<div class="page-content">
    <div class="page-header">
        <h1>Administration</h1>
        <div class="page-description">
            Consultez et modifiez des comptes d’organisations et d’utilisateurs
        </div>
    </div>

    <div class="page-body">
        <div class="search-and-button">
            <ed-textfield
                [ngModel]="paginationInfo.search"
                (ngModelChange)="applyFilter($event)"
                placeholder="Chercher une organisation"
                [icon]="'icon-search-lg'"></ed-textfield>
            <ed-button
                *ngIf="!isLoading"
                [icon]="'icon-plus'"
                (click)="openModalCreateOrganization()">
                Ajouter une organisation
            </ed-button>
        </div>
    </div>
    <ed-table
        *ngIf="dataSource.data.length"
        (onDropdownMenuClick)="onDropdownMenuClick($event.object, $event.event)"
        [dataSource]="dataSource"
        [displayedColumns]="displayedColumns"
        (onRowClick)="goToDetail($event.toString())"
        [areActionsInElements]="true">
    </ed-table>
    <!-- when more users to display -->
    <ed-skeleton-list *ngIf="isLoading"></ed-skeleton-list>

    @if ((organizations$ | async) === null) {
        <ed-skeleton-list></ed-skeleton-list>
    }
</div>
