import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatTableDataSource } from '@angular/material/table';

import {
    AlertService,
    DropdownMenuItem,
    LayerService,
    LayerSize,
    PopupService,
    PopupTypes,
} from '@ed---interne/ng-uui-components';

import { OrganizationCreateComponent } from './organization-create/organization-create.component';
import {
    DisplayedColumns,
    DisplayType,
} from '../../../shared/components/ed-table/ed-table.component';

import { Pagination } from '../../../shared/all.types';
import { LayerPopupOptions } from '../../../shared/all.constants';

import {
    DeleteOrganization,
    GetAllOrganizations,
    OrganizationState,
    SetCurrentOrganization,
} from './organization.state';
import { Organization } from '../../../core/models/organization.model';
import { Router } from '@angular/router';
import { ConcessionControl } from 'src/app/core/models/concession-control.model';

interface OrganizationTableElement {
    id: string;
    name: string;
    userCount: number;
    createdAt: Date | undefined;
    lastConnexionAt: Date | undefined;
    concessionControls: ConcessionControl[];
    actions: DropdownMenuItem[];
}
enum OrganizationMenuEvent {
    AccessConcession = 'AccessConcession',
    Update = 'Update',
    Delete = 'Delete',
}
export interface SettingsMenuItem {
    display: string;
    routerLink: string[];
    routerLinkActiveOptions?: any;
}

@UntilDestroy()
@Component({
    selector: 'app-organization',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit {
    public organizations$: Observable<Organization[]> = inject(Store)
        .select(OrganizationState.organizations)
        .pipe(filter(Boolean));

    public isLoading = false;

    public filterChanged = new Subject<string>();

    public dataSource: MatTableDataSource<OrganizationTableElement> =
        new MatTableDataSource<OrganizationTableElement>();
    public menuItems$: Observable<SettingsMenuItem[]> | undefined;

    public isLoading$: Observable<boolean> = inject(Store).select(OrganizationState.isLoading);

    public displayedColumns: DisplayedColumns[] = [
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Organisation',
            objectKey: 'name',
            bold: true,
        },
        {
            displayType: DisplayType.NUMBER,
            objectDisplayName: 'Utilisateurs',
            objectKey: 'userCount',
        },
        {
            displayType: DisplayType.DATE,
            objectDisplayName: 'Date de création',
            objectKey: 'createdAt',
        },
        {
            displayType: DisplayType.DATE,
            objectDisplayName: 'Dernière connexion',
            objectKey: 'lastConnexionAt',
        },
        {
            displayType: DisplayType.BADGE,
            objectDisplayName: 'CRAC',
            objectKey: 'crac',
        },
        {
            displayType: DisplayType.LABELS,
            objectDisplayName: 'Contrôle de concession',
            objectKey: 'concessionControls',
        },
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Etudes spécifiques',
            objectKey: 'specificStudies',
        },
    ];

    public paginationInfo: Pagination = {
        orderBy: { field: 'createdAt', order: -1 },
        page: 1,
        search: '',
        organizationId: null,
    };

    constructor(
        private readonly store: Store,
        private readonly layerService: LayerService,
        private readonly popupService: PopupService,
        private readonly alertService: AlertService,
        private readonly router: Router,
    ) {}

    public ngOnInit(): void {
        this.store.dispatch([new SetCurrentOrganization({}), new GetAllOrganizations()]);

        this.isLoading$!.pipe(untilDestroyed(this)).subscribe((loadingState) => {
            this.isLoading = loadingState;
        });

        this.organizations$.pipe(untilDestroyed(this)).subscribe((organizations) => {
            this.dataSource = new MatTableDataSource<OrganizationTableElement>(
                organizations.map((organization) => ({
                    id: organization.id,
                    createdAt: organization.createdAt,
                    name: organization.name,
                    userCount: organization.userCount,
                    lastConnexionAt: organization.lastConnexionAt,
                    concessionControls: organization.concessionControls!!,
                    actions: this._getActions(),
                })),
            );
        });
    }

    public openConcessionOrga(organization: Organization): void {
        void this.router.navigate(['main/' + organization.id + '/']);
    }

    private _deleteOrganization(organization: Organization): void {
        this.popupService
            .openPopup({
                title: "Suppression de l'organisation",
                htmlContent: `Supprimer l’organisation supprimera aussi les utilisateurs qui y sont rattachés. Confirmez-vous la suppression de l’organisation <b>${organization.name}</b> ?`,
                confirmButtonText: "Supprimer l'organisation",
                type: PopupTypes.Delete,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.store
                        .dispatch(new DeleteOrganization({ organizationId: organization?.id }))
                        .pipe(untilDestroyed(this))
                        .subscribe({
                            next: () => {
                                this.alertService.valid(
                                    'Organisation',
                                    "L'organisation a bien été supprimée",
                                );
                            },
                            error: (err) => {
                                this.alertService.error(
                                    'Organisation',
                                    "Une erreur est survenue : Impossible de supprimer l'organisation",
                                );
                            },
                        });
                }
            });
    }

    private _updateOrganization(organization: Organization): void {
        void this.router.navigate(['admin/' + organization.id + '/general']);
    }

    public openModalCreateOrganization(): void {
        this.layerService.show(
            OrganizationCreateComponent,
            {},
            {
                size: LayerSize.Medium,
                withClosePopup: LayerPopupOptions.Creating,
            },
        );
    }

    private _getActions(): DropdownMenuItem[] {
        const actions: DropdownMenuItem[] = [
            {
                icon: 'icon-edit-02',
                text: "Editer l'organisation",
                outputEventString: OrganizationMenuEvent.Update,
            },
            {
                icon: 'icon-switch-horizontal-01',
                text: 'Accéder à la concession',
                outputEventString: OrganizationMenuEvent.AccessConcession,
            },
            {
                icon: 'icon-trash-01',
                text: "Supprimer l'organisation",
                outputEventString: OrganizationMenuEvent.Delete,
            },
        ];

        return actions;
    }

    public onDropdownMenuClick(organization: Organization, event: string): void {
        switch (event) {
            case OrganizationMenuEvent.AccessConcession:
                this.openConcessionOrga(organization);
                break;
            case OrganizationMenuEvent.Update:
                this._updateOrganization(organization);
                break;
            default:
                this._deleteOrganization(organization);
                break;
        }
    }

    public goToDetail(organizationId: string): void {
        void this.router.navigate(['admin/' + organizationId + '/general']);
    }

    public applyFilter(event: string): void {
        this.dataSource.filter = event.trim().toLowerCase();
    }
}
