import { AbstractControl, ValidationErrors } from '@angular/forms';

export function fileValidator(control: AbstractControl): ValidationErrors | null {
    const file = control.value;
    if (file) {
        if (file.size > 1 * 1024 * 1024) {
            // 1 MB limit
            return { fileSize: true };
        }
        if (!['image/png', 'image/jpeg', 'image/tiff'].includes(file.type)) {
            return { fileType: true };
        }
    }
    return null;
}
