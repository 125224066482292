import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Organization } from '../../../core/models/organization.model';
import { OrganizationService } from './organization.service';
import { ConcessionControl } from 'src/app/core/models/concession-control.model';
import { ConcessionControlStatus } from '../../main/concessioncontrol/assets/constants';

/** This file is divided in 3 parts:
 * - The definition of the Store : defining data structure
 * - Constants that allow NgXs to trigger Actions
 * - Selectors & Actions : Functions allowed to interact with the store
 *
 */

// Store definition
export interface OrganizationStateModel {
    organizations: Organization[];
    createdOrganization: Organization | null;
    userOrganization: Organization | null;
    currentOrganization: Organization | null;
    organizationPart: Partial<Organization> | null;
    avatar: string | null;
    isLoading: boolean;
    concessionControls: ConcessionControl[];
    concessionControlPart: Partial<ConcessionControl> | null;
    currentYear: number;
}

//Constants

export class GetAllOrganizations {
    static readonly type = 'ADMIN/ORGANIZATION/ALL';
}

export class DeleteOrganization {
    static readonly type = 'ADMIN/ORGANIZATION/DELETE';
    constructor(public payload: { organizationId: string }) {}
}

export class UpdateOrganization {
    static readonly type = 'ADMIN/ORGANIZATION/UPDATE';
    constructor(public payload: { organizationPart: Partial<Organization>; avatarFile?: File }) {}
}

export class CreateOrganization {
    static readonly type = 'ADMIN/ORGANIZATION/CREATE';
    constructor(public payload: { organization: Partial<Organization>; avatarFile?: File }) {}
}

export class CreateOrganizationSuccess {
    static readonly type = '[Organization] Create Organization Success';
    constructor(public payload: { organization: Organization }) {}
}

export class SetCurrentOrganization {
    static readonly type = 'ADMIN/ORGANIZATION/ID';
    constructor(public payload: { organizationId?: string; organization?: Organization }) {}
}

export class SetCurrentYear {
    static readonly type = 'ORGANIZATION/YEAR';
    constructor(public payload: { year: number }) {}
}

export class SetCurrentAvatar {
    static readonly type = 'ORGANIZATION/SET_AVATAR';
    constructor(public payload: { avatarUrl: string | null }) {}
}

export class SetUserOrganization {
    static readonly type = 'ORGANIZATION/SET_USER_ORGANIZATION';
    constructor(public payload: { organization: Organization; isAdmin: boolean }) {}
}

export class GetAllConcessionControls {
    static readonly type = 'ADMIN/CONCESSIONCONTROL/ORGANIZATION/ID';
    constructor(public payload: { organizationId: string }) {}
}

export class UpdateConcessionControl {
    static readonly type = 'ADMIN/CONCESSIONCONTROL/ORGANIZATION/UPDATE';
    constructor(
        public payload: {
            concessionControlPart: Partial<ConcessionControl>;
            file?: File;
            isFileDeleted: boolean;
            isNewFile: boolean;
        },
    ) {}
}
//Actions

//Usual latest control is last year
const defaultCurrentYear = new Date().getFullYear() - 1;

@State<OrganizationStateModel>({
    name: 'Organizations',
    defaults: {
        organizations: [],
        createdOrganization: null,
        userOrganization: null,
        currentOrganization: null,
        organizationPart: null,
        isLoading: false,
        concessionControls: [],
        concessionControlPart: null,
        avatar: null,
        currentYear: defaultCurrentYear,
    },
})
@Injectable()
export class OrganizationState {
    @Selector()
    public static organizations(state: OrganizationStateModel): Organization[] | null {
        return state.organizations;
    }

    @Selector()
    public static createdOrganization(state: OrganizationStateModel): Organization | null {
        return state.createdOrganization;
    }

    @Selector()
    public static userOrganization(state: OrganizationStateModel): Organization | null {
        return state.userOrganization;
    }

    @Selector()
    public static currentOrganization(state: OrganizationStateModel): Organization | null {
        return state.currentOrganization;
    }

    @Selector()
    public static currentYear(state: OrganizationStateModel): number {
        return state.currentYear;
    }

    @Selector()
    public static avatar(state: OrganizationStateModel): string | null {
        return state.avatar;
    }

    @Selector()
    static isLoading(state: OrganizationStateModel): boolean {
        return state.isLoading;
    }

    @Selector()
    public static concessionControls(state: OrganizationStateModel): ConcessionControl[] | null {
        return state.concessionControls.sort((a, b) => b.year - a.year);
    }

    constructor(
        private organizationService: OrganizationService,
        private readonly store: Store,
    ) {}

    @Action(GetAllOrganizations)
    public async getAllOrganizations(
        ctx: StateContext<OrganizationStateModel>,
        action: GetAllOrganizations,
    ): Promise<void> {
        ctx.patchState({ isLoading: true });

        const organizations = await this.organizationService.getAllOrganizations();
        ctx.patchState({ organizations: organizations, isLoading: false });
    }

    @Action(UpdateOrganization)
    public async updateOrganization(
        ctx: StateContext<OrganizationStateModel>,
        action: UpdateOrganization,
    ): Promise<void> {
        try {
            const updatedOrganization = await this.organizationService.updateOrganization(
                action.payload.organizationPart,
                action.payload.avatarFile,
            );
            const organizations = ctx.getState().organizations;
            const organizationIndex = organizations.findIndex(
                (organization) => organization.id === updatedOrganization.id,
            );
            organizations[organizationIndex] = updatedOrganization;
            ctx.patchState({ currentOrganization: updatedOrganization, organizations });
        } catch (err: any) {
            console.log(err);
            throw err;
        }
    }

    @Action(CreateOrganization)
    public async createOrganization(
        ctx: StateContext<OrganizationStateModel>,
        action: CreateOrganization,
    ): Promise<Organization> {
        try {
            const organization = await this.organizationService.createOrganization(
                action.payload.organization,
                action.payload.avatarFile,
            );
            const currentState = ctx.getState();
            const currentOrganizations = currentState.organizations || [];
            const updatedOrganizations = [...currentOrganizations, organization];
            ctx.patchState({ organizations: updatedOrganizations });
            return organization;
        } catch (err: any) {
            console.error(err);
            throw err;
        }
    }

    @Action(DeleteOrganization)
    public async deleteOrganization(
        ctx: StateContext<OrganizationStateModel>,
        action: DeleteOrganization,
    ): Promise<void> {
        try {
            await this.organizationService.deleteOrganization(action.payload.organizationId);
            ctx.dispatch(GetAllOrganizations);
        } catch (err: any) {
            console.error('Error DeleteOrganization', err);
            throw err;
        }
    }

    @Action(SetCurrentOrganization)
    public async setCurrentOrganization(
        ctx: StateContext<OrganizationStateModel>,
        action: SetCurrentOrganization,
    ): Promise<void> {
        if (action.payload.organization) {
            ctx.patchState({ currentOrganization: action.payload.organization, avatar: null });
            return;
        }

        if (!action.payload.organizationId) {
            ctx.patchState({ currentOrganization: null, avatar: null });
            return;
        }

        const organizations = ctx.getState().organizations;
        let currentOrganization = organizations.find(
            (organization) => organization.id === action.payload.organizationId,
        );

        if (!currentOrganization) {
            currentOrganization = await this.organizationService.getOrganizationById(
                action.payload.organizationId,
            );
        }

        ctx.patchState({ currentOrganization, avatar: null });
    }

    @Action(SetCurrentYear)
    public async setCurrentYear(
        ctx: StateContext<OrganizationStateModel>,
        action: SetCurrentYear,
    ): Promise<void> {
        if (!action.payload.year) {
            return;
        }

        ctx.patchState({ currentYear: action.payload.year });
    }

    @Action(SetUserOrganization)
    public async setUserOrganization(
        ctx: StateContext<OrganizationStateModel>,
        action: SetUserOrganization,
    ): Promise<void> {
        ctx.patchState({ userOrganization: action.payload.organization });

        if (!action.payload.isAdmin) {
            this.store.dispatch(
                new SetCurrentOrganization({ organization: action.payload.organization }),
            );
        }
    }

    @Action(SetCurrentAvatar)
    public async setCurrentAvatar(
        ctx: StateContext<OrganizationStateModel>,
        action: SetCurrentAvatar,
    ): Promise<void> {
        ctx.patchState({ avatar: action.payload.avatarUrl });
    }

    @Action(GetAllConcessionControls)
    public async getConcessionControlOrganizationById(
        ctx: StateContext<OrganizationStateModel>,
        action: GetAllConcessionControls,
    ): Promise<void> {
        ctx.patchState({ isLoading: true });

        const concessionControls =
            await this.organizationService.getConcessionControlOrganizationById(
                action.payload.organizationId,
            );

        const currentYear = new Date().getFullYear();
        if (!concessionControls.find((ccStudy) => ccStudy.year === currentYear)) {
            const newStudy: ConcessionControl = {
                id: 'new-study',
                year: currentYear,
                status: ConcessionControlStatus.WAITING,
                description: '',
                document: null,
                organization: {
                    id: action.payload.organizationId,
                    name: '',
                    userCount: 0,
                    concessionControls: [],
                },
                sizeFile: 0,
            };
            concessionControls.push(newStudy);
        }

        return ctx.patchState({ concessionControls: concessionControls, isLoading: false });
    }

    @Action(UpdateConcessionControl)
    public async updateConcessionControl(
        ctx: StateContext<OrganizationStateModel>,
        action: UpdateConcessionControl,
    ): Promise<void> {
        try {
            const updatedConcessionControl = await this.organizationService.updateConcessionControl(
                action.payload.concessionControlPart,
            );

            const organizationId: string =
                action.payload.concessionControlPart.organization?.id ?? '';
            const file: File | undefined = action.payload.file;

            await this.organizationService.updateFileConcessionControl(
                organizationId,
                updatedConcessionControl.id,
                file!,
                action.payload.isFileDeleted,
                action.payload.isNewFile,
            );

            const state = ctx.getState();

            const updatedConcessionControls = state.concessionControls?.map((control) =>
                ['new-study', updatedConcessionControl.id].includes(control.id)
                    ? updatedConcessionControl
                    : control,
            );

            ctx.patchState({
                concessionControls: updatedConcessionControls,
            });
        } catch (err: any) {
            console.error('Error updating concession control:', err);
            throw err;
        }
    }
}
