import { Component, inject, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable, filter, combineLatest } from 'rxjs';

import { ButtonColors } from '@ed---interne/ng-uui-components';

import { AuthState } from 'src/app/core/states/auth.state';
import {
    OrganizationState,
    GetAllConcessionControls,
} from '../../admin/organization/organization.state';

import {
    CC_CONSTANTS,
    ConcessionControlStatus,
    CC_STATUS_CONSTANTS,
} from '../concessioncontrol/assets/constants';
import { ConcessionControl } from '../../..//core/models/concession-control.model';

import { SidenavService } from '../../../shared/services/sidenav.service';
import { Organization } from 'src/app/core/models/organization.model';
import { User } from 'src/app/core/models/user.model';

@UntilDestroy()
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
    private prefixPath: string = '/main';
    public _currentOrganization$: Observable<Organization | null> = inject(Store).select(
        OrganizationState.currentOrganization,
    );
    public _loggedUser$: Observable<User | null> = inject(Store).select(AuthState.loggedUser);

    public buttonColors = ButtonColors;
    public statusOptions = CC_STATUS_CONSTANTS;
    public ccStudiesSignal: Signal<ConcessionControl[] | null> = inject(Store).selectSignal(
        OrganizationState.concessionControls,
    );

    public concessionControlStatus = ConcessionControlStatus;
    constructor(
        private router: Router,
        private readonly store: Store,
        private readonly sidenavService: SidenavService,
    ) {
        // make sure sidenav is not display on this page
        this.sidenavService.setTopMenuItems([]);

        combineLatest([this._currentOrganization$, this._loggedUser$])
            .pipe(untilDestroyed(this))
            .subscribe(([organization, loggedUser]) => {
                //For Admin mode, we need organizationId in url : ( /main/{organzationid}/)
                if (organization?.id && loggedUser?.isAdmin) {
                    this.prefixPath = `/main/${organization.id}`;
                }
                if (organization?.id) {
                    const payload = { organizationId: organization.id };
                    this.store.dispatch(new GetAllConcessionControls(payload));
                    //this.store.dispatch(new GetAllCrac(payload));
                    //this.store.dispatch(new GetAllSpecificStudies(payload));
                }
            });
    }

    openConcessionControl(year: number) {
        void this.router.navigate([`${this.prefixPath}/cc/${year}/${CC_CONSTANTS.welcome.path}`]);
    }

    getBadgeLabel(statusId: number): string {
        const option = this.statusOptions.find((option) => option.value === statusId);
        return option ? option.label : '';
    }

    getBadgeClass(status: number): string {
        switch (status) {
            case ConcessionControlStatus.WAITING:
                return 'pending';
            case ConcessionControlStatus.PROCESSING:
                return 'inProgress';
            default:
                return 'available';
        }
    }

    // public openCrac(year: Number): void {
    //     void this.router.navigate([
    //         `${this.prefixPath}/crac/${year}/${CRAC_CONSTANTS.general.path}`,
    //     ]);
    // }

    public contact(year: Number) {}
}
