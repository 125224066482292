import { Component, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { ConcessioncontrolState, PrepareDataFromKeys } from '../../states/concessioncontrol.state';
import { CC_CONSTANTS } from '../../assets/constants';
import { ActivatedRoute } from '@angular/router';
import { GenericComponent } from '../generic/generic.component';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidenavService } from 'src/app/shared/services/sidenav.service';

const WELCOME_PAGE_All_KPIS = 'welcome_x_x_kpis';
const KEYS = [WELCOME_PAGE_All_KPIS];

interface KpiData {
    //Minimum of a line
    organizationId?: string;
    year?: string;
    error?: boolean;

    //Date de signature du contrat de concession' })
    //KPIS_ID=2
    concessionContractSigningDate?: string;

    //Durée du contrat de concession en vigueur' })
    //KPIS_ID=5
    currentConcessionContractDuration?: number;

    //Nom des concessionnaires' })
    //KPIS_ID=6
    concessionHoldersNames?: string;

    //Dernier exercice des données' })
    //KPIS_ID=??
    processedConcessionContractYear?: string;
    //Taux d\'enfouissement des réseaux HTA (en %)' })
    //KPIS_ID=19
    mvUndergroundingRate?: number;
    mvUndergroundingRateEvolution?: number;

    //Taux d\'enfouissement des réseaux BT (en %)' })
    //KPIS_ID=79
    lvUndergroundingRate?: number;
    lvUndergroundingRateEvolution?: number;

    //Taux de déploiement des compteurs Linky C5 (en %)' })
    //KPIS_ID=159
    linkyDeploymentRate?: number;
    linkyDeploymentRateMean?: number;

    //'Critère B HIX (en minutes)' })
    //KPIS_ID=203
    criterionBHix?: number;
    criterionBHixMean?: number;

    //Investissements annuels totaux d\'Enedis (CAPEX, en M€)'
    //KPIS_ID=930
    totalInvestments?: number;
    totalInvestmentsEvolution?: number;

    //Nombre total d\'usagers en soutirage (Enedis)'
    //KPIS_ID=9
    totalNumberOfUsers?: number;
    totalNumberOfUsersEvolution?: number;
}

@UntilDestroy()
@Component({
    selector: 'app-cc-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent extends GenericComponent {
    public kpis: Signal<KpiData[]> = this.store.selectSignal(
        ConcessioncontrolState.getDataByKey(WELCOME_PAGE_All_KPIS),
    ) as Signal<KpiData[]>;

    public texts = CC_CONSTANTS.welcome.texts;

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly sidenavService: SidenavService,
    ) {
        //GenericComponent intialize sideNav, Tabs (if exists), title and subTitle
        super(route, sidenavService);
    }

    ngOnInit() {
        // Every time currentOrganization or currentYear changes, we tell the store he has to ask for the data
        combineLatest([this._currentOrganization$, this._currentYear$])
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(() => {
                this.store.dispatch(new PrepareDataFromKeys({ keys: KEYS }));
            });
    }
}
