<generic-card
    [title]="title"
    [descriptionTitle]="descriptionTitle"
    [descriptionContent]="descriptionContent"
    [error]="error"
    [ngClass]="cardClass"
    [isLoading]="isLoading">
    @if (isLoading) {
        <div class="card-chart">
            <div class="skeleton-body">
                <div class="row">
                    <div class="col-sm-2"><div class="skeleton skeleton-bar-30"></div></div>
                    <div class="col-sm-2"><div class="skeleton skeleton-bar-40"></div></div>
                    <div class="col-sm-2"><div class="skeleton skeleton-bar-60"></div></div>
                    <div class="col-sm-2"><div class="skeleton skeleton-bar-90"></div></div>
                    <div class="col-sm-2"><div class="skeleton skeleton-bar-70"></div></div>
                    <div class="col-sm-2"><div class="skeleton skeleton-bar-50"></div></div>
                </div>
            </div>
        </div>
    } @else {
        <div class="card-chart">
            <apx-chart
                [id]="chart.id"
                [series]="series"
                [chart]="chart"
                [colors]="defaultColors"
                [stroke]="stroke"
                [tooltip]="tooltip"
                [fill]="fill"
                [plotOptions]="plotOptions"
                [dataLabels]="_dataLabels"
                [labels]="labels"
                [xaxis]="xaxis"
                [yaxis]="_yaxis"></apx-chart>
        </div>
    }
</generic-card>
