<header>
    @let loggedUser = loggedUser$ | async;
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
            <button
                *ngIf="isSmallScreen"
                class="navbar-toggler d-lg-inline-block"
                type="button"
                (click)="toggleSidenav()">
                <i class="icon-menu-01"></i>
            </button>

            <a
                class="navbar-brand"
                href="https://aecenergie.fr/"
                target="_blank"
                title="aecenergie.fr"
                [ngClass]="{ 'me-0': isSmallScreen }">
                <img src="/assets/img/logo_header.png" alt="logo" height="44" />
            </a>

            <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed">
                <i class="bi bi-three-dots"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed">
                <form [formGroup]="headerFormGroup" class="me-auto">
                    @if (currentPage == 'admin') {
                        <ul class="navbar-nav">
                            <li class="navbar-header">
                                <a class="breadcrumbs" routerLink="/admin/organization">
                                    Administration
                                </a>
                            </li>
                            <li class="navbar-header" *ngIf="!isSmallScreen && organization?.id">
                                <i class="bi bi-chevron-right"></i>
                            </li>
                            <li class="nav-item select-example" *ngIf="organization?.id">
                                <ed-select
                                    formControlName="organization"
                                    [disableRipple]="true"
                                    [disableOptionCentering]="true"
                                    innerId="select-organization"
                                    placeholder="Sélectionner une organisation"
                                    [options]="(orgsList$ | async) || []">
                                </ed-select>
                            </li>
                        </ul>
                    } @else {
                        <ul class="navbar-nav">
                            @if (loggedUser?.isAdmin) {
                                <li class="navbar-header">
                                    <ed-select
                                        formControlName="organization"
                                        [disableRipple]="true"
                                        [disableOptionCentering]="true"
                                        innerId="select-organization"
                                        placeholder="Sélectionner une organisation"
                                        [options]="(orgsList$ | async) || []">
                                    </ed-select>
                                </li>
                            } @else {
                                <li class="navbar-header">
                                    <a class="breadcrumbs" routerLink="/main">Mon espace</a>
                                </li>
                            }
                            <li class="navbar-header" *ngIf="!isSmallScreen">
                                <i class="bi bi-chevron-right"></i>
                            </li>

                            <li class="nav-item select-example">
                                <ed-select
                                    formControlName="cracOrCc"
                                    [disableRipple]="true"
                                    [disableOptionCentering]="true"
                                    innerId="crac-or-cc"
                                    placeholder="Sélectionner CRAC ou Contrôle de concession"
                                    [options]="selectOptions">
                                </ed-select>
                            </li>
                            <li class="navbar-header" *ngIf="!isSmallScreen && displaySelectYear">
                                <i class="bi bi-chevron-right"></i>
                            </li>

                            <li class="nav-item select-year" *ngIf="displaySelectYear">
                                <ed-select
                                    formControlName="year"
                                    [disableRipple]="true"
                                    [disableOptionCentering]="true"
                                    innerId="select-year"
                                    placeholder="Sélectionner une année"
                                    [options]="yearOptions">
                                </ed-select>
                            </li>
                        </ul>
                    }
                </form>

                <div class="profile">
                    <div mat-icon-button [matMenuTriggerFor]="menu">
                        @if (avatarUrl) {
                            <img
                                class="avatar"
                                [src]="avatarUrl"
                                width="40"
                                height="40"
                                alt="avatar" />
                        } @else {
                            <div class="circle">
                                <span class="initials">
                                    {{ initials$ | async }}
                                </span>
                            </div>
                        }
                    </div>

                    <mat-menu #menu="matMenu" class="header-dropdown">
                        <button mat-menu-item disabled class="user-info">
                            <div>
                                @if (avatarUrl) {
                                    <img
                                        class="avatar"
                                        [src]="avatarUrl"
                                        width="40"
                                        height="40"
                                        alt="avatar" />
                                } @else {
                                    <div class="circle">
                                        <span class="initials">
                                            {{ initials$ | async }}
                                        </span>
                                    </div>
                                }
                            </div>
                            <div>
                                <div class="primary-info">
                                    {{ loggedUser?.firstname }} {{ loggedUser?.lastname }}
                                </div>
                                <div class="secondary-info">
                                    {{ loggedUser?.mail }}
                                </div>
                            </div>
                        </button>

                        @if (displayMyProfile) {
                            <button mat-menu-item routerLink="/main/account" class="btn-cta">
                                <i class="icon icon-user-01"></i>
                                <span>Mon profil</span>
                            </button>
                        }

                        @if (loggedUser?.isAdmin) {
                            <button mat-menu-item routerLink="/admin/organization" class="btn-cta">
                                <i class="icon icon-tool-01"></i>
                                <span>Administration</span>
                            </button>
                        }
                        <button mat-menu-item (click)="logOut()" class="btn-cta">
                            <i class="icon icon-log-out-01"></i>
                            <span>Déconnexion</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </nav>
</header>
