import { Routes } from '@angular/router';

import { GeneralCracComponent } from './components/generalcrac/generalcrac.component';
import { CracComponent } from './crac.component';
import { importProvidersFrom } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CracState } from './states/crac.state';
import { CRAC_CONSTANTS } from './assets/constants';

export const cracControlRoutes: Routes = [
    {
        path: '',
        component: CracComponent,
        providers: [importProvidersFrom(NgxsModule.forFeature([CracState]))],
        children: [
            { path: '', component: GeneralCracComponent, data: CRAC_CONSTANTS.general },
            {
                path: CRAC_CONSTANTS.general.path,
                component: GeneralCracComponent,
                data: CRAC_CONSTANTS.general,
            },
        ],
    },
];
