<div class="map-wrapper">
    <div class="export-menu">
        <div mat-icon-button [matMenuTriggerFor]="menu">
            <i class="icon icon-download"></i>
        </div>

        <mat-menu #menu="matMenu" class="map-dropdown">
            <button mat-menu-item (click)="export()">Download PNG</button>
            <button
                mat-menu-item
                (click)="export(index)"
                *ngFor="let layer of layers; let index = index">
                Download shapefile - {{ legendConfig[index].title }}
            </button>
        </mat-menu>
    </div>
    <div [id]="mapId" class="map"></div>
</div>
