import { Component, Signal } from '@angular/core';
import { ConcessioncontrolState, PrepareDataFromKeys } from '../../states/concessioncontrol.state';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { CC_CONSTANTS } from '../../assets/constants';
import { GenericComponent } from '../generic/generic.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, distinctUntilChanged } from 'rxjs';

import { SidenavService } from 'src/app/shared/services/sidenav.service';

const MV_NETWORKS_AGES_KPIS = 'ouvrages_mv_networks_ages_kpis';
const KEYS = [MV_NETWORKS_AGES_KPIS];

interface KpiData {
    organizationId?: string;
    year?: string;
    error?: boolean;

    mvNetworksAverageAge?: number;
    mvNetworksAverageAgeEvolution?: number;

    mvNetworksLengthOver40Km?: number;
    mvNetworksLengthOver40KmEvolution?: number;

    mvNetworksLengthCpi?: number;
    mvNetworksLengthCpiEvolution?: number;

    mvNetworksLengthLowSection?: number;
    mvNetworksLengthLowSectionEvolution?: number;
}

@UntilDestroy()
@Component({
    templateUrl: './mv-networks-ages.component.html',
    styleUrls: ['./mv-networks-ages.component.scss'],
})
export class MvNetworksAgesComponent extends GenericComponent {
    public texts = CC_CONSTANTS.mvNetworks.tabs.aging.texts;

    public kpis: Signal<KpiData[]> = this.store.selectSignal(
        ConcessioncontrolState.getDataByKey(MV_NETWORKS_AGES_KPIS),
    ) as Signal<KpiData[]>;

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly sidenavService: SidenavService,
    ) {
        //GenericComponent intialize sideNav, Tabs (if exists), title and subTitle
        super(route, sidenavService);
    }

    ngOnInit() {
        // Every time currentOrganization or currentYear changes, we tell the store he has to ask for the data
        combineLatest([this._currentOrganization$, this._currentYear$])
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(() => {
                this.store.dispatch(new PrepareDataFromKeys({ keys: KEYS }));
            });
    }
}
