<div class="page-content">
    <div class="py-4">
        <form [formGroup]="organizationFormGroup" class="update-organization-container">
            <div class="row">
                <label
                    for="avatar-upload"
                    class="col-2 combined-label display-label-on-the-left d-flex align-items-center">
                    Icône d'organisation</label
                >

                <div class="col-auto">
                    <label class="custom-file-upload">
                        <input id="avatar-upload" type="file" (change)="onFileSelect($event)" />
                        @if (!avatarUrl) {
                            <div
                                class="avatar-upload empty"
                                tippyClassName="ed-custom-tippy"
                                [tippyProps]="{
                                    allowHTML: true,
                                    placement: 'top',
                                    content:
                                        '<div><b>Téléverser l\'icone de l\'organisation</b></div><div>Format rond ou carré (JPG, PNG, TIFF)</div>',
                                }"
                                ngxTippy>
                                <i class="icon icon-upload-cloud-02"></i>
                            </div>
                        } @else {
                            <div
                                class="avatar-upload uploaded"
                                tippyClassName="ed-custom-tippy"
                                [tippyProps]="{
                                    allowHTML: true,
                                    placement: 'top',
                                    content:
                                        '<div><b>Modifier l\'icone de l\'organisation</b></div><div>Format rond ou carré (JPG, PNG, TIFF)</div>',
                                }"
                                ngxTippy>
                                <img alt="preview" [src]="avatarUrl" width="70" height="70" />
                                <i class="icon icon-upload-cloud-02"></i>
                            </div>
                        }
                    </label>
                </div>
            </div>

            <div class="row">
                <div
                    class="col-2 combined-label display-label-on-the-left d-flex align-items-center">
                    <div>
                        <label for="name" class="required"> Nom de l'organisation </label>
                    </div>
                </div>
                <div class="col-5">
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="col-sm">
                                    <ed-textfield
                                        innerId="name"
                                        name="name"
                                        type="name"
                                        placeholder=""
                                        [required]="true"
                                        [formControlName]="'name'"></ed-textfield>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sticky-bottom-buttons" *ngIf="organizationFormGroup.dirty && !isSubmitting">
                <ed-button
                    class="left"
                    [icon]="'icon-flip-backward'"
                    (click)="resetForm()"
                    [colorSchema]="buttonColors.Secondary"
                    >Annuler les modifications</ed-button
                >

                <ed-button
                    [type]="buttonTypes.Submit"
                    [disabled]="organizationFormGroup.invalid || !organizationFormGroup.dirty"
                    [icon]="'icon-save-01'"
                    (click)="onSubmit()"
                    >Enregistrer</ed-button
                >
            </div>
        </form>
    </div>
</div>
