import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, map, Observable } from 'rxjs';
import { SetCurrentOrganization } from '../../features/admin/organization/organization.state';

export const organizationGuard: CanActivateFn = (
    next: ActivatedRouteSnapshot,
): Observable<boolean> | Promise<boolean> => {
    const router = inject(Router);
    const store = inject(Store);
    const organizationId = next.params['organizationId'];

    if (!organizationId) {
        // return router.navigate(['/admin']);
        return store.dispatch(new SetCurrentOrganization({})).pipe(
            map(() => true),
            catchError(() => {
                return router.navigate(['/admin']);
            }),
        );
    }

    return store.dispatch(new SetCurrentOrganization({ organizationId })).pipe(
        map(() => true),
        catchError(() => {
            return router.navigate(['/admin']);
        }),
    );
};
