<div class="page-content">
    <div class="page-header">
        <h1>Mon espace</h1>
        <div class="page-description">Visualiser et accéder à vos études</div>
    </div>

    <div class="page-body">
        @let ccStudies = ccStudiesSignal() || [];
        <app-studies
            [ccStudies]="ccStudies"
            [ccStudyHeaderTemplate]="ccStudyHeaderTemplate"
            [ccStudyBodyTemplate]="ccStudyBodyTemplate"></app-studies>
    </div>
</div>

<ng-template #ccStudyHeaderTemplate let-study="study">
    @if (study.status === concessionControlStatus.WAITING) {
        <ed-button (click)="contact(study.year)" [colorSchema]="buttonColors.Secondary">
            Nous contacter
        </ed-button>
    } @else if (study.status === concessionControlStatus.PROCESSING) {
        <ed-button [disabled]="true">Ouvrir</ed-button>
    } @else if (study.status === concessionControlStatus.AVAILABLE) {
        <ed-button (click)="openConcessionControl(study.year)">Ouvrir</ed-button>
    }
</ng-template>

<ng-template #ccStudyBodyTemplate let-study="study">
    <div class="body">
        <div class="badge rounded-pill" [ngClass]="getBadgeClass(study.status)">
            {{ getBadgeLabel(study.status) }}
        </div>
        <p class="description" *ngIf="!!study.description">
            {{ study.description }}
        </p>
    </div>
</ng-template>
