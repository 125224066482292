<div class="modal-content">
    <div class="modal-content-header">
        <h1>Modifier mon mot de passe</h1>
    </div>

    <form class="modal-content-body" [formGroup]="passwordFormGroup">
        <div class="description">
            Veuillez renseigner votre ancien mot de passe suivi du nouveau, à garder précieusement.
        </div>

        <div>
            <div class="width-500">
                <label for="currentPassword">Ancien mot de passe</label>
                <ed-textfield
                    innerId="currentPassword"
                    [type]="isCurrentPasswordVisible ? 'text' : 'password'"
                    (keyup)="onKeyUpCheckCurrentPassword()"
                    placeholder="Saisissez votre mot de passe actuel"
                    formControlName="currentPassword"
                    (onIconClick)="toggleCurrentPasswordVisibility()"
                    [icon]="isCurrentPasswordVisible ? 'icon-eye' : 'icon-eye-off'"
                    [iconPosition]="'right'"
                    [required]="true"
                    [iconClickable]="true"></ed-textfield>
            </div>
        </div>

        <div>
            <div class="width-500">
                <label for="newPassword">Nouveau mot de passe</label>
                <ed-textfield
                    innerId="newPassword"
                    [type]="isNewPasswordVisible ? 'text' : 'password'"
                    (keyup)="onKeyUpCheckNewPassword()"
                    placeholder="Choisissez votre nouveau mot de passe"
                    formControlName="newPassword"
                    (onIconClick)="toggleNewPasswordVisibility()"
                    [icon]="isNewPasswordVisible ? 'icon-eye' : 'icon-eye-off'"
                    [iconPosition]="'right'"
                    [required]="true"
                    [iconClickable]="true"></ed-textfield>
            </div>
        </div>
    </form>

    <div class="modal-content-footer">
        <ed-button [colorSchema]="buttonColors.Secondary" (click)="close()">Annuler</ed-button>
        <ed-button
            [disabled]="
                !passwordFormGroup.dirty || !isNewPasswordValid || passwordFormGroup.invalid
            "
            (click)="changePassword()"
            [loading]="isSaving">
            Enregistrer le mot de passe
        </ed-button>
    </div>
</div>
