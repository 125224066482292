import { Component, HostBinding, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { StyleFunction } from 'leaflet';
import { LegendConfig } from '../map/map.component';

export interface GeoMapCardGeometry {
    organizationId: string;
    year: number;
    layerOrder?: number;
    geom: Geometry;
    showTooltip?: boolean;
    [key: string]: any;
}

@UntilDestroy()
@Component({
    selector: 'geo-map-card',
    templateUrl: './geo-map-card.component.html',
    styleUrls: ['./geo-map-card.component.scss'],
})
export class GeoMapCardComponent {
    @Input() title: string = '';
    @Input() mapId: string = '';
    @Input() descriptionTitle?: string;
    @Input() descriptionContent?: string;
    @Input() error: boolean = false;
    @Input() isLoading: boolean = true;
    @Input() cardClass: string = '';
    @Input() set geometries(value: GeoMapCardGeometry[]) {
        this._createGeoJson(value);
    }
    @Input() getStyle: StyleFunction<GeoJsonProperties> = () => ({});
    @Input() getTooltip: (feature: Feature) => string = () => '';
    @Input() legendConfig: LegendConfig[] = [];

    // Bind the cardClass property to the class attribute of the host element
    @HostBinding('class') get hostClasses(): string {
        return this.cardClass;
    }

    public layers: GeoJSON.FeatureCollection[] = [];

    constructor() {}

    private _createGeoJson(geometries: GeoMapCardGeometry[]): void {
        const layerOrderSet = new Set<number>();
        this.layers = [];

        for (const geom of geometries) {
            layerOrderSet.add(geom.layerOrder || 0);
        }
        const layerOrderArray = Array.from(layerOrderSet).sort((a, b) => a - b);
        layerOrderArray.forEach((order) => {
            this._createLayer(geometries, order);
        });
    }

    private _createLayer(geometries: GeoMapCardGeometry[], order: number): void {
        const features = geometries
            .filter((geometry) => geometry.layerOrder === order)
            .map((geometry) => {
                const { geom, organizationId, year, ...properties } = geometry;

                return {
                    type: 'Feature',
                    properties,
                    geometry: geom,
                } as GeoJSON.Feature;
            });

        this.layers.push({
            type: 'FeatureCollection',
            features,
        });
    }
}
