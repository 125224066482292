import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AlertService, ButtonTypes } from '@ed---interne/ng-uui-components';

import { emailValidator } from 'src/app/shared/validators/email.validator';
import { passwordValidator } from 'src/app/shared/validators/password.validator';
import { parseJwt } from 'src/app/shared/all.helpers';

import { User } from 'src/app/core/models/user.model';
import { Signup } from 'src/app/core/states/auth.state';
import { AuthService } from 'src/app/core/services/auth.service';

@UntilDestroy()
@Component({
    selector: 'signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
})
export class SignUpComponent implements OnInit {
    public ButtonTypes = ButtonTypes;

    public isPasswordVisible: boolean = false;
    public signupFormGroup: FormGroup = this.formBuilder.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        email: ['', [emailValidator(), Validators.required]],
        password: ['', [passwordValidator(), Validators.required]],
    });

    constructor(
        private readonly alertService: AlertService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly authService: AuthService,
        private readonly formBuilder: FormBuilder,
        private router: Router,
        private store: Store,
    ) {}

    async ngOnInit(): Promise<void> {
        this.signupFormGroup.get('email')?.disable();

        const token = this.activatedRoute.snapshot.queryParams['token'];
        const isTokenValid = await this.authService.isAnyUserMatchToken(token);
        if (!isTokenValid) {
            this.alertService.warning(
                'Utilisateur existant',
                'Le compte a déjà été créé. Vous pouvez vous connecter avec vos identifiants.',
            );
            this.router.navigateByUrl('/');
        }

        const payload: any = parseJwt(token);
        this.signupFormGroup.patchValue({
            firstname: payload.firstname,
            lastname: payload.lastname,
            email: payload.sub.toLowerCase(),
        });
    }

    public onSubmit(): void {
        const token = this.activatedRoute.snapshot.queryParams['token'];

        const userToUpdate: Partial<User> = {
            firstname: this.signupFormGroup.value.firstname as string,
            lastname: this.signupFormGroup.value.lastname as string,
            mail: this.signupFormGroup.value.email as string,
            password: this.signupFormGroup.value.password as string,
        };

        this.store
            .dispatch(new Signup({ token: token, user: userToUpdate }))
            .pipe(untilDestroyed(this))
            .subscribe({
                next: () => {
                    this.alertService.valid('Compte', 'Compte correctement créé !');
                    this.router.navigate(['/']);
                },
                error: (err) =>
                    this.alertService.error(
                        'Compte',
                        'Une erreur est survenue. Impossible de créer le compte.',
                    ),
            });
    }

    public togglePasswordVisibility(): void {
        this.isPasswordVisible = !this.isPasswordVisible;
    }
}
