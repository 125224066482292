import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[EdDragAndDrop]',
    standalone: true,
})
export class DragAndDropDirective {
    @HostBinding('class.fileover')
    public fileOver: boolean | undefined;

    @Output()
    public fileDropped = new EventEmitter<any>();

    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt: {
        preventDefault: () => void;
        stopPropagation: () => void;
    }): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt: {
        preventDefault: () => void;
        stopPropagation: () => void;
    }): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    }

    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt: {
        preventDefault: () => void;
        stopPropagation: () => void;
        dataTransfer: { files: any };
    }): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        const files = evt.dataTransfer.files;

        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
