<div class="page-content">
    <div class="page-header">
        <h1>{{ (organization$ | async)?.name }}</h1>
        <div class="page-description">
            Consultez et modifiez l’organisation, les comptes utilisateurs et les études disponibles
        </div>
    </div>

    <div class="page-body">
        <div class="settings-menu">
            <div
                *ngFor="let item of menuItems$ | async"
                class="settings-item"
                [routerLink]="item.routerLink"
                routerLinkActive="selected"
                [routerLinkActiveOptions]="item.routerLinkActiveOptions">
                {{ item.display }}
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
