import { Inject, Injectable } from '@angular/core';
import { RestService } from '../../../../core/services/rest.service';

@Injectable({
    providedIn: 'root',
})
export class ConcessionControlService {
    constructor(@Inject(RestService) private restService: RestService) {}

    public async get(organizationId: string, year: number, key: string): Promise<any> {
        const path = `/organization/${organizationId}/concessioncontrol/${year}/${key}`;
        const datas = await this.restService.get(path, {});
        return datas;
    }
}
