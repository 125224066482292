import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import { catchError, finalize, of, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ButtonTypes } from '@ed---interne/ng-uui-components';

import { Login } from '../../../core/states/auth.state';
import { emailValidator } from '../../../shared/validators/email.validator';
import { OrganizationService } from '../../admin/organization/organization.service';

@UntilDestroy()
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    public ButtonTypes = ButtonTypes;
    public loading = false;

    constructor(
        private store: Store,
        private readonly formBuilder: FormBuilder,
        private organizationService: OrganizationService,
    ) {}

    public loginFormGroup = this.formBuilder.group({
        email: ['', [emailValidator()]],
        password: [''],
    });

    public async login() {
        this.loading = true;

        this.store
            .dispatch(
                new Login({
                    email: this.loginFormGroup.value.email || '',
                    password: this.loginFormGroup.value.password || '',
                }),
            )
            .pipe(
                tap(() => {}),
                catchError((error) => {
                    console.error('Erreur lors de la connexion:', error);
                    return of(null);
                }),
                finalize(() => {
                    this.loading = false;
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }
}
