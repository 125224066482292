<div class="page-content">
    <div class="page-header">
        <h1>{{ title }}</h1>
        <div class="page-description">{{ subTitle }}</div>
    </div>

    <div class="page-body">
        <div class="settings-menu">
            <a
                *ngFor="let item of tabItems$ | async"
                class="settings-item"
                [routerLink]="item.routerLink"
                routerLinkActive="selected"
                [routerLinkActiveOptions]="item.routerLinkActiveOptions">
                {{ item.display }}
            </a>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
