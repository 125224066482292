import { Inject, Injectable } from '@angular/core';
import { RestService } from '../../core/services/rest.service';
import { Document } from '../models/document.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    constructor(@Inject(RestService) private restService: RestService) {}

    public async getAll(
        folderName: string = 'myFolder',
        organizationId: string,
    ): Promise<Document[]> {
        const path = `${this._getBasePath(organizationId)}/folder/${folderName}`;
        const docs = await this.restService.get(path, {});
        return docs;
    }

    public get(documentId: string, organizationId: string): Observable<Object> {
        const path = `${this._getBasePath(organizationId)}/${documentId}`;
        return this.restService.download(path, {});
    }

    public async delete(documentId: string, organizationId: string): Promise<any> {
        const path = `${this._getBasePath(organizationId)}/${documentId}`;
        return await this.restService.delete(path, {});
    }

    public async upload(folderName: string, organizationId: string, file: File): Promise<Document> {
        const path = `${this._getBasePath(organizationId)}/${folderName}`;
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('organizationId', organizationId);

        return await this.restService.post(path, {}, formData);
    }

    private _getBasePath(organizationId: string): string {
        return `/organization/${organizationId}/document`;
    }
}
