<generic-card
    [title]="title"
    [descriptionTitle]="descriptionTitle"
    [descriptionContent]="descriptionContent"
    [footerValue]="footerValue"
    [error]="error"
    [isLoading]="isLoading"
    [ngClass]="cardClass">
    @if (isLoading) {
        <div class="card-text">
            <div class="skeleton skeleton-body"></div>
        </div>
        <div class="badge rounded-pill skeleton skeleton-badge">
            {{ '' }}
        </div>
    } @else {
        <div class="card-text">
            {{ bodyValue }}
        </div>
        <div class="badge rounded-pill" [ngClass]="badgeStyle" *ngIf="!!badgeValue">
            {{ badgeValue }}
        </div>
    }
</generic-card>
