<div class="sidenav-container" *ngIf="topMenuItems.length > 0">
    <div class="top">
        <ul class="list-unstyled">
            <ng-container
                *ngFor="let item of topMenuItems"
                [ngTemplateOutletContext]="{ item: item }"
                [ngTemplateOutlet]="menuItemTemplate"></ng-container>
        </ul>
    </div>
    <div class="bottom">
        <ul class="list-unstyled">
            <ng-container
                *ngFor="let item of bottomMenuItems"
                [ngTemplateOutletContext]="{ item: item }"
                [ngTemplateOutlet]="menuItemTemplate"></ng-container>
        </ul>
    </div>
</div>

<ng-template #menuItemTemplate let-item="item">
    <ng-container *ngIf="!item.hidden">
        <li>
            @if (!item.subItems.length) {
                <a
                    *ngIf="!item.isDivider"
                    [routerLink]="item.routerLink"
                    routerLinkActive="active-item"
                    [routerLinkActiveOptions]="item.routerLinkActiveOptions"
                    class="item"
                    (click)="handleClickOnItem()">
                    <i [class]="'icon ' + item.icon"></i>
                    {{ item.label }}
                </a>
            } @else {
                <mat-accordion>
                    <mat-expansion-panel
                        [expanded]="item.panelOpenState"
                        (opened)="item.panelOpenState = true"
                        (closed)="item.panelOpenState = false">
                        <mat-expansion-panel-header
                            [ngClass]="{
                                'panel-on-focus': item.isActive,
                                'panel-open': item.panelOpenState,
                            }">
                            <mat-panel-title>
                                <i [class]="'icon ' + item.icon"></i>
                            </mat-panel-title>
                            <mat-panel-description>
                                {{ item.label }}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ul class="list-unstyled">
                            <ng-container
                                *ngFor="let subItem of item.subItems"
                                [ngTemplateOutletContext]="{ item: subItem }"
                                [ngTemplateOutlet]="menuItemTemplate"></ng-container>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            }
        </li>
    </ng-container>
</ng-template>
