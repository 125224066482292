import { Component, HostBinding, Input } from '@angular/core';
import { PopupService } from '@ed---interne/ng-uui-components';
import { convertNumber } from '../../all.helpers';

const MAX_DESCRIPTION_LENGTH_TOOLTIP = 500;

interface StatInput {
    prefix?: string;
    value: string | number | undefined;
    suffix?: string;
    precision?: number;
    style?: 'success' | 'danger' | 'info';
}

@Component({
    selector: 'kpi-card',
    templateUrl: './kpi-card.component.html',
    styleUrls: ['./kpi-card.component.scss'],
})
export class KpiCardComponent {
    @Input() title: string | undefined;
    @Input() set body(value: StatInput | undefined) {
        this.bodyValue = this._formatValue(value, false);
    }
    @Input() set badge(value: StatInput | undefined) {
        this.badgeValue = this._formatValue(value, true);
        this.badgeStyle = value?.style || '';
    }
    @Input() set footer(value: StatInput | undefined) {
        this.footerValue = this._formatValue(value, false);
    }
    @Input() descriptionTitle?: string;
    @Input() descriptionContent?: string;
    @Input() error: boolean = false;
    @Input() isLoading: boolean = true;
    @Input() cardClass: string = '';

    // Bind the cardClass property to the class attribute of the host element
    @HostBinding('class') get hostClasses(): string {
        return this.cardClass;
    }

    public bodyValue: string = '';
    public badgeValue: string = '';
    public badgeStyle: string = '';
    public footerValue: string = '';

    constructor(private readonly popupService: PopupService) {}

    public showDescriptionTooltip(): boolean {
        let length = 0;
        if (!!this.descriptionTitle) {
            length += this.descriptionTitle.length;
        }
        if (!!this.descriptionContent) {
            length += this.descriptionContent.length;
        }
        return length > 0 && length <= MAX_DESCRIPTION_LENGTH_TOOLTIP;
    }

    public openDescriptionPopup() {
        this.popupService.openPopup({
            title: this.descriptionTitle
                ? `<div style="text-align: center; margin-top:20px">${this.descriptionTitle}</div>`
                : ' ',
            htmlContent: this.descriptionContent
                ? `<div style="text-align: justify;">${this.descriptionContent}</div>`
                : ' ',
            showCancelButton: false,
            confirmButtonText: 'Fermer',
        });
    }

    private _formatValue(stat: StatInput | undefined, addPlusSign: boolean = false): string {
        if (!stat) {
            return '';
        }

        const stringValue =
            typeof stat.value === 'number'
                ? convertNumber(stat.value, stat.precision, addPlusSign)
                : stat.value || '';

        return `${stat.prefix || ''}${stringValue}${stat.suffix || ''}`;
    }
}
