<div class="page-content">
    <div class="page-header">
        <h1>{{ title }}</h1>
        <div class="page-description">{{ subTitle }}</div>
    </div>

    <div class="page-body">
        <div class="settings-menu">
            <a
                *ngFor="let item of tabItems$ | async"
                class="settings-item"
                [routerLink]="item.routerLink"
                routerLinkActive="selected"
                [routerLinkActiveOptions]="item.routerLinkActiveOptions">
                {{ item.display }}
            </a>
        </div>
        <div class="container-fluid cards-container">
            <div class="row g-3">
                <div class="col-sm-12 col-lg-4">
                    <kpi-card
                        title="Exemple de skeleton"
                        [isLoading]="true"
                        cardClass="h-100"></kpi-card>
                </div>
                <div class="col-sm-12 col-lg-4">
                    <kpi-card title="Exemple d'erreur" [error]="true" cardClass="h-100"></kpi-card>
                </div>
                <div class="col-sm-12 col-lg-4">
                    <kpi-card
                        title="Exemple qui marche"
                        [body]="{ value: 250, suffix: ' kVA' }"
                        [badge]="{ value: 10, suffix: ' kVA', style: 'success' }"
                        [footer]="{ prefix: 'Moyenne nationale: ', value: 250, suffix: ' kVA' }"
                        descriptionContent="Description "
                        descriptionTitle="Titre"
                        [isLoading]="false"
                        cardClass="h-100 justify-content-between"></kpi-card>
                </div>
            </div>

            <div class="row g-3">
                <div class="col-sm-12 col-lg-4">
                    <chart-card
                        title="Exemple de skeleton (graphique)"
                        descriptionContent="Description "
                        descriptionTitle="Titre"
                        [isLoading]="true"
                        cardClass="h-100"></chart-card>
                </div>
                <div class="col-sm-12 col-lg-4 g-3">
                    <chart-card
                        title="Exemple d'erreur (graphique)"
                        descriptionContent="Description "
                        descriptionTitle="Titre"
                        [error]="true"
                        cardClass="h-100"></chart-card>
                </div>
                <div class="col-sm-12 col-lg-4 g-3">
                    <chart-card
                        title="Exemple qui marche (graphique)"
                        descriptionContent="Description"
                        descriptionTitle="Titre"
                        [isLoading]="false"
                        type="pie"
                        [series]="[10, 20, 50, 20, 30, 40]"
                        [labels]="['A', 'B', 'C', 'D', 'E', 'G', 'H', 'I', 'J']"
                        [precision]="0"
                        [fill]="{ opacity: 0.9 }"
                        cardClass="h-100"></chart-card>
                </div>
            </div>

            <div class="row g-3">
                <div class="col-sm-12 col-lg-4">
                    <chart-card
                        title="Exemple qui marche (graphique)"
                        descriptionContent="Description"
                        descriptionTitle="Titre"
                        [isLoading]="false"
                        type="donut"
                        [dataLabels]="{ enabled: true }"
                        [series]="[10, 20, 50, 20, 30, 40]"
                        [labels]="['A', 'B', 'C', 'D', 'E', 'G', 'H', 'I', 'J']"
                        [fill]="{ opacity: 0.9 }"
                        [precision]="1"
                        cardClass="h-100"></chart-card>
                </div>
                <div class="col-sm-12 col-lg-8">
                    <chart-card
                        title="Exemple qui marche (graphique)"
                        descriptionContent="Description"
                        descriptionTitle="Titre"
                        [isLoading]="false"
                        type="boxPlot"
                        [dataLabels]="{ enabled: false }"
                        [series]="[
                            {
                                data: [
                                    { x: 'dataset1', y: [0, 2, 3, 4, 5] },
                                    { x: 'dataset2', y: [1, 2, 5, 6, 7] },
                                    { x: 'dataset3', y: [2, 3, 5, 6, 7] },
                                    { x: 'dataset4', y: [1, 2, 3, 4, 8] },
                                ],
                            },
                        ]"
                        [fill]="{ opacity: 0.9 }"
                        cardClass="h-100"></chart-card>
                </div>
            </div>

            <div class="row g-3">
                <div class="col-sm-12 col-lg-6">
                    <chart-card
                        title="Exemple qui marche (graphique)"
                        descriptionContent="Description"
                        descriptionTitle="Titre"
                        [isLoading]="false"
                        type="boxPlot"
                        [dataLabels]="{ enabled: false }"
                        [series]="[
                            {
                                data: [
                                    { x: 'dataset1', y: [0, 2, 3, 4, 5] },
                                    { x: 'dataset2', y: [1, 2, 5, 6, 7] },
                                    { x: 'dataset3', y: [2, 3, 5, 6, 7] },
                                    { x: 'dataset4', y: [1, 2, 3, 4, 8] },
                                ],
                            },
                        ]"
                        [fill]="{ opacity: 0.9 }"
                        [plotOptions]="{
                            bar: {
                                horizontal: true,
                            },
                        }"
                        cardClass="h-100"></chart-card>
                </div>
                <div class="col-sm-12 col-lg-6">
                    <chart-card
                        id="municipalitiesChart"
                        title="Exemple qui marche (graphique)"
                        descriptionContent="Description "
                        descriptionTitle="Titre"
                        [isLoading]="false"
                        [dataLabels]="{ enabled: true, enabledOnSeries: [1] }"
                        [series]="[
                            {
                                name: 'Histogramme',
                                type: 'column',
                                data: [
                                    { x: 0, y: 0 },
                                    { x: 1, y: 2 },
                                    { x: 2, y: 3 },
                                    { x: 3, y: 4 },
                                ],
                            },
                            {
                                name: 'Courbe',
                                type: 'line',
                                data: [
                                    { x: 0, y: 2 },
                                    { x: 1, y: 3 },
                                    { x: 2, y: 4 },
                                    { x: 3, y: 5 },
                                ],
                            },
                        ]"
                        [precision]="1"
                        [yaxis]="[{}]"
                        cardClass="h-100"></chart-card>
                </div>
            </div>

            <div class="row g-3">
                <div class="col-sm-12 col-lg-6">
                    <chart-card
                        id="municipalitiesChart2"
                        title="Exemple qui marche (graphique)"
                        descriptionContent="Description "
                        descriptionTitle="Titre"
                        [isLoading]="false"
                        [dataLabels]="{ enabled: true, enabledOnSeries: [0, 1] }"
                        [series]="[
                            {
                                name: 'Courbe',
                                type: 'line',
                                data: [
                                    { x: 0, y: 2 },
                                    { x: 1, y: 3 },
                                    { x: 2, y: 4 },
                                    { x: 3, y: 5 },
                                ],
                            },
                            {
                                name: 'Aire',
                                type: 'area',
                                data: [
                                    { x: 0, y: 1 },
                                    { x: 1, y: 2 },
                                    { x: 2, y: 5 },
                                    { x: 3, y: 6 },
                                ],
                            },
                        ]"
                        cardClass="h-100"></chart-card>
                </div>
            </div>
        </div>
    </div>
</div>
