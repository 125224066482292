import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from '@ed---interne/ng-uui-components';

import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SignUpComponent } from './signup/signup.component';

@NgModule({
    declarations: [
        AuthComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        SignUpComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ComponentsModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthModule {}
