import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, map, Observable } from 'rxjs';
import { SetCurrentYear } from '../../features/admin/organization/organization.state';

export const yearGuard: CanActivateFn = (
    next: ActivatedRouteSnapshot,
): Observable<boolean> | Promise<boolean> | boolean => {
    const router = inject(Router);
    const store = inject(Store);
    const year = next.params['year'];

    if (!year) {
        return false;
    }

    return store.dispatch(new SetCurrentYear({ year })).pipe(
        map(() => true),
        catchError(() => {
            return router.navigate(['/main']);
        }),
    );
};
