import { Component, Input, TemplateRef } from '@angular/core';

import { ConcessionControl } from 'src/app/core/models/concession-control.model';

@Component({
    selector: 'app-studies',
    templateUrl: './studies.component.html',
    styleUrls: ['./studies.component.scss'],
})
export class StudiesComponent {
    @Input() ccStudies: ConcessionControl[] = [];
    @Input() ccStudyHeaderTemplate!: TemplateRef<any>;
    @Input() ccStudyBodyTemplate!: TemplateRef<any>;

    @Input() cracStudies: any[] = [];
    @Input() cracStudyHeaderTemplate!: TemplateRef<any>;
    @Input() cracStudyBodyTemplate!: TemplateRef<any>;
}
