import { Component, effect, inject, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { ConcessioncontrolState, PrepareDataFromKeys } from '../../states/concessioncontrol.state';
import { CC_CONSTANTS } from '../../assets/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericComponent } from '../generic/generic.component';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { OrganizationState } from '../../../../admin/organization/organization.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { ApexAxisChartSeries, ApexDataLabels, ApexYAxis } from 'ng-apexcharts';
import { colorAec1BlueLight, colorAec8RedDark } from 'src/app/shared/all.constants';

const MV_NETWORKS_UNDEGROUNDING_KPIS = 'ouvrages_mv_networks_undergrounding_kpis';
const MV_NETWORKS_UNDERGROUNDING_RATE_DEP_CHART =
    'ouvrages_mv_networks_undergrounding_rate_dep_chart';
const MV_NETWORKS_UNDERGROUNDING_RATE_CONCESSION_CHART =
    'ouvrages_mv_networks_undergrounding_rate_concession_chart';
const KEYS = [
    MV_NETWORKS_UNDEGROUNDING_KPIS,
    MV_NETWORKS_UNDERGROUNDING_RATE_DEP_CHART,
    MV_NETWORKS_UNDERGROUNDING_RATE_CONCESSION_CHART,
];

interface KpiData {
    //Minimum of a line
    organizationId?: string;
    year?: string;
    error?: boolean;

    totalMvNetworkLength?: number;
    mvBurialRate?: number;
    mvFeedersCount?: number;
    mvFeedersOver70kmCount?: number;
    nbMVFeederLongestName?: string;
    nbMVFeederLongestLength?: number;
}

interface ChartData {
    organizationId?: string;
    year?: string;
    xDensityUsersPerKmNetwork?: number;
    yUndergroundRate?: number;
    error?: boolean;
}

@UntilDestroy()
@Component({
    selector: 'app-mv-networks-undergrounding',
    templateUrl: './mv-networks-undergrounding.component.html',
    styleUrls: ['./mv-networks-undergrounding.component.scss'],
})
export class MvNetworksUndergroundingComponent extends GenericComponent {
    public kpis: Signal<KpiData[]> = this.store.selectSignal(
        ConcessioncontrolState.getDataByKey(MV_NETWORKS_UNDEGROUNDING_KPIS),
    ) as Signal<KpiData[]>;

    public texts = CC_CONSTANTS.mvNetworks.tabs.undergrounding.texts;

    public chartTitle = this.texts.mvUndergroundRateChart.title;
    public chartSeries: ApexAxisChartSeries | undefined;
    public dataLabels: ApexDataLabels = { enabled: true, enabledOnSeries: [] };

    public chartDataDep: Signal<ChartData[]> = this.store.selectSignal(
        ConcessioncontrolState.getDataByKey(MV_NETWORKS_UNDERGROUNDING_RATE_DEP_CHART),
    ) as Signal<ChartData[]>;

    public chartDataConcession: Signal<ChartData[]> = this.store.selectSignal(
        ConcessioncontrolState.getDataByKey(MV_NETWORKS_UNDERGROUNDING_RATE_CONCESSION_CHART),
    ) as Signal<ChartData[]>;

    public yaxis: ApexYAxis[] = [
        {
            seriesName: this.texts.mvUndergroundRateChart.mvUndergroundRateConcessionAxisTitle,
            min: 0,
        },
        {
            seriesName: this.texts.mvUndergroundRateChart.mvUndergroundRateConcessionAxisTitle,
            min: 0,
        },
    ];

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly sidenavService: SidenavService,
    ) {
        //GenericComponent intialize sideNav, Tabs (if exists), title and subTitle
        super(route, sidenavService);

        effect(() => {
            const dataListConcession = this.chartDataConcession();
            const dataListDep = this.chartDataDep();
            if (
                dataListConcession?.length > 0 &&
                !dataListConcession[0].error &&
                dataListDep?.length > 0 &&
                !dataListDep[0].error
            ) {
                this.chartSeries = [
                    {
                        name: this.texts.mvUndergroundRateChart
                            .mvUndergroundRateDepartmentAxisTitle,
                        type: 'scatter',
                        data: dataListDep.map((data) => ({
                            x: data.xDensityUsersPerKmNetwork,
                            y: data.yUndergroundRate,
                        })),
                        color: colorAec1BlueLight,
                    },
                    {
                        name: this.texts.mvUndergroundRateChart
                            .mvUndergroundRateConcessionAxisTitle,
                        type: 'scatter',
                        data: dataListConcession.map((data) => ({
                            x: data.xDensityUsersPerKmNetwork,
                            y: data.yUndergroundRate,
                        })),
                        color: colorAec8RedDark,
                    },
                ];
                //Add dataLabels
                this.dataLabels = { enabled: true, enabledOnSeries: [] };
            }
        });
    }

    ngOnInit() {
        // Every time currentOrganization or currentYear changes, we tell the store he has to ask for the data
        combineLatest([this._currentOrganization$, this._currentYear$])
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(() => {
                this.store.dispatch(new PrepareDataFromKeys({ keys: KEYS }));
            });
    }
}
