<div class="container-fluid cards-container">
    @let kpi = kpis()[0];
    <div class="row g-3">
        <div class="col-sm-6 col-lg-3">
            <kpi-card
                [title]="texts.mvNetworksAverageAge.title"
                [body]="{
                    value: kpi.mvNetworksAverageAge,
                    suffix: texts.mvNetworksAverageAge.suffix,
                    precision: texts.mvNetworksAverageAge.precision,
                }"
                [badge]="{
                    value: kpi.mvNetworksAverageAgeEvolution,
                    suffix: texts.mvNetworksAverageAge.badgeSuffix,
                    precision: texts.mvNetworksAverageAge.badgePrecision,
                    style: kpi.mvNetworksAverageAgeEvolution! > 0 ? 'success' : 'danger',
                }"
                [descriptionTitle]="texts.mvNetworksAverageAge.descriptionTitle"
                [descriptionContent]="texts.mvNetworksAverageAge.descriptionContent"
                [isLoading]="!kpi.organizationId"
                [error]="checkKpiErrors(kpi, 'mvNetworksAverageAge')"
                cardClass="h-100">
            </kpi-card>
        </div>

        <div class="col-sm-6 col-lg-3">
            <kpi-card
                [title]="texts.mvNetworksLengthOver40Km.title"
                [body]="{
                    value: kpi.mvNetworksLengthOver40Km,
                    suffix: texts.mvNetworksLengthOver40Km.suffix,
                    precision: texts.mvNetworksLengthOver40Km.precision,
                }"
                [badge]="{
                    value: kpi.mvNetworksLengthOver40KmEvolution,
                    suffix: texts.mvNetworksLengthOver40Km.badgeSuffix,
                    precision: texts.mvNetworksLengthOver40Km.badgePrecision,
                    style: kpi.mvNetworksLengthOver40KmEvolution! > 0 ? 'success' : 'danger',
                }"
                [descriptionTitle]="texts.mvNetworksLengthOver40Km.descriptionTitle"
                [descriptionContent]="texts.mvNetworksLengthOver40Km.descriptionContent"
                [isLoading]="!kpi.organizationId"
                [error]="checkKpiErrors(kpi, 'mvNetworksLengthOver40Km')"
                cardClass="h-100">
            </kpi-card>
        </div>

        <div class="col-sm-6 col-lg-3">
            <kpi-card
                [title]="texts.mvNetworksLengthCpi.title"
                [body]="{
                    value: kpi.mvNetworksLengthCpi,
                    suffix: texts.mvNetworksLengthCpi.suffix,
                    precision: texts.mvNetworksLengthCpi.precision,
                }"
                [badge]="{
                    value: kpi.mvNetworksLengthCpiEvolution,
                    suffix: texts.mvNetworksLengthCpi.badgeSuffix,
                    precision: texts.mvNetworksLengthCpi.badgePrecision,
                    style: kpi.mvNetworksLengthCpiEvolution! > 0 ? 'success' : 'danger',
                }"
                [descriptionTitle]="texts.mvNetworksLengthCpi.descriptionTitle"
                [descriptionContent]="texts.mvNetworksLengthCpi.descriptionContent"
                [isLoading]="!kpi.organizationId"
                [error]="checkKpiErrors(kpi, 'mvNetworksLengthOver40Km')"
                cardClass="h-100">
            </kpi-card>
        </div>

        <div class="col-sm-6 col-lg-3">
            <kpi-card
                [title]="texts.mvNetworksLengthLowSection.title"
                [body]="{
                    value: kpi.mvNetworksLengthLowSection,
                    suffix: texts.mvNetworksLengthLowSection.suffix,
                    precision: texts.mvNetworksLengthLowSection.precision,
                }"
                [badge]="{
                    value: kpi.mvNetworksLengthLowSectionEvolution,
                    suffix: texts.mvNetworksLengthLowSection.badgeSuffix,
                    precision: texts.mvNetworksLengthLowSection.badgePrecision,
                    style: kpi.mvNetworksLengthLowSectionEvolution! > 0 ? 'success' : 'danger',
                }"
                [descriptionTitle]="texts.mvNetworksLengthLowSection.descriptionTitle"
                [descriptionContent]="texts.mvNetworksLengthLowSection.descriptionContent"
                [isLoading]="!kpi.organizationId"
                [error]="checkKpiErrors(kpi, 'mvNetworksLengthLowSection')"
                cardClass="h-100">
            </kpi-card>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-12">
            <geo-map-card title="Big MAP" cardClass="h-100"> </geo-map-card>
        </div>
    </div> -->
</div>
