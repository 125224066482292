export function parseJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

export function convertNumber(
    number: number | undefined,
    decimals: number = 0,
    addPlusSign: boolean = false,
) {
    if (!number && number !== 0) {
        return '';
    }

    const prefix: string = addPlusSign && number >= 0 ? '+' : '';
    // Use the right number of decimals and split the number in integer and decimals parts
    let [integerPart, decimalsPart] = number.toFixed(decimals).split('.');
    // Add a space every three digits
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return prefix + [integerPart, decimalsPart].filter((part) => !!part).join(',');
}
