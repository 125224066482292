import { Component, Input } from '@angular/core';
import { PopupService } from '@ed---interne/ng-uui-components';

const MAX_DESCRIPTION_LENGTH_TOOLTIP = 500;

@Component({
    selector: 'generic-card',
    templateUrl: './generic-card.component.html',
    styleUrls: ['./generic-card.component.scss'],
})
export class GenericCardComponent {
    @Input() title: string | undefined;
    @Input() descriptionTitle?: string;
    @Input() descriptionContent?: string;
    @Input() footerValue?: string = '';
    @Input() error: boolean = false;
    @Input() isLoading: boolean = false;

    constructor(private readonly popupService: PopupService) {}

    public showDescriptionTooltip(): boolean {
        let length = 0;
        if (!!this.descriptionTitle) {
            length += this.descriptionTitle.length;
        }
        if (!!this.descriptionContent) {
            length += this.descriptionContent.length;
        }
        return length > 0 && length <= MAX_DESCRIPTION_LENGTH_TOOLTIP;
    }

    public openDescriptionPopup() {
        this.popupService.openPopup({
            title: this.descriptionTitle
                ? `<div style="text-align: center; margin-top:20px">${this.descriptionTitle}</div>`
                : ' ',
            htmlContent: this.descriptionContent
                ? `<div style="text-align: justify;">${this.descriptionContent}</div>`
                : ' ',
            showCancelButton: false,
            confirmButtonText: 'Fermer',
        });
    }
}
