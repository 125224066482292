<generic-card
    [title]="title"
    [descriptionTitle]="descriptionTitle"
    [descriptionContent]="descriptionContent"
    [ngClass]="cardClass"
    [error]="error"
    [isLoading]="isLoading">
    @if (isLoading) {
        <div class="skeleton skeleton-map"></div>
    } @else {
        <ed-map
            [layers]="layers"
            [getStyle]="getStyle"
            [getTooltip]="getTooltip"
            [legendConfig]="legendConfig"
            [mapId]="mapId"
            [title]="title"></ed-map>
    }
</generic-card>
