import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
    canvas,
    circleMarker,
    CircleMarkerOptions,
    Control,
    DomUtil,
    geoJSON,
    GeoJSONOptions,
    LatLng,
    latLngBounds,
    Layer,
    map,
    Map,
    StyleFunction,
    tileLayer,
} from 'leaflet';
import html2canvas from 'html2canvas';
import { DownloadOptions, ZipOptions, zip } from '@mapbox/shp-write';
import { Geometry, GeoJSON, GeoJsonProperties, Feature } from 'geojson';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';

export interface LegendConfig {
    title: string;
    values: { color: string; value: string; size?: number }[];
}

@UntilDestroy()
@Component({
    selector: 'ed-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnDestroy {
    @Input() layers: GeoJSON.FeatureCollection[] = [];
    @Input() getStyle: StyleFunction<GeoJsonProperties> = () => ({});
    @Input() getTooltip: (feature: Feature) => string = () => '';
    @Input() legendConfig: LegendConfig[] = [];
    @Input() title: string = '';
    @Input() mapId: string = 'mapId';

    private _map!: Map;
    private _leafletLayers: GeoJSON<any, Geometry>[] = [];

    constructor(private readonly datePipe: DatePipe) {}

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this._initializeMap();
            this._addLayers();
            this._centerMap();
            this._addLegend();
        }, 0);
    }

    private _initializeMap(): void {
        this._leafletLayers = [];
        const baseMapURl = 'https://a.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}@2x.png';
        this._map = map(this.mapId, {
            renderer: canvas(), // Rendre toutes les couches avec Canvas
        });
        tileLayer(baseMapURl, {
            crossOrigin: true, // Important pour html2canvas
        }).addTo(this._map);
        this._map.attributionControl.setPrefix('Leaflet');
    }

    private _addLayers(): void {
        this.layers.forEach((layer) => {
            const options: GeoJSONOptions = {
                style: this.getStyle,
                onEachFeature: this._onEachFeature.bind(this),
            };

            const isPointsLayer = layer.features[0].geometry.type === 'Point';

            if (isPointsLayer) {
                options.pointToLayer = this._pointsToCircles();
            }

            const leafletLayer = geoJSON(layer, options);
            leafletLayer.addTo(this._map);
            this._leafletLayers.push(leafletLayer);
        });
    }

    private _pointsToCircles() {
        return (feature: GeoJSON.Feature, latlng: LatLng) => {
            const style = this.getStyle(feature) as CircleMarkerOptions;
            return circleMarker(latlng, style);
        };
    }

    private _centerMap(): void {
        // Create a LatLngBounds object to encompass all the features locations
        const bounds = latLngBounds(this._leafletLayers.map((layer) => layer.getBounds()));

        // Fit the map view to the bounds
        this._map.fitBounds(bounds);
        window.dispatchEvent(new Event('resize'));
    }

    private _onEachFeature(feature: GeoJSON.Feature, layer: Layer): void {
        layer.bindTooltip(this.getTooltip(feature), {
            permanent: !!feature.properties!['showTooltip'],
        });
    }

    private _addLegend() {
        const legend = new Control({ position: 'bottomleft' });

        legend.onAdd = () => {
            const div = DomUtil.create('div', 'legend');

            this.legendConfig.forEach((config) => {
                div.innerHTML += `<div class="legend-title">${config.title}</div>`;
                const maxSize = config.values.reduce(
                    (acc, value) => ((value.size || 0) > acc ? value.size || 0 : acc),
                    0,
                );
                config.values.forEach((value) => {
                    let style = `background:${value.color};`;

                    if (value.size !== undefined) {
                        style += `border-radius: 50%;width: ${value.size * 2}px;height: ${value.size * 2}px;margin: 0 ${maxSize - value.size}px;`;

                        if (value.size === 0) {
                            style += 'border: none';
                        }
                    }

                    div.innerHTML += `<div class="legend-item"><i style="${style}"></i>${value.value}</div>`;
                });
            });

            return div;
        };

        legend.addTo(this._map);
    }

    public export(layerOrder?: number): void {
        const date = new Date();
        const fileName = `${this.title.replace(' ', '_')}_${this.datePipe.transform(date, "dd.MM.yyyy_HH'h'mm")}`;

        if (layerOrder !== undefined) {
            this.downloadShapeFile(layerOrder, fileName);
        } else {
            this.downloadImage(fileName);
        }
    }

    public async downloadImage(fileName: string): Promise<void> {
        const mapElement = document.getElementById(this.mapId);

        if (!mapElement) {
            return;
        }

        this._leafletLayers.forEach((l) => l.bringToFront());

        const canvas = await html2canvas(mapElement, {
            useCORS: true, // Permet de capturer les tuiles externes respectant CORS.
            logging: true, // Utile pour le débogage.
            allowTaint: false, // Empêche les erreurs liées au CORS
        });
        // Convertir le canvas en image
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = `${fileName}.png`;
        link.href = imgData;
        link.click();
    }

    public async downloadShapeFile(layerOrder: number, fileName: string): Promise<void> {
        const options: DownloadOptions & ZipOptions = {
            folder: fileName,
            filename: this.legendConfig[layerOrder].title,
            outputType: 'blob',
            compression: 'DEFLATE',
        };
        const zipFile = await zip(this.layers[layerOrder], options);
        saveAs(zipFile as Blob, `${fileName}.zip`);
    }

    public ngOnDestroy(): void {
        this._map.remove();
    }
}
