<div class="container-fluid cards-container">
    @let kpi = kpis()[0];
    <div class="row g-3">
        <div class="col-sm-6 col-lg-3">
            <kpi-card
                [title]="texts.totalMvNetworkLength.title"
                [body]="{
                    value: kpi.totalMvNetworkLength,
                    suffix: texts.totalMvNetworkLength.suffix,
                }"
                [descriptionTitle]="texts.totalMvNetworkLength.descriptionTitle"
                [descriptionContent]="texts.totalMvNetworkLength.descriptionContent"
                [isLoading]="!kpi.organizationId"
                [error]="checkKpiErrors(kpi, 'totalMvNetworkLength')"
                cardClass="h-100"></kpi-card>
        </div>

        <div class="col-sm-6 col-lg-3">
            <kpi-card
                [title]="texts.mvBurialRate.title"
                [body]="{
                    value: kpi.mvBurialRate,
                    suffix: texts.mvBurialRate.suffix,
                }"
                [descriptionTitle]="texts.mvBurialRate.descriptionTitle"
                [descriptionContent]="texts.mvBurialRate.descriptionContent"
                [isLoading]="!kpi.organizationId"
                [error]="checkKpiErrors(kpi, 'mvBurialRate')"
                cardClass="h-100">
            </kpi-card>
        </div>

        <div class="col-sm-6 col-lg-3">
            <kpi-card
                [title]="texts.mvFeedersCount.title"
                [body]="{ value: kpi.mvFeedersCount }"
                [descriptionTitle]="texts.mvFeedersCount.descriptionTitle"
                [descriptionContent]="texts.mvFeedersCount.descriptionContent"
                [isLoading]="!kpi.organizationId"
                [error]="checkKpiErrors(kpi, 'mvFeedersCount')"
                cardClass="h-100">
            </kpi-card>
        </div>
        <div class="col-sm-6 col-lg-3">
            <kpi-card
                [title]="texts.mvFeedersOver70kmCount.title"
                [body]="{ value: kpi.mvFeedersOver70kmCount }"
                [isLoading]="!kpi.organizationId"
                [error]="
                    checkKpiErrors(
                        kpi,
                        'mvFeedersOver70kmCount',
                        'nbMVFeederLongestName',
                        'nbMVFeederLongestLength'
                    )
                "
                [footer]="{
                    prefix: 'Départ ' + kpi.nbMVFeederLongestName + ' : ',
                    value: kpi.nbMVFeederLongestLength,
                    suffix: texts.mvFeedersOver70kmCount.footerSuffix + ' de long',
                    precision: texts.mvFeedersOver70kmCount.footerPrecision,
                }"
                cardClass="h-100">
            </kpi-card>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-12">
            <chart-card
                id="mvUndergroundRateChart"
                [title]="chartTitle"
                [descriptionTitle]="texts.mvUndergroundRateChart.descriptionTitle"
                [descriptionContent]="texts.mvUndergroundRateChart.descriptionContent"
                [error]="!!chartDataDep()[0].error"
                [isLoading]="!chartDataDep()[0].organizationId"
                [series]="chartSeries"
                type="scatter"
                [dataLabels]="dataLabels"
                [yaxis]="yaxis"
                [precision]="2"
                class="h-100">
            </chart-card>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <geo-map-card title="Big MAP" cardClass="h-100"> </geo-map-card>
        </div>
    </div>
</div>
