import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { NgxFilesizeModule } from 'ngx-filesize';
import { AlertModule, ComponentsModule } from '@ed---interne/ng-uui-components';

import { SidenavComponent } from '../../shared/components/sidenav/sidenav.component';
import { MainComponent } from './main.component';
import { HomeComponent } from './home/home.component';

import { AccountComponent } from './settings/account/account.component';
import { AccountPasswordEditComponent } from './settings/edit-password/account-password-edit.component';

import { UsersComponent } from '../admin/users/users.component';

import { SkeletonListComponent } from '../../shared/components/skeleton-list/skeleton-list.component';

import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { EdTableModule } from '../../shared/components/ed-table/ed-table.module';

import { InfiniteScrollDirective } from '../../shared/directives/infinite-scroll.directive';
import { DragAndDropDirective } from '../../shared/directives/drag-and-drop.directive';

import { OrganizationComponent } from '../admin/organization/organization.component';

import { CracComponent } from './crac/crac.component';
import { GeneralCracComponent } from './crac/components/generalcrac/generalcrac.component';

import { GenericComponent } from './concessioncontrol/components/generic/generic.component';
import { WelcomeComponent } from './concessioncontrol/components/welcome/welcome.component';
import { GeneralInfoComponent } from './concessioncontrol/components/general-info/general-info.component';
import { SourceStationsComponent } from './concessioncontrol/components/sourcestations/sourcestations.component';

import { MvNetworksComponent } from './concessioncontrol/components/mv-networks/mv-networks.component';
import { MvNetworksUndergroundingComponent } from './concessioncontrol/components/mv-networks-undergrounding/mv-networks-undergrounding.component';
import { MvNetworksAgesComponent } from './concessioncontrol/components/mv-networks-ages/mv-networks-ages.component';

@NgModule({
    declarations: [
        MainComponent,
        SidenavComponent,
        HomeComponent,
        UsersComponent,
        AccountComponent,
        AccountPasswordEditComponent,
        OrganizationComponent,
        CracComponent,
        GeneralCracComponent,
        GenericComponent,
        WelcomeComponent,
        GeneralInfoComponent,
        SourceStationsComponent,
        MvNetworksComponent,
        MvNetworksUndergroundingComponent,
        MvNetworksAgesComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        SharedModule,
        ComponentsModule,
        CoreModule,
        SkeletonListComponent,
        EdTableModule,
        InfiniteScrollDirective,
        DragAndDropDirective,
        ReactiveFormsModule,
        MatMenuModule,
        MatExpansionModule,
        MatSidenavModule,
        MatTableModule,
        MatSortModule,
        AlertModule,
        NgxFilesizeModule,
    ],
})
export class MainModule {}
