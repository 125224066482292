<div class="header">
    <div class="icon-box info"><i class="icon icon-key-01"></i></div>
    <div class="header-content">
        <h1>Créer votre compte</h1>
        <div class="subtitle">Vérifiez vos informations et créez votre mot de passe</div>
    </div>
</div>

<form (ngSubmit)="onSubmit()" [formGroup]="signupFormGroup" class="content">
    <div>
        <label for="firstname">Prénom</label>
        <ed-textfield
            innerId="firstname"
            type="text"
            placeholder="Jeanne"
            formControlName="firstname"></ed-textfield>
    </div>

    <div>
        <label for="lastname">Nom</label>
        <ed-textfield
            innerId="lastname"
            type="text"
            placeholder="Martin"
            formControlName="lastname"></ed-textfield>
    </div>

    <div>
        <label for="email">Adresse email</label>
        <ed-textfield
            innerId="email"
            type="text"
            placeholder="jeanne@martin.fr"
            formControlName="email"></ed-textfield>
    </div>

    <div>
        <label for="password">Mot de passe</label>
        <ed-textfield
            innerId="password"
            [type]="isPasswordVisible ? 'text' : 'password'"
            formControlName="password"
            (onIconClick)="togglePasswordVisibility()"
            [icon]="isPasswordVisible ? 'icon-eye' : 'icon-eye-off'"
            [iconPosition]="'right'"
            [iconClickable]="true"
            autocomplete="new-password"
            placeholder="Choisissez votre mot de passe"></ed-textfield>
    </div>

    <ed-button [type]="ButtonTypes.Submit" [disabled]="signupFormGroup.invalid">
        Créer mon compte
    </ed-button>
</form>

<div class="user-already-exists">
    Vous avez déjà un compte ?
    <span>
        <a routerLink="/" class="btn-connect">Se connecter</a>
    </span>
</div>
