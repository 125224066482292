@if (error) {
    <div class="generic-card-title">
        <h6>{{ title || ' ' }}</h6>
    </div>

    <div class="generic-card-body flex-grow-1">
        <div class="card-text error">
            <i class="icon icon-unplug"></i>
            <span> Information non disponible</span>
        </div>
    </div>
} @else {
    <div class="generic-card-title" *ngIf="!!title">
        @if (isLoading) {
            <h6>
                <div class="skeleton skeleton-title1"></div>
                <div class="skeleton skeleton-title2"></div>
            </h6>
            <div>
                <i class="bi bi-circle-fill skeleton-icon"></i>
            </div>
        } @else {
            <h6>{{ title }}</h6>
            <div
                *ngIf="(descriptionTitle || descriptionContent) && !showDescriptionTooltip()"
                class="generic-card-description"
                (click)="openDescriptionPopup()">
                <i class="bi bi-info-circle"></i>
            </div>
            <div *ngIf="showDescriptionTooltip()" tippyClassName="ed-custom-tippy">
                <i
                    class="bi bi-info-circle"
                    [tippyProps]="{
                        allowHTML: true,
                        placement: 'auto',
                        delay: [null, 200],
                        content:
                            '<div>' +
                            (descriptionTitle ? '<b>' + descriptionTitle + '</b><br/>' : '') +
                            (descriptionContent || '') +
                            '</div>',
                    }"
                    ngxTippy
                    tippy></i>
            </div>
        }
    </div>

    <div class="generic-card-body">
        <ng-content></ng-content>
    </div>

    <div class="generic-card-footer" *ngIf="!!footerValue">
        {{ footerValue }}
    </div>
}
