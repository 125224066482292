import { inject } from '@angular/core';
import {
    CanDeactivateFn,
    Router,
    UrlTree,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { PopupService } from '@ed---interne/ng-uui-components';
import { AccountComponent } from './account.component';
import Swal from 'sweetalert2';

export const canDeactivateGuard: CanDeactivateFn<AccountComponent> = (
    component: AccountComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: { url: string | UrlTree },
): boolean => {
    const popupService = inject(PopupService);
    const router = inject(Router);

    if (component.userFormGroup.dirty) {
        popupService
            .openPopup({
                title: 'Sauvegarde des modifications',
                htmlContent: 'Souhaitez-vous sauvegarder les modifications effectuées ?',
                cancelButtonText: 'Annuler les changements',
                confirmButtonText: 'Sauvegarder',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    component.onSubmit();
                }
                if (result.dismiss !== Swal.DismissReason.close) {
                    component.userFormGroup.reset();
                    router.navigateByUrl(nextState.url);
                }
            });
        return false;
    }
    return true;
};
