<div class="modal-content">
    <div class="modal-content-header">
        <h1>Ajouter une nouvelle organisation</h1>
    </div>

    <div class="modal-content-body" [formGroup]="organizationFormGroup">
        <div class="row d-flex align-items-center">
            <label for="avatar-upload" class="col-auto me-5">Icône d'organisation</label>
            <div class="col-auto">
                <label class="custom-file-upload">
                    <input id="avatar-upload" type="file" (change)="onFileSelect($event)" />
                    @if (!avatarUrl) {
                        <div
                            class="avatar-upload empty"
                            tippyClassName="ed-custom-tippy"
                            [tippyProps]="{
                                allowHTML: true,
                                placement: 'top',
                                content:
                                    '<div><b>Téléverser un avatar</b></div><div>Format rond ou carré (JPG, PNG, TIFF)</div>',
                            }"
                            ngxTippy>
                            <i class="icon icon-upload-cloud-02"></i>
                        </div>
                    } @else {
                        <div
                            class="avatar-upload uploaded"
                            tippyClassName="ed-custom-tippy"
                            [tippyProps]="{
                                allowHTML: true,
                                placement: 'top',
                                content:
                                    '<div><b>Modifier l\'icone de l\'organisation</b></div><div>Format rond ou carré (JPG, PNG, TIFF)</div>',
                            }"
                            ngxTippy>
                            <img alt="preview" [src]="avatarUrl" width="70" height="70" />
                            <i class="icon icon-upload-cloud-02"></i>
                        </div>
                    }
                </label>
            </div>
        </div>

        <div>
            <label for="name" class="required">Nom de l'organisation</label>
            <ed-textfield
                innerId="name"
                name="name"
                type="text"
                placeholder=""
                [required]="true"
                [formControlName]="'name'"></ed-textfield>
        </div>
    </div>

    <div class="modal-content-footer">
        <ed-button [colorSchema]="buttonColors.Secondary" (click)="close()">Retour</ed-button>
        <ed-button (click)="valid()" [disabled]="isCreateDisabled()"
            >Ajouter l'organisation</ed-button
        >
    </div>
</div>
