<app-studies
    [ccStudies]="ccStudies"
    [ccStudyHeaderTemplate]="ccStudyHeaderTemplate"
    [ccStudyBodyTemplate]="ccStudyBodyTemplate"></app-studies>

<ng-template #ccStudyHeaderTemplate let-study="study">
    @if (editingStudies.has(study.id)) {
        <ng-container
            *ngTemplateOutlet="ccStudyHeaderEditTemplate; context: { study: study }"></ng-container>
    } @else {
        <ng-container
            *ngTemplateOutlet="ccStudyHeaderShowTemplate; context: { study: study }"></ng-container>
    }
</ng-template>

<ng-template #ccStudyBodyTemplate let-study="study">
    @if (editingStudies.has(study.id)) {
        <ng-container
            *ngTemplateOutlet="ccStudyBodyEditTemplate; context: { study: study }"></ng-container>
    } @else {
        <ng-container
            *ngTemplateOutlet="ccStudyBodyShowTemplate; context: { study: study }"></ng-container>
    }
</ng-template>

<ng-template #ccStudyHeaderEditTemplate let-study="study">
    <ed-button [icon]="'icon-save-01'" (click)="saveChanges(study.id)" *ngIf="!study.isLoading">
        Enregistrer
    </ed-button>
    <ed-button [loading]="true" *ngIf="study.isLoading"> </ed-button>
    <ed-button [colorSchema]="buttonColors.Secondary" (click)="cancelEdit(study.id)">
        Annuler
    </ed-button>
</ng-template>

<ng-template #ccStudyHeaderShowTemplate let-study="study">
    <ed-button [icon]="'icon-edit-02'" (click)="editStudy(study.id)">Modifier</ed-button>
    <ed-button [colorSchema]="buttonColors.Secondary" (click)="openConcessionControl(study.year)"
        >Ouvrir</ed-button
    >
</ng-template>

<ng-template #ccStudyBodyEditTemplate let-study="study">
    <form class="body edit" [formGroup]="studiesFormGroups[study.id]">
        <div>
            <label [for]="'status-' + study.year">Statut</label>
            <ed-select
                formControlName="status"
                [innerId]="'status-' + study.year"
                [disableRipple]="true"
                [disableOptionCentering]="true"
                [options]="statusOptions">
            </ed-select>
        </div>
        <div>
            <label [for]="'sharedNotes-' + study.year">Description</label>
            <ed-textarea
                [innerId]="'sharedNotes-' + study.year"
                [baseHeight]="180"
                [isResizable]="false"
                [maxTextSize]="10000"
                formControlName="description"
                placeholder="Saisir une description">
            </ed-textarea>
        </div>

        @if (!studiesFormGroups[study.id].get('document')?.value) {
            <div
                EdDragAndDrop
                (fileDropped)="onFileDropped($event, study.id)"
                class="add-file no-file">
                <i class="icon icon-upload-cloud-02"></i>
                <label class="custom-file-upload">
                    <input
                        type="file"
                        accept="application/pdf"
                        (change)="onFileSelect($event, study.id)" />
                    <span class="field-name">Charger le rapport PDF</span>
                    <span class="drag-text"> ou glisser-déposer (50 Mo maximum)</span>
                </label>
            </div>
        } @else {
            <div class="add-file">
                <i class="icon icon-file-plus-02"></i>
                <span class="field-name">Rapport PDF</span>
                <span class="file-name">{{
                    studiesFormGroups[study.id].get('document')?.value.name
                }}</span>
                <span class="file-size">{{
                    getFileSize(this.studiesFormGroups[study.id].get('document')?.value.size)
                }}</span>
                <div class="right-icons">
                    <!-- <i class="icon icon-download-cloud-01" (click)="downloadFile()"></i> -->
                    <i class="icon icon-trash-01" (click)="deleteFile(study.id)"></i>
                </div>
            </div>
        }
    </form>
</ng-template>

<ng-template #ccStudyBodyShowTemplate let-study="study">
    <div class="body">
        <div class="badge rounded-pill" [ngClass]="getBadgeClass(study.status)">
            {{ getBadgeLabel(study.status) }}
        </div>
        <p class="description" *ngIf="!!study.description">{{ study.description }}</p>

        @if (!studiesFormGroups[study.id].get('document')?.value) {
            <div class="add-file">
                <i class="icon icon-upload-cloud-02"></i>
                <div>
                    <span class="drag-text">Le document </span>
                    <span class="field-name">Rapport PDF </span>
                    <span class="drag-text">n'a pas été chargé</span>
                </div>
            </div>
        } @else {
            <div class="add-file">
                <i class="icon icon-file-plus-02"></i>
                <span class="field-name">Rapport PDF</span>
                <span class="file-name">{{
                    studiesFormGroups[study.id].get('document')?.value.name
                }}</span>
                <span class="file-size">{{
                    getFileSize(this.studiesFormGroups[study.id].get('document')?.value.size)
                }}</span>
            </div>
        }
    </div>
</ng-template>
