import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable, of } from 'rxjs';

import { Organization } from '../../../../core/models/organization.model';
import { OrganizationState } from '../organization.state';
import { SettingsMenuItem } from '../organization.component';

@UntilDestroy()
@Component({
    selector: 'organization-detail',
    templateUrl: './organization-detail.component.html',
    styleUrls: ['./organization-detail.component.scss'],
})
export class OrganizationDetailComponent implements OnInit {
    public menuItems$: Observable<SettingsMenuItem[]> | undefined;
    public organization$: Observable<Organization | null> = inject(Store).select(
        OrganizationState.currentOrganization,
    );

    constructor() {}

    ngOnInit() {
        this.menuItems$ = of(null).pipe(
            map(() => {
                const items = [
                    {
                        display: 'Général',
                        routerLink: ['./general'],
                        routerLinkActiveOptions: { exact: false },
                    },
                    {
                        display: 'Utilisateurs',
                        routerLink: ['./users'],
                        routerLinkActiveOptions: { exact: false },
                    },
                    {
                        display: 'Gérer les études disponibles',
                        routerLink: ['./manageStudies'],
                        routerLinkActiveOptions: { exact: false },
                    },
                ];

                return items;
            }),
            untilDestroyed(this),
        );
    }
}
