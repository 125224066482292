@if (!emailSentTo) {
    <ng-container *ngTemplateOutlet="emailSentToTmp"></ng-container>
} @else {
    <ng-container *ngTemplateOutlet="emailSentTmp"></ng-container>
}

<ng-template #emailSentToTmp>
    <div class="header">
        <div class="icon-box info"><i class="icon icon-key-01"></i></div>
        <div class="header-content">
            <h1>Mot de passe oublié ?</h1>
            <div class="subtitle">
                Renseignez votre email et nous vous enverrons des instructions pour le réinitialiser
            </div>
        </div>
    </div>

    <form (ngSubmit)="onSubmit()" [formGroup]="forgotPasswordFormGroup" class="content">
        <ed-textfield
            innerId="email"
            type="email"
            placeholder="jeanne@martin.fr"
            formControlName="email"></ed-textfield>

        <ed-button
            [type]="ButtonTypes.Submit"
            [disabled]="
                forgotPasswordFormGroup.invalid || forgotPasswordFormGroup.value.email === ''
            "
            >Réinitialiser mon mot de passe</ed-button
        >
    </form>
</ng-template>

<ng-template #emailSentTmp>
    <div class="header">
        <div class="icon-box info"><i class="icon icon-mail-01"></i></div>
        <div class="header-content">
            <h1>Consultez vos emails</h1>
            <div class="subtitle">
                Les instructions pour réinitialiser votre mot de passe ont été transmises à

                <div class="email-sent-to">{{ emailSentTo }}</div>
            </div>
        </div>
    </div>

    <div class="content email-info">
        <div>Email non reçu ? <span class="link" (click)="send()">Cliquez pour renvoyer</span></div>
    </div>
</ng-template>

<a routerLink="/" class="btn-connect"><i class="icon icon-arrow-left"></i>Se connecter</a>
