<div class="page-content">
    <div class="page-header">
        <h1>{{ title }}</h1>
        <div class="page-description">{{ subTitle }}</div>
    </div>

    <div class="page-body">
        <div class="container-fluid cards-container">
            <chart-card
                id="municipalitiesChart"
                [title]="chartTitle"
                [descriptionContent]="chartDescriptionContent"
                [error]="!!chartData()[0].error"
                [isLoading]="!chartData()[0].organizationId"
                [series]="chartSeries"
                [dataLabels]="dataLabels"
                [yaxis]="yaxis"
                [precision]="0"
                class="h-100"></chart-card>
        </div>
    </div>
</div>
