import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Document } from '../../core/models/document.model';
import { DocumentService } from 'src/app/core/services/document.service';

/** This file is divided in 3 parts:
 * - The definition of the Store : defining data structure
 * - Constants that allow NgXs to trigger Actions
 * - Selectors & Actions : Functions allowed to interact with the store
 *
 */

// Store definition
export interface DocumentsStateModel {
    documents: Document[];
}

//Constants

export class GetAllDocuments {
    static readonly type = 'ADMIN/FILES/ALL';
    constructor(public payload: { folderName: string; organizationId: string }) {}
}

//Actions

@State<DocumentsStateModel>({
    name: 'Documents',
    defaults: {
        documents: [],
    },
})
@Injectable()
export class DocumentsState {
    @Selector()
    public static documents(state: DocumentsStateModel): Document[] | null {
        return state.documents;
    }

    constructor(private documentService: DocumentService) {}

    @Action(GetAllDocuments)
    public async getDocuments(
        ctx: StateContext<DocumentsStateModel>,
        action: GetAllDocuments,
    ): Promise<void> {
        try {
            const documents = await this.documentService.getAll(
                action.payload.folderName,
                action.payload.organizationId,
            );
            ctx.patchState({ documents });
        } catch (err: any) {
            console.log(err);
            ctx.patchState({ documents: [] });
        }
    }
}
