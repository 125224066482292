import { Component, OnInit, inject, Signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, distinctUntilChanged, switchMap, of, filter, map } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthState, Logout } from '../../states/auth.state';

import { SidenavService } from '../../../shared/services/sidenav.service';
import { NavigationEnd, Router } from '@angular/router';
import {
    OrganizationState,
    SetCurrentAvatar,
} from '../../../features/admin/organization/organization.state';
import { Organization } from '../../models/organization.model';
import { OrganizationService } from '../../../features/admin/organization/organization.service';
import { User } from '../../models/user.model';

export interface YearOption {
    label: string;
    value: number;
}

@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public isCollapsed: boolean = true;
    public headerFormGroup: FormGroup;

    public selectOptions = [
        {
            label: 'Contrôle de concession',
            value: 'cc',
        },
    ];

    public organizations$: Observable<Organization[] | null> = inject(Store).select(
        OrganizationState.organizations,
    );
    public orgsList$!: Observable<{ label: string; value: string }[]>;

    public currentOrganization$: Observable<Organization | null> = inject(Store).select(
        OrganizationState.currentOrganization,
    );
    private currentYear$: Observable<number | null> = inject(Store).select(
        OrganizationState.currentYear,
    );

    public initials$: Observable<string> | undefined;
    public avatarUrl: string | null = null;

    public isSmallScreen!: boolean;
    public displayMyProfile: boolean = true;
    private _currentUrl: string = '';
    public currentPage: string = '';

    public organization: Organization | undefined | null;
    public yearOptions: YearOption[] = [];
    public displaySelectYear: boolean = false;

    public loggedUser$ = inject(Store).select(AuthState.loggedUser).pipe(filter(Boolean));
    public _loggedUser$: Observable<User | null> = inject(Store).select(AuthState.loggedUser);

    private currentOrganizationSignal: Signal<Organization | null> = inject(Store).selectSignal(
        OrganizationState.currentOrganization,
    );
    private currentYearSignal: Signal<number> = inject(Store).selectSignal(
        OrganizationState.currentYear,
    );
    private organizationsSignal: Signal<Organization[] | null> = inject(Store).selectSignal(
        OrganizationState.organizations,
    );
    private avatarUrlSignal: Signal<string | null> = inject(Store).selectSignal(
        OrganizationState.avatar,
    );

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly store: Store,
        private readonly sidenavService: SidenavService,
        private readonly router: Router,
        private readonly organizationService: OrganizationService,
    ) {
        inject(BreakpointObserver)
            .observe(sidenavService.breakPointsThreshold)
            .pipe(untilDestroyed(this))
            .subscribe((result) => {
                const matches = result.matches;
                this.isSmallScreen = matches;
            });

        const currentOrganization = this.currentOrganizationSignal();
        let currentYear = null;
        if (currentOrganization) {
            this.getYearOptions(currentOrganization!.concessionControls);
            currentYear = this.currentYearSignal();
        }

        this.headerFormGroup = this.formBuilder.group({
            organization: [currentOrganization?.id, Validators.required],
            cracOrCc: ['cc', Validators.required],
            year: [currentYear, Validators.required],
        });
    }

    ngOnInit() {
        this.currentYear$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((currentYear) => {
            this.headerFormGroup.get('year')?.setValue(currentYear, { emitEvent: false });
        });

        this.currentOrganization$.pipe(untilDestroyed(this)).subscribe((organization) => {
            this.organization = organization;
            if (this.organization) {
                if (this.currentPage === 'admin') {
                    this.headerFormGroup.get('organization')?.setValue(this.organization.id);
                }
            }
        });

        this.orgsList$ = this.organizations$.pipe(
            filter(Boolean),
            map((organizations) =>
                organizations.map((org) => ({
                    label: org.name,
                    value: org.id,
                })),
            ),
        );

        this.headerFormGroup
            .get('organization')
            ?.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe((organizationId) => {
                const currentPage = this.checkIfAdminPage(this.router.url);
                if (currentPage === 'admin') {
                    this.router.navigate([`/admin/${organizationId}/general`]);
                }

                if (currentPage === 'other') {
                    const organizations = this.organizationsSignal();
                    const newOrganization = organizations?.find(
                        (organization) => organization.id === organizationId,
                    );
                    if (!newOrganization) {
                        return;
                    }
                    this.organization = newOrganization;

                    this.getYearOptions(this.organization!.concessionControls);
                    const newYear = this.setNewYear();
                    this.headerFormGroup.get('year')?.setValue(newYear.value);
                }
            });

        this.headerFormGroup
            .get('year')
            ?.valueChanges.pipe(untilDestroyed(this))
            .subscribe((year) => {
                if (this.currentPage === 'other') {
                    const currentOrganization = this.currentOrganizationSignal();
                    const organization = this.organization
                        ? this.organization
                        : currentOrganization;

                    const newUrl = this._currentUrl
                        .replace(/\/main\/[a-f\d\-]{36}/, `/main/${organization!.id}`)
                        .replace(/\/(\d{4})(?=\/|$)/, `/${year}`);
                    this.router.navigate([newUrl]);
                }
            });

        this.initials$ = this.loggedUser$.pipe(
            distinctUntilChanged(),
            map((user) => (user.firstname?.charAt(0) || '') + (user.lastname?.charAt(0) || '')),
            untilDestroyed(this),
        );

        this.currentOrganization$
            .pipe(
                distinctUntilChanged(),
                switchMap((organization) => {
                    if (!organization) {
                        return of(null);
                    }
                    const avatarUrl = this.avatarUrlSignal();
                    return this._getAvatar(avatarUrl, organization!.id!);
                }),
                untilDestroyed(this),
            )
            .subscribe((avatarUrl) => {
                this.updateAvatar(avatarUrl!);
            });

        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this._currentUrl = this.router.url;
                this.currentPage = this.checkIfAdminPage(event.urlAfterRedirects);
                this.checkDisplayMyProfile(event.urlAfterRedirects);
                this.checkDisplaySelectYear(event.urlAfterRedirects);
            }
        });

        this._currentUrl = this.router.url;
        this.currentPage = this.checkIfAdminPage(this._currentUrl);
        this.checkDisplayMyProfile(this._currentUrl);
        this.checkDisplaySelectYear(this._currentUrl);
    }

    private setNewYear(): YearOption {
        const currentYear = this.currentYearSignal();
        let newYear = this.yearOptions.find((year) => year.value === currentYear!);
        if (!newYear) {
            newYear = this.yearOptions
                .slice(1)
                .reduce(
                    (prev, current) => (prev.value > current.value ? prev : current),
                    this.yearOptions[0],
                );
        }
        return newYear;
    }

    public toggleSidenav() {
        this.sidenavService.toggle();
    }

    public logOut(): void {
        this.store.dispatch(new Logout());
    }

    private _getAvatar(
        avatarUrl: string | null,
        organizationId: string,
    ): Observable<string | null> {
        if (avatarUrl) {
            return of(avatarUrl);
        }
        return this.organizationService
            .getAvatar(organizationId)
            .pipe(map((blob) => (!!blob ? URL.createObjectURL(blob) : null)));
    }

    private updateAvatar(avatarUrl: string): void {
        this.avatarUrl = avatarUrl;
        this.store.dispatch(new SetCurrentAvatar({ avatarUrl: avatarUrl }));
    }

    private checkIfAdminPage(url: string): string {
        if (url.includes('admin')) {
            return 'admin';
        } else {
            return 'other';
        }
    }

    private getYearOptions(concessionControls: any[] | undefined | null): void {
        if (concessionControls) {
            this.yearOptions = concessionControls!
                .filter((control) => control.id !== 'new-study')
                .map((control) => ({
                    label: control.year?.toString(),
                    value: control.year?.toString(),
                }));
            this.yearOptions.sort((a: YearOption, b: YearOption) => b.value - a.value);
        }
    }

    private checkDisplayMyProfile(url: string): void {
        this.displayMyProfile = !url.includes('/admin');
    }

    private checkDisplaySelectYear(url: string): void {
        this.displaySelectYear = url !== '/main';
    }
}
