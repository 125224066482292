import { Routes } from '@angular/router';
import { importProvidersFrom } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { CC_CONSTANTS } from './assets/constants';
import { ConcessioncontrolState } from './states/concessioncontrol.state';

import { GenericComponent } from './components/generic/generic.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { GeneralInfoComponent } from './components/general-info/general-info.component';
import { SourceStationsComponent } from './components/sourcestations/sourcestations.component';

import { MvNetworksComponent } from './components/mv-networks/mv-networks.component';
import { MvNetworksUndergroundingComponent } from './components/mv-networks-undergrounding/mv-networks-undergrounding.component';
import { MvNetworksAgesComponent } from './components/mv-networks-ages/mv-networks-ages.component';

export const concessionControlRoutes: Routes = [
    {
        path: '',
        providers: [importProvidersFrom(NgxsModule.forFeature([ConcessioncontrolState]))],
        children: [
            {
                path: '',
                component: WelcomeComponent,
                data: { ...CC_CONSTANTS.welcome },
            },
            {
                path: CC_CONSTANTS.welcome.path,
                component: WelcomeComponent,
                data: { ...CC_CONSTANTS.welcome },
            },
            {
                path: CC_CONSTANTS.generalInfo.path,
                component: GeneralInfoComponent,
                data: { ...CC_CONSTANTS.generalInfo },
            },
            {
                path: CC_CONSTANTS.sourceStations.path,
                component: SourceStationsComponent,
                data: { ...CC_CONSTANTS.sourceStations },
            },
            {
                path: CC_CONSTANTS.mvNetworks.path,
                component: MvNetworksComponent,
                data: { ...CC_CONSTANTS.mvNetworks, activeTab: 'undergrounding' },
                children: [
                    {
                        path: '',
                        redirectTo: CC_CONSTANTS.mvNetworks.tabs.undergrounding.path,
                        pathMatch: 'full', // Redirect to the default active tab
                    },
                    {
                        path: CC_CONSTANTS.mvNetworks.tabs.undergrounding.path,
                        component: MvNetworksUndergroundingComponent,
                        data: { ...CC_CONSTANTS.mvNetworks },
                    },
                    {
                        path: CC_CONSTANTS.mvNetworks.tabs.aging.path,
                        component: MvNetworksAgesComponent,
                        data: { ...CC_CONSTANTS.mvNetworks },
                    },
                ],
            },
            {
                path: CC_CONSTANTS.lvNetworks.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.lvNetworks, activeTab: 'undergrounding' },
                children: [
                    {
                        path: CC_CONSTANTS.lvNetworks.tabs.undergrounding.path,
                        component: GenericComponent,
                        data: { ...CC_CONSTANTS.lvNetworks, activeTab: 'undergrounding' },
                    },
                    {
                        path: CC_CONSTANTS.lvNetworks.tabs.aging.path,
                        component: GenericComponent,
                        data: { ...CC_CONSTANTS.lvNetworks, activeTab: 'aging' },
                    },
                ],
            },
            {
                path: CC_CONSTANTS.transformerStations.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.transformerStations, activeTab: 'substationsTransformers' },
                children: [
                    {
                        path: CC_CONSTANTS.transformerStations.tabs.substationsTransformers.path,
                        component: GenericComponent,
                        data: {
                            ...CC_CONSTANTS.transformerStations,
                            activeTab: 'substationsTransformers',
                        },
                    },
                    {
                        path: CC_CONSTANTS.transformerStations.tabs.meters.path,
                        component: GenericComponent,
                        data: { ...CC_CONSTANTS.transformerStations, activeTab: 'meters' },
                    },
                ],
            },
            {
                path: CC_CONSTANTS.supplyContinuity.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.supplyContinuity, activeTab: 'BCriterion' },
                children: [
                    {
                        path: CC_CONSTANTS.supplyContinuity.tabs.BCriterion.path,
                        data: {
                            ...CC_CONSTANTS.supplyContinuity,
                            activeTab: 'BCriterion',
                        },
                        component: GenericComponent,
                    },
                    {
                        path: CC_CONSTANTS.supplyContinuity.tabs.outages.path,
                        data: {
                            ...CC_CONSTANTS.supplyContinuity,
                            activeTab: 'outages',
                        },
                        component: GenericComponent,
                    },
                    {
                        path: CC_CONSTANTS.supplyContinuity.tabs.incidentology.path,
                        data: {
                            ...CC_CONSTANTS.supplyContinuity,
                            activeTab: 'incidentology',
                        },
                        component: GenericComponent,
                    },
                ],
            },
            {
                path: CC_CONSTANTS.voltageQuality.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.voltageQuality },
            },
            {
                path: CC_CONSTANTS.investments.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.investments },
            },
            {
                path: CC_CONSTANTS.maintenanceCost.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.maintenanceCost },
            },
            {
                path: CC_CONSTANTS.kpis.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.kpis },
            },
            {
                path: CC_CONSTANTS.grantorRights.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.grantorRights },
            },
            {
                path: CC_CONSTANTS.operatingAccount.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.operatingAccount },
            },
            {
                path: CC_CONSTANTS.energyUsers.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.energyUsers },
            },
            {
                path: CC_CONSTANTS.energyProducers.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.energyProducers },
            },
            {
                path: CC_CONSTANTS.connections.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.connections },
            },
            {
                path: CC_CONSTANTS.enedisQuality.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.enedisQuality },
            },
            {
                path: CC_CONSTANTS.enedisClaims.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.enedisClaims },
            },
            {
                path: CC_CONSTANTS.trvUsers.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.trvUsers },
            },
            {
                path: CC_CONSTANTS.edfQuality.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.edfQuality },
            },
            {
                path: CC_CONSTANTS.edfClaims.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.edfClaims },
            },
            {
                path: CC_CONSTANTS.energyPoverty.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.energyPoverty },
            },
            {
                path: CC_CONSTANTS.exportReport.path,
                component: GenericComponent,
                data: { ...CC_CONSTANTS.exportReport },
            },
        ],
    },
];
