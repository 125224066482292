import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AlertService, ButtonColors, LayerService } from '@ed---interne/ng-uui-components';
import { User, CreateUserModel } from '../../../../core/models/user.model';
import { CreateUser } from '../users.state';
import { emailValidator } from '../../../../shared/validators/email.validator';
import { passwordValidator } from '../../../../shared/validators/password.validator';

@UntilDestroy()
@Component({
    selector: 'user-create',
    templateUrl: './user-create.component.html',
    styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent {
    public buttonColors = ButtonColors;

    public user!: User;
    public password: string | null = null;
    public passwordConfirm: string | null = null;
    public header: any;
    public organizationId: any;
    public userFormGroup: FormGroup = this.formBuilder.group({
        email: [{ value: '', disabled: false }, [emailValidator()]],
        password: ['', [passwordValidator()]],
        firstname: [''],
        lastname: [''],
        passwordConfirm: ['', [passwordValidator()]],
        isAdmin: false,
    });

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService,
        private readonly store: Store,
        private readonly alertService: AlertService,
    ) {
        this.userFormGroup = this.formBuilder.group({
            email: [{ value: '', disabled: false }, [emailValidator()]],
            password: ['', [passwordValidator()]],
            firstname: [''],
            lastname: [''],
            passwordConfirm: ['', [passwordValidator()]],
            isAdmin: [false],
        });
    }

    public valid(): void {
        const userToCreate: Partial<CreateUserModel> = {
            mail: this.userFormGroup.value.email as string,
            firstname: this.userFormGroup.value.firstname as string,
            lastname: this.userFormGroup.value.lastname as string,
            isAdmin: !!this.userFormGroup.value.isAdmin,
            organizationId: this.organizationId,
        };
        //create
        this.store.dispatch(new CreateUser({ user: userToCreate })).subscribe({
            next: () => {
                this.alertService.valid('Utilisateurs', 'Utilisateur créé !');
                this.close();
            },
            error: (err) => {
                console.info(err.error.message);
                this.alertService.error('Création', err.error.message);
            },
        });
    }

    public set params(params: { header: any; user: User; organizationId: any }) {
        this.header = params.header;
        this.organizationId = params.organizationId;

        this.userFormGroup.patchValue({
            email: '',
            firstname: '',
            lastname: '',
            isAdmin: '',
            password: '',
            passwordConfirm: '',
        });
    }

    public close(): void {
        this.layerService.close();
    }

    public canCreateEdit(): boolean {
        return this.userFormGroup.invalid || !this.userFormGroup.dirty;
    }

    public showPopupOnLayerClose(): boolean {
        return this.userFormGroup.dirty;
    }
}
