@if (!passwordChanged) {
    <ng-container *ngTemplateOutlet="changePasswordTmp"></ng-container>
} @else {
    <ng-container *ngTemplateOutlet="passwordChangedTmp"></ng-container>
}

<ng-template #changePasswordTmp>
    <div class="header">
        <div class="icon-box info"><i class="icon icon-key-01"></i></div>
        <div class="header-content">
            <h1>Nouveau mot de passe</h1>
            <div class="subtitle">
                Choisissez un nouveau mot de passe unique et gardez le précieusement.
            </div>
        </div>
    </div>

    <form (ngSubmit)="onSubmit()" [formGroup]="changePasswordFormGroup" class="content">
        <div class="password-container">
            <ed-textfield
                innerId="password"
                [type]="isPasswordVisible ? 'text' : 'password'"
                [formControlName]="'password'"
                (onIconClick)="togglePasswordVisibility()"
                [icon]="isPasswordVisible ? 'icon-eye' : 'icon-eye-off'"
                [iconPosition]="'right'"
                [iconClickable]="true"
                placeholder="Choisissez votre mot de passe"></ed-textfield>
            <div
                class="hint"
                *ngIf="
                    !changePasswordFormGroup.get('password')?.dirty ||
                    changePasswordFormGroup.get('password')?.value == '' ||
                    changePasswordFormGroup.get('password')?.valid
                ">
                Au moins 8 caractères, dont 1 chiffre, 1 majuscule et un caractère spécial ? ! / , ;
            </div>
        </div>

        <ed-button
            [type]="ButtonTypes.Submit"
            [disabled]="
                changePasswordFormGroup.invalid || changePasswordFormGroup.value.password === ''
            "
            >Réinitialiser mon mot de passe</ed-button
        >
    </form>

    <a routerLink="/" class="btn-connect"><i class="icon icon-arrow-left"></i>Se connecter</a>
</ng-template>

<ng-template #passwordChangedTmp>
    <div class="header">
        <div class="icon-box valid"><i class="icon icon-check-circle"></i></div>
        <div class="header-content">
            <h1>Mot de passe enregistré</h1>
            <div class="subtitle">
                Votre nouveau mot de passe a été enregistré.<br />Vous pouvez dès à présent vous
                connecter.
            </div>
        </div>
    </div>

    <div class="content">
        <ed-button routerLink="/">Se connecter</ed-button>
    </div>
</ng-template>
