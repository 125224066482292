<div class="page-content">
    <div class="page-header">
        <h1>{{ title }}</h1>
        <div class="page-description">{{ subTitle }}</div>
    </div>

    <div class="page-body">
        <div class="container-fluid kpis">
            <div class="row g-3">
                @let kpi = kpis()[0];
                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.nbSourceStationsSupplying.title"
                        [body]="{
                            value: kpi.nbSourceStationsSupplying,
                            precision: texts.nbSourceStationsSupplying.precision,
                        }"
                        [descriptionTitle]="texts.nbSourceStationsSupplying.descriptionTitle"
                        [descriptionContent]="texts.nbSourceStationsSupplying.descriptionContent"
                        [badge]="{
                            value: kpi.nbSourceStationsSupplyingEvolution,
                            precision: texts.nbSourceStationsSupplying.badgePrecision,
                            style: 'info',
                        }"
                        [isLoading]="!kpi.organizationId"
                        [error]="checkKpiErrors(kpi, 'nbSourceStationsSupplying')"
                        cardClass="h-100"></kpi-card>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.nbSourceStationsIn.title"
                        [body]="{
                            value: kpi.nbSourceStationsIn,
                            precision: texts.nbSourceStationsIn.precision,
                        }"
                        [badge]="{
                            value: kpi.nbSourceStationsInEvolution,
                            precision: texts.nbSourceStationsIn.badgePrecision,
                            style: 'info',
                        }"
                        [isLoading]="!kpi.organizationId"
                        [error]="checkKpiErrors(kpi, 'nbSourceStationsIn')"
                        cardClass="h-100"></kpi-card>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.sourceStationsPowerSupplying.title"
                        [body]="{
                            value: kpi.sourceStationsPowerSupplying,
                            suffix: texts.sourceStationsPowerSupplying.suffix,
                            precision: texts.sourceStationsPowerSupplying.precision,
                        }"
                        [badge]="{
                            value: kpi.sourceStationsPowerSupplyingEvolution,
                            precision: texts.sourceStationsPowerSupplying.badgePrecision,
                            suffix: texts.sourceStationsPowerSupplying.badgeSuffix,
                            style: 'info',
                        }"
                        [isLoading]="!kpi.organizationId"
                        [error]="checkKpiErrors(kpi, 'sourceStationsPowerSupplying')"
                        cardClass="h-100"></kpi-card>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.nbTransformersSupplying.title"
                        [body]="{
                            value: kpi.nbTransformersSupplying,
                            precision: texts.nbTransformersSupplying.precision,
                        }"
                        [badge]="{
                            value: kpi.nbTransformersSupplyingEvolution,
                            precision: texts.nbTransformersSupplying.badgePrecision,
                            style: 'info',
                        }"
                        [isLoading]="!kpi.organizationId"
                        [error]="checkKpiErrors(kpi, 'nbTransformersSupplying')"
                        cardClass="h-100"></kpi-card>
                </div>
            </div>
        </div>

        <chart-card
            id="sourceStationsChart"
            [title]="chartTitle"
            [error]="!!chartData()[0].error"
            [isLoading]="!chartData()[0].organizationId"
            [series]="chartSeries"
            [dataLabels]="dataLabels"
            [stacked]="true"
            [yaxis]="yaxis"
            [precision]="0"
            class="h-100"></chart-card>

        <geo-map-card
            [title]="texts.localizationMap.title"
            [descriptionContent]="texts.localizationMap.descriptionContent"
            [descriptionTitle]="texts.localizationMap.descriptionTitle"
            [geometries]="geometriesWithTooltips()"
            [isLoading]="!geometries()[0].organizationId"
            [error]="!!geometries()[0].error"
            [getStyle]="getLocalizationMapStyle"
            [legendConfig]="legendConfig"
            [mapId]="'sourcesStationsMap'"
            [getTooltip]="getTooltip"></geo-map-card>
    </div>
</div>
