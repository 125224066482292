import { DatePipe } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { PopupService } from '@ed---interne/ng-uui-components';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexNonAxisChartSeries,
    ApexPlotOptions,
    ApexStroke,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
    ChartType,
} from 'ng-apexcharts';
import {
    colorAec1BlueLight,
    colorAec2BlueDark,
    colorAec3Green,
    colorAec4Orange,
    colorAec5Brown,
    colorAec6Gray,
    colorAec7RedLight,
    colorAec8RedDark,
    colorAec9Cream,
} from '../../all.constants';
import { convertNumber } from '../../all.helpers';

const MAX_DESCRIPTION_LENGTH_TOOLTIP = 500;

@Component({
    selector: 'chart-card',
    templateUrl: './chart-card.component.html',
    styleUrls: ['./chart-card.component.scss'],
})
export class ChartCardComponent {
    @Input() title: string = '';
    @Input() descriptionTitle: string | undefined;
    @Input() descriptionContent: string | undefined;
    @Input() error: boolean = false;
    @Input() isLoading: boolean = true;

    @Input() set xaxisType(value: 'datetime' | 'category' | 'numeric') {
        if (value) {
            this.xaxis = { type: value };
        }
    }

    @Input() public set yaxis(value: ApexYAxis[] | undefined) {
        this._yaxis = value?.map((y) => {
            return {
                ...y,
                labels: {
                    formatter: (value: any) => convertNumber(value, this.precision || 0, false),
                    ...y.labels,
                },
            };
        });
    }
    public _yaxis: ApexYAxis[] | undefined;

    @Input() public series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;

    //Number of decimal to show
    @Input() public precision: number | null = null;

    @Input() public set dataLabels(dataLabels: ApexDataLabels) {
        if (!dataLabels.formatter) {
            dataLabels.formatter = (v: any) => convertNumber(v, this.precision || 0, false);
        }
        this._dataLabels = dataLabels;
    }
    public _dataLabels: ApexDataLabels = { enabled: true };

    @Input() labels: string[] | undefined;
    @Input() set type(value: ChartType) {
        this.chart.type = value || 'line';
    }

    @Input() set stacked(value: boolean) {
        this.chart.stacked = value || false;
    }

    @Input() plotOptions: ApexPlotOptions = {
        bar: {
            columnWidth: '70%',
            horizontal: false,
            dataLabels: {
                position: 'bottom',
            },
        },
        boxPlot: {
            colors: {
                upper: colorAec1BlueLight,
                lower: colorAec2BlueDark,
            },
        },
    };

    @Input() fill: ApexFill = {
        opacity: 0.4,
    };

    @Input() set id(value: string) {
        this.chart.id = value;
    }
    @Input() cardClass: string = '';

    // Bind the cardClass property to the class attribute of the host element
    @HostBinding('class') get hostClasses(): string {
        return this.cardClass;
    }

    public xaxis: ApexXAxis = {
        //Can be chage with input xaxisType
        type: 'category', //Or "datetime" or "numeric"
    };

    public chart: ApexChart = {
        id: 'myChart',
        type: 'line',
        height: 250,
        stacked: false,
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: true,
            tools: {
                download: '<i class="icon icon-download" style="font-size:50%"></i>',
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
            },
            export: {
                csv: {
                    filename: undefined,
                    columnDelimiter: ',',
                },
                png: {
                    filename: undefined,
                },
            },
        },
    };

    public stroke: ApexStroke = {
        width: 2,
        curve: 'smooth',
    };

    public tooltip: ApexTooltip = {};

    public defaultColors: string[] = [
        colorAec1BlueLight,
        colorAec2BlueDark,
        colorAec3Green,
        colorAec4Orange,
        colorAec5Brown,
        colorAec6Gray,
        colorAec7RedLight,
        colorAec8RedDark,
        colorAec9Cream,
    ];

    constructor(
        private readonly popupService: PopupService,
        private datePipe: DatePipe,
    ) {}

    ngOnInit() {
        const date = new Date();
        const fileName = `${this.title.replace(' ', '_')}_${this.datePipe.transform(date, "dd.MM.yyyy_HH'h'mm")}`;
        this.chart!.toolbar!.export!.csv!.filename = fileName;
        this.chart!.toolbar!.export!.png!.filename = fileName;
    }

    public showDescriptionTooltip(): boolean {
        let length = 0;
        if (!!this.descriptionTitle) {
            length += this.descriptionTitle.length;
        }
        if (!!this.descriptionContent) {
            length += this.descriptionContent.length;
        }
        return length > 0 && length <= MAX_DESCRIPTION_LENGTH_TOOLTIP;
    }

    public openDescriptionPopup() {
        this.popupService.openPopup({
            title: this.descriptionTitle
                ? `<div style="text-align: center; margin-top:20px">${this.descriptionTitle}</div>`
                : ' ',
            htmlContent: this.descriptionContent
                ? `<div style="text-align: justify;">${this.descriptionContent}</div>`
                : ' ',
            showCancelButton: false,
            confirmButtonText: 'Fermer',
        });
    }
}
