import { Component, inject, Signal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { CracState, PrepareDataFromKeys } from '../../states/crac.state';
import { ActivatedRoute } from '@angular/router';
import { CRAC_CONSTANTS } from '../../assets/constants';
import { OrganizationState } from '../../../../admin/organization/organization.state';
import { combineLatest, distinctUntilChanged } from 'rxjs';

const GENERAL_PAGE_KPIS = 'fakeKey';
const KEYS = [GENERAL_PAGE_KPIS];

@UntilDestroy()
@Component({
    selector: 'app-crac-general',
    templateUrl: './generalcrac.component.html',
    styleUrls: ['./generalcrac.component.scss'],
})
export class GeneralCracComponent {
    public data: Signal<any[]> = this.store.selectSignal(
        CracState.getDataByKey(GENERAL_PAGE_KPIS),
    ) as Signal<any[]>;

    public title = '';
    public subTitle = '';
    public texts = CRAC_CONSTANTS.general.texts;

    private _currentOrganization$ = inject(Store).select(OrganizationState.currentOrganization);
    private _currentYear$ = inject(Store).select(OrganizationState.currentYear);

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
    ) {
        this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
            this.title = data['title'];
            this.subTitle = data['subTitle'];
        });
    }
    ngOnInit() {
        // Every time currentOrganization or currentYear changes, we tell the store he has to ask for the data
        combineLatest([this._currentOrganization$, this._currentYear$])
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(() => {
                this.store.dispatch(new PrepareDataFromKeys({ keys: KEYS }));
            });
    }
}
