<div class="header">
    <div class="icon-box info"><i class="icon icon-log-in-04"></i></div>
    <div class="header-content">
        <h1>Se connecter</h1>
    </div>
</div>

<form (ngSubmit)="login()" [formGroup]="loginFormGroup">
    <div class="content">
        <div class="fields">
            <ed-textfield
                innerId="email"
                [type]="'email'"
                [placeholder]="'Votre email'"
                [displayError]="false"
                [formControlName]="'email'"></ed-textfield>

            <ed-textfield
                innerId="password"
                [type]="'password'"
                [placeholder]="'••••••••'"
                [formControlName]="'password'"></ed-textfield>

            <div *ngIf="loginFormGroup.get('email')?.hasError('email')" class="alert-msg">
                Email invalide
            </div>
        </div>

        <ed-button
            [type]="ButtonTypes.Submit"
            [loading]="loading"
            [disabled]="!loginFormGroup.valid"
            >Se connecter</ed-button
        >
    </div>
</form>

<a routerLink="/forgot-password" href="/forgot-password" class="btn-forget-pwd">
    Mot de passe oublié
</a>
