import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngxs/store';

import { RestService } from './rest.service';
import { User } from '../models/user.model';
import { Logout, Register } from '../../core/states/auth.state';
import { OrganizationService } from '../../features/admin/organization/organization.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _isAuth = false;
    private _isAdmin = false;

    constructor(
        @Inject(RestService) private restService: RestService,
        private store: Store,
        private router: Router,
        private organizationService: OrganizationService,
    ) {}

    public get isAuth(): boolean {
        return this._isAuth;
    }
    public get isAdmin(): boolean {
        return this._isAdmin;
    }

    public async getLoggedUserInfos(): Promise<User> {
        const path = '/user';
        const user = await this.restService.get(path, {});
        return user;
    }

    public async registerToken(token: String): Promise<User> {
        if (token) {
            localStorage.setItem('Authorization', 'Bearer ' + token);
        } else {
            localStorage.removeItem('Authorization');
            this.router.navigate(['/']);
        }

        let user: User = {} as User;

        try {
            user = await this.getLoggedUserInfos();
        } catch (err) {
            await this.logout();
        }

        if (user) {
            this._isAdmin = user.isAdmin;
            this._isAuth = true;
            this.store.dispatch(new Register({ loggedUser: user }));
        } else {
            await this.logout();
        }

        return user;
    }

    public async login(email: string, password: string): Promise<User | undefined> {
        const path = '/auth/login';
        const data = { mail: email, password: password };
        const result = await this.restService.post(path, {}, data);

        if (!result.access_token) {
            return undefined;
        }

        const user = await this.registerToken(result.access_token);
        if (user.isAdmin) {
            this.router.navigate(['/admin/organization']);
        } else {
            this.router.navigate(['/main']);
        }
        return user;
    }

    public async logout(): Promise<boolean> {
        const path = `/auth/logout`;
        try {
            await this.restService.get(path);
        } catch (error) {
            console.log(error);
        }

        this._isAuth = false;
        this._isAdmin = false;
        localStorage.removeItem('Authorization');
        this.store.dispatch(new Logout());
        this.router.navigate(['/']);
        return true;
    }

    public async passwordForgot(email: string): Promise<User> {
        const path = '/auth/reset-password';
        const data = {
            mail: email,
        };
        const user = await this.restService.post(path, {}, data);
        return user;
    }

    public async passwordChange(token: string, password: string): Promise<User> {
        const path = '/auth/change-password';
        const data = {
            token: token,
            password: password,
        };
        const user = await this.restService.post(path, {}, data);
        return user;
    }

    public async signup(token: string, user: Partial<User>): Promise<User> {
        const path = `/auth/signup`;
        const data = {
            token: token,
            ...user,
        };
        return await this.restService.post(path, {}, data);
    }

    public async isAnyUserMatchToken(token: string): Promise<boolean> {
        const path = `/auth/isAnyUserMatchToken?token=${token}`;
        return await this.restService.get(path, {});
    }
}
