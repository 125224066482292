import { Inject, Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';

import { RestService } from '../../../core/services/rest.service';
import { environment } from '../../../../environments/environment';
import { Organization } from 'src/app/core/models/organization.model';
import { ConcessionControl } from 'src/app/core/models/concession-control.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SetCurrentAvatar } from './organization.state';
import { AlertService } from '@ed---interne/ng-uui-components';
import { Store } from '@ngxs/store';

@Injectable({
    providedIn: 'root',
})
@UntilDestroy()
export class OrganizationService {
    private _organizations = false;

    private updateListOrga = new Subject<void>();
    updateListOrga$ = this.updateListOrga.asObservable();

    constructor(
        @Inject(RestService) private readonly restService: RestService,
        private readonly alertService: AlertService,
        private readonly store: Store,
    ) {}

    triggerUpdateListOrga() {
        this.updateListOrga.next();
    }

    public get organizations(): boolean {
        return this._organizations;
    }

    public uploadAvatar(organizationId: string, avatarFile: File): Promise<any> {
        const path = `/organization/${organizationId}/avatar`;
        const formData: FormData = new FormData();
        formData.append('avatarFile', avatarFile);
        return this.restService.post(path, {}, formData);
    }

    public getAvatar(organizationId: string): Observable<Blob> {
        const path = `/organization/${organizationId}/avatar`;
        return this.restService.download(path);
    }

    public async getAllOrganizations(): Promise<Organization[]> {
        const path = '/organization/all';
        const organizations = await this.restService.get(path, {});
        return organizations;
    }

    public async getOrganizationById(organizationId: string): Promise<Organization> {
        const path = `/organization/${organizationId}/infos`;
        const organization = await this.restService.get(path, {});
        return organization;
    }

    public async deleteOrganization(organizationId: string): Promise<void> {
        const path = `/organization/${organizationId}`;
        await this.restService.delete(path, {});
    }

    public async updateOrganization(
        organization: Partial<Organization>,
        avatarFile?: File,
    ): Promise<Organization> {
        const path = `/organization/${organization.id}`;
        const updatedOrganization = await this.restService.put(path, {}, organization);

        if (avatarFile) {
            await this.uploadAvatar(organization.id!, avatarFile);
            this.getAvatar(organization!.id!)
                .pipe(
                    map((blob) => (!!blob ? URL.createObjectURL(blob) : null)),
                    untilDestroyed(this),
                )
                .subscribe((avatarUrl) => {
                    this.store.dispatch(new SetCurrentAvatar({ avatarUrl: avatarUrl! }));
                });
        }

        return updatedOrganization;
    }

    public async createOrganization(
        organization: Partial<Organization>,
        avatarFile?: File,
    ): Promise<Organization> {
        const path = `/organization`;
        const createdOrganization = await this.restService.post(path, {}, organization);

        if (avatarFile) {
            await this.uploadAvatar(createdOrganization.id!, avatarFile);
        }

        return createdOrganization;
    }

    public async getConcessionControlOrganizationById(orgId: string): Promise<ConcessionControl[]> {
        const path = `/organization/${orgId}/concessioncontrol/`;
        const concessionControl = await this.restService.get(path, {});
        return concessionControl;
    }
    public async updateConcessionControl(
        concessionControl: Partial<ConcessionControl>,
    ): Promise<ConcessionControl> {
        const path = `/organization/${concessionControl.organization?.id}/concessioncontrol/`;
        return await this.restService.put(path, {}, concessionControl);
    }

    public async updateFileConcessionControl(
        organizationId: string,
        concessionControlId: string,
        file: File,
        isFileDeleted: boolean,
        isNewFile: boolean,
    ): Promise<void> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        const path = `/organization/${organizationId}/concessioncontrol/${concessionControlId}/${isFileDeleted}/${isNewFile}`;
        await this.restService.post(path, {}, formData);
    }
}
