import { Component, inject, Signal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, map, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { OrganizationState } from 'src/app/features/admin/organization/organization.state';
import { AuthState } from 'src/app/core/states/auth.state';
import { CC_CONSTANTS } from '../../assets/constants';
import { MenuItem } from 'src/app/shared/interfaces/menuitem.interface';
import { SidenavService } from 'src/app/shared/services/sidenav.service';

interface TabsItem {
    display: string;
    routerLink: any[];
    routerLinkActiveOptions?: any;
}

@UntilDestroy()
@Component({
    selector: 'app-cc-generic',
    templateUrl: './generic.component.html',
    styleUrls: ['./generic.component.scss'],
})
export class GenericComponent {
    public title = '';
    public subTitle = '';
    public tabItems$: Observable<TabsItem[]> | undefined = undefined;
    public _currentOrganization$ = inject(Store).select(OrganizationState.currentOrganization);
    public _currentYear$ = inject(Store).select(OrganizationState.currentYear);
    public _loggedUser$ = inject(Store).select(AuthState.loggedUser);

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly sidenavServiceSuper: SidenavService,
    ) {
        this.setTabs();

        this.setSideNavTopMenuItems();
    }

    ngOnDestroy(): void {
        // do not reset sidenav for now, but i have the feeling this will change again soon
        // this.sidenavServiceSuper.setTopMenuItems([]);
    }

    public async setTabs(): Promise<void> {
        this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe((data) => {
            this.title = data['title'];
            this.subTitle = data['subTitle'];
            if (data['activeTab'] && data['tabs']) {
                this.tabItems$ = of(null).pipe(
                    map(() => {
                        return Object.keys(data['tabs']).map((key: string): TabsItem => {
                            const tab = data['tabs'][key];
                            return {
                                display: tab['title'],
                                routerLink: [`./${tab['path']}`],
                                routerLinkActiveOptions: { exact: false },
                            };
                        });
                    }),
                );
            }
        });
    }

    public async setSideNavTopMenuItems(): Promise<void> {
        combineLatest([this._currentOrganization$, this._currentYear$, this._loggedUser$])
            .pipe(untilDestroyed(this))
            .subscribe(([organization, year, loggedUser]) => {
                const prefixPath = `/main/${loggedUser?.isAdmin && organization?.id ? `${organization.id}/` : ''}cc/${year}`;
                const topMenuItems: MenuItem[] = [
                    {
                        label: CC_CONSTANTS.welcome.sidebarLabel,
                        icon: CC_CONSTANTS.welcome.icon,
                        routerLink: [`${prefixPath}/${CC_CONSTANTS.welcome.path}`],
                        routerLinkActiveOptions: { exact: false },
                        hidden: false,
                        isActive: false,
                        subItems: [],
                    },
                    {
                        label: CC_CONSTANTS.generalInfo.sidebarLabel,
                        icon: CC_CONSTANTS.generalInfo.icon,
                        routerLink: [`${prefixPath}/${CC_CONSTANTS.generalInfo.path}`],
                        routerLinkActiveOptions: { exact: false },
                        hidden: false,
                        isActive: false,
                        subItems: [],
                    },
                    {
                        label: 'Ouvrages',
                        icon: CC_CONSTANTS.sourceStations.icon,
                        routerLink: [`${prefixPath}/${CC_CONSTANTS.sourceStations.path}`],
                        routerLinkActiveOptions: { exact: false },
                        hidden: false,
                        isActive: false,
                        subItems: [
                            {
                                label: CC_CONSTANTS.sourceStations.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.sourceStations.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.mvNetworks.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.mvNetworks.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.lvNetworks.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.lvNetworks.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.transformerStations.sidebarLabel,
                                icon: '',
                                routerLink: [
                                    `${prefixPath}/${CC_CONSTANTS.transformerStations.path}`,
                                ],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                        ],
                    },
                    {
                        label: 'Continuité et Qualité',
                        icon: CC_CONSTANTS.supplyContinuity.icon,
                        routerLink: [`${prefixPath}/${CC_CONSTANTS.supplyContinuity.path}`],
                        routerLinkActiveOptions: { exact: false },
                        hidden: false,
                        isActive: false,
                        subItems: [
                            {
                                label: CC_CONSTANTS.supplyContinuity.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.supplyContinuity.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.voltageQuality.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.voltageQuality.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                        ],
                    },
                    {
                        label: 'Investissements et maintenance',
                        icon: CC_CONSTANTS.investments.icon,
                        routerLink: [`${prefixPath}/${CC_CONSTANTS.investments.path}`],
                        routerLinkActiveOptions: { exact: false },
                        hidden: false,
                        isActive: false,
                        subItems: [
                            {
                                label: CC_CONSTANTS.investments.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.investments.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.maintenanceCost.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.maintenanceCost.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                        ],
                    },
                    {
                        label: 'Comptabilité',
                        icon: CC_CONSTANTS.kpis.icon,
                        routerLink: [`${prefixPath}/${CC_CONSTANTS.kpis.path}`],
                        routerLinkActiveOptions: { exact: false },
                        hidden: false,
                        isActive: false,
                        subItems: [
                            {
                                label: CC_CONSTANTS.kpis.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.kpis.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.grantorRights.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.grantorRights.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                        ],
                    },
                    {
                        label: CC_CONSTANTS.operatingAccount.sidebarLabel,
                        icon: CC_CONSTANTS.operatingAccount.icon,
                        routerLink: [`${prefixPath}/${CC_CONSTANTS.operatingAccount.path}`],
                        routerLinkActiveOptions: { exact: false },
                        hidden: false,
                        isActive: false,
                        subItems: [],
                    },
                    {
                        label: 'Clientèle Enedis',
                        icon: CC_CONSTANTS.energyUsers.icon,
                        routerLink: [`${prefixPath}/${CC_CONSTANTS.energyUsers.path}`],
                        routerLinkActiveOptions: { exact: false },
                        hidden: false,
                        isActive: false,
                        subItems: [
                            {
                                label: CC_CONSTANTS.energyUsers.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.energyUsers.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.energyProducers.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.energyProducers.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.connections.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.connections.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.enedisQuality.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.enedisQuality.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.enedisClaims.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.enedisClaims.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                        ],
                    },
                    {
                        label: 'Clientèle EDF',
                        icon: CC_CONSTANTS.trvUsers.icon,
                        routerLink: [`${prefixPath}/${CC_CONSTANTS.trvUsers.path}`],
                        routerLinkActiveOptions: { exact: false },
                        hidden: false,
                        isActive: false,
                        subItems: [
                            {
                                label: CC_CONSTANTS.trvUsers.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.trvUsers.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.edfQuality.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.edfQuality.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.edfClaims.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.edfClaims.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                            {
                                label: CC_CONSTANTS.energyPoverty.sidebarLabel,
                                icon: '',
                                routerLink: [`${prefixPath}/${CC_CONSTANTS.energyPoverty.path}`],
                                routerLinkActiveOptions: { exact: false },
                                hidden: false,
                                isActive: false,
                                subItems: [],
                            },
                        ],
                    },
                    {
                        label: CC_CONSTANTS.exportReport.sidebarLabel,
                        icon: 'icon-download-cloud-02',
                        routerLink: [`${prefixPath}/${CC_CONSTANTS.exportReport.path}`],
                        routerLinkActiveOptions: { exact: false },
                        hidden: false,
                        isActive: false,
                        subItems: [],
                    },
                ];
                this.sidenavServiceSuper.setTopMenuItems(topMenuItems);
            });
    }

    public checkKpiErrors(kpi: any, ...keys: any[]): boolean {
        const isKpiDefined = !!kpi.organizationId;
        const isErrorDefined = !!kpi.error;
        const areAllValuesDefined = keys.every((key) => !!kpi[key]);
        const isErrorsDefined = isErrorDefined || !areAllValuesDefined;
        return isKpiDefined && isErrorsDefined;
    }
}
