import { Component, computed, effect, Signal } from '@angular/core';
import { ConcessioncontrolState, PrepareDataFromKeys } from '../../states/concessioncontrol.state';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { CC_CONSTANTS } from '../../assets/constants';
import { GenericComponent } from '../generic/generic.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, distinctUntilChanged } from 'rxjs';

import { ApexAxisChartSeries, ApexDataLabels, ApexYAxis } from 'ng-apexcharts';

import {
    colorAec1BlueLight,
    colorAec2BlueDark,
    colorAec3Green,
    colorAec4Orange,
    colorAec5Brown,
    colorAec6Gray,
    colorAec8RedDark,
    colorAec9Cream,
    colorAec7RedLight,
} from 'src/app/shared/all.constants';
import { CircleMarkerOptions, PathOptions } from 'leaflet';
import { LegendConfig } from 'src/app/shared/components/map/map.component';

import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { convertNumber } from 'src/app/shared/all.helpers';

const SOURCE_STATIONS_KPIS = 'ouvrages_sourcestations_x_kpis';
const SOURCE_STATIONS_CHART = 'ouvrages_sourcestations_x_chart';
const LOCALIZATION_MAP_KEY = 'ouvrages_sourcestations_x_localization_map';
const KEYS = [SOURCE_STATIONS_KPIS, SOURCE_STATIONS_CHART, LOCALIZATION_MAP_KEY];

interface KpiData {
    organizationId?: string;
    year?: string;
    error?: boolean;

    // kpi_id=13
    nbSourceStationsSupplying?: number;
    nbSourceStationsSupplyingEvolution?: number;

    // kpi_id=14
    nbSourceStationsIn?: number;
    nbSourceStationsInEvolution?: number;

    // kpi_id=15
    sourceStationsPowerSupplying?: number;
    sourceStationsPowerSupplyingEvolution?: number;

    // kpi_id=2009
    nbTransformersSupplying?: number;
    nbTransformersSupplyingEvolution?: number;
}

interface ChartData {
    organizationId?: string;
    year?: string;
    xYear?: number;
    nbSourceStationsSupplyingConcession?: number;
    nbSourceStationsInConcession?: number;
    sourceStationsPowerSupplyingConcession?: number;
    error?: boolean;
}

@UntilDestroy()
@Component({
    templateUrl: './sourcestations.component.html',
    styleUrls: ['./sourcestations.component.scss'],
})
export class SourceStationsComponent extends GenericComponent {
    public texts = CC_CONSTANTS.sourceStations.texts;

    public kpis: Signal<KpiData[]> = this.store.selectSignal(
        ConcessioncontrolState.getDataByKey(SOURCE_STATIONS_KPIS),
    ) as Signal<KpiData[]>;

    public chartData: Signal<ChartData[]> = this.store.selectSignal(
        ConcessioncontrolState.getDataByKey(SOURCE_STATIONS_CHART),
    ) as Signal<ChartData[]>;
    public chartSeries: ApexAxisChartSeries | undefined;
    public chartTitle = this.texts.sourceStationsChart.title;

    // first two have the same series name so apexcharts has to use the same yaxis for both
    public yaxis: ApexYAxis[] = [
        {
            seriesName: this.texts.sourceStationsChart.nbSourceStationsSupplyingConcessionAxisTitle,
            min: 0,
        },
        {
            seriesName: this.texts.sourceStationsChart.nbSourceStationsSupplyingConcessionAxisTitle,
            min: 0,
        },
        {
            seriesName: this.texts.sourceStationsChart.nbTransformersSupplyingConcessionAxisTitle,
            min: 0,
            opposite: true,
        },
    ];
    public dataLabels: ApexDataLabels = { enabled: true, enabledOnSeries: [] };

    public geometries: Signal<any[]> = this.store.selectSignal(
        ConcessioncontrolState.getDataByKey(LOCALIZATION_MAP_KEY),
    ) as Signal<any[]>;
    public geometriesWithTooltips: Signal<any[]> = computed(() => this._addTooltips());

    public legendConfig: LegendConfig[] = [
        {
            title: "Nombre d'usagers",
            values: [
                { color: colorAec9Cream, value: '0 - 500' },
                { color: colorAec6Gray, value: '500 - 2 000' },
                { color: colorAec3Green, value: '2 000 - 5 000' },
                { color: colorAec1BlueLight, value: '5 000 - 10 000' },
                { color: colorAec2BlueDark, value: '10 000+' },
            ],
        },
        {
            title: 'Postes sources alimentant la concession',
            values: [
                { color: colorAec8RedDark, value: '1 poste source', size: 0 },
                { color: colorAec8RedDark, value: '36 MVA ou moins', size: 5 },
                { color: colorAec8RedDark, value: 'Entre 40 et 70 MVA (inclus)', size: 8 },
                { color: colorAec8RedDark, value: 'Entre 72 et 102 MVA (inclus)', size: 12 },
                { color: colorAec5Brown, value: '2 postes sources', size: 15 },
                { color: colorAec4Orange, value: '3 postes sources ou plus', size: 15 },
            ],
        },
    ];

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly sidenavService: SidenavService,
    ) {
        //GenericComponent intialize sideNav, Tabs (if exists), title and subTitle
        super(route, sidenavService);

        effect(() => {
            const dataList = this.chartData();
            if (dataList?.length > 0 && !dataList[0].error) {
                this.chartSeries = [
                    {
                        name: this.texts.sourceStationsChart
                            .nbSourceStationsSupplyingConcessionAxisTitle,
                        type: 'column',
                        data: dataList.map((data) => ({
                            x: data.xYear,
                            y: data.nbSourceStationsSupplyingConcession,
                        })),
                        color: colorAec8RedDark,
                    },
                    {
                        name: this.texts.sourceStationsChart.nbSourceStationsInConcessionAxisTitle,
                        type: 'column',
                        data: dataList.map((data) => ({
                            x: data.xYear,
                            y: data.nbSourceStationsInConcession,
                        })),
                        color: colorAec2BlueDark,
                    },
                    {
                        name: this.texts.sourceStationsChart
                            .nbTransformersSupplyingConcessionAxisTitle,
                        type: 'line',
                        data: dataList.map((data) => ({
                            x: data.xYear,
                            y: data.sourceStationsPowerSupplyingConcession,
                        })),
                        color: colorAec3Green,
                    },
                ];
                //Add dataLabels
                //TODO Improve this
                this.dataLabels.enabledOnSeries = [0, 1, 2];
            }
        });
    }

    ngOnInit() {
        // Every time currentOrganization or currentYear changes, we tell the store he has to ask for the data
        combineLatest([this._currentOrganization$, this._currentYear$])
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(() => {
                this.store.dispatch(new PrepareDataFromKeys({ keys: KEYS }));
            });
    }

    public getLocalizationMapStyle(
        feature: GeoJSON.Feature | undefined,
    ): PathOptions | CircleMarkerOptions {
        if (!feature?.properties) {
            return {};
        }

        if (feature.properties['layerOrder'] === 1) {
            // Cities style
            let fillColor = colorAec9Cream;
            const usersCount = feature.properties['usersCount'];
            if (usersCount > 10000) {
                fillColor = colorAec2BlueDark;
            } else if (usersCount > 5000) {
                fillColor = colorAec1BlueLight;
            } else if (usersCount > 2000) {
                fillColor = colorAec3Green;
            } else if (usersCount > 500) {
                fillColor = colorAec6Gray;
            }

            return {
                fillColor,
                weight: 1,
                fillOpacity: 0.9,
                color: 'black',
            };
        } else {
            // Source stations style
            const sourceStationsCount = feature.properties['sourceStationsCount'];
            let fillColor = colorAec8RedDark;
            if (sourceStationsCount === 2) {
                fillColor = colorAec5Brown;
            } else if (sourceStationsCount > 2) {
                fillColor = colorAec4Orange;
            }

            const totalSourceStationsPower = feature.properties['totalSourceStationsPower'];
            let radius = 5;
            if (totalSourceStationsPower >= 108) {
                radius = 15;
            } else if (totalSourceStationsPower >= 72) {
                radius = 12;
            } else if (totalSourceStationsPower > 36) {
                radius = 8;
            }
            return {
                radius,
                weight: 1,
                fillColor,
                opacity: 1,
                fillOpacity: 0.8,
                color: 'black',
            };
        }
    }

    public getTooltip(feature: GeoJSON.Feature): string {
        if (!feature.properties) {
            return '';
        }
        let { cityName, usersCount, sourceStationsCount } = feature.properties;

        if (feature.properties['layerOrder'] === 1) {
            // Cities tooltip
            usersCount = convertNumber(Number(usersCount));
            return `${cityName} - ${usersCount} usagers`;
        } else {
            // Source stations tooltip
            sourceStationsCount = Number(sourceStationsCount);
            const plural = sourceStationsCount > 1 ? 's' : '';
            return `${cityName} - ${convertNumber(sourceStationsCount)} poste${plural} source${plural}`;
        }
    }

    private _addTooltips() {
        const usersCountTopFive = this.geometries()
            .filter((geometry) => geometry.layerOrder === 1)
            .map((geometry) => geometry.usersCount)
            .sort((countA, countB) => countB - countA)
            .slice(0, 5);
        return this.geometries().map((geometry) => {
            if (usersCountTopFive.includes(geometry.usersCount)) {
                geometry['showTooltip'] = true;
            }

            return geometry;
        });
    }
}
