import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MenuItem } from '../interfaces/menuitem.interface';

const defaultTopMenuItems: MenuItem[] = [];

@Injectable({
    providedIn: 'root',
})
export class SidenavService {
    public toggleSidenavObs = new BehaviorSubject<boolean>(false);
    public toggleSidenavObs$: Observable<boolean> = this.toggleSidenavObs.asObservable();

    public breakPointsThreshold = [Breakpoints.XSmall, Breakpoints.Small];

    private topMenuItemsObs = new BehaviorSubject<MenuItem[]>(defaultTopMenuItems);
    public topMenuItems$: Observable<MenuItem[]> = this.topMenuItemsObs.asObservable();

    constructor() {}

    public toggle() {
        this.toggleSidenavObs.next(!this.toggleSidenavObs.value);
    }

    public setTopMenuItems(menuItems: MenuItem[]) {
        this.topMenuItemsObs.next([...defaultTopMenuItems, ...menuItems]);
    }
}
