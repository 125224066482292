<div class="page-content">
    <div class="page-header">
        <h1>{{ title }}</h1>
        <div class="page-description">{{ subTitle }}</div>
    </div>

    <div class="page-body">
        <div class="container-fluid cards-container">
            @let kpi = kpis()[0];
            <div class="row g-3">
                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.concessionContractSigningDate.title"
                        [body]="{ value: kpi.concessionContractSigningDate }"
                        [descriptionTitle]="texts.default.descriptionTitle"
                        [descriptionContent]="texts.default.descriptionContent"
                        [isLoading]="!kpi.organizationId"
                        [error]="checkKpiErrors(kpi, 'concessionContractSigningDate')"
                        cardClass="h-100"></kpi-card>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.currentConcessionContractDuration.title"
                        [body]="{
                            value: kpi.currentConcessionContractDuration,
                            suffix: texts.currentConcessionContractDuration.suffix,
                        }"
                        [descriptionTitle]="texts.default.descriptionTitle"
                        [descriptionContent]="texts.default.descriptionContent"
                        [isLoading]="!kpi.organizationId"
                        [error]="checkKpiErrors(kpi, 'currentConcessionContractDuration')"
                        cardClass="h-100">
                    </kpi-card>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.concessionHoldersNames.title"
                        [body]="{ value: kpi.concessionHoldersNames }"
                        [descriptionTitle]="texts.default.descriptionTitle"
                        [descriptionContent]="texts.default.descriptionContent"
                        [isLoading]="!kpi.organizationId"
                        [error]="checkKpiErrors(kpi, 'concessionHoldersNames')"
                        cardClass="h-100">
                    </kpi-card>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.processedConcessionContractYear.title"
                        [body]="{ value: kpi.processedConcessionContractYear }"
                        [descriptionTitle]="texts.default.descriptionTitle"
                        [descriptionContent]="texts.default.descriptionContent"
                        [isLoading]="!kpi.organizationId"
                        [error]="checkKpiErrors(kpi, 'processedConcessionContractYear')"
                        cardClass="h-100">
                    </kpi-card>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.mvUndergroundingRate.title"
                        [body]="{
                            value: kpi.mvUndergroundingRate,
                            suffix: texts.mvUndergroundingRate.suffix,
                            precision: texts.mvUndergroundingRate.precision,
                        }"
                        [badge]="{
                            value: kpi.mvUndergroundingRateEvolution,
                            suffix: texts.mvUndergroundingRate.badgeSuffix,
                            precision: texts.mvUndergroundingRate.badgePrecision,
                            style: kpi.mvUndergroundingRateEvolution! > 0 ? 'success' : 'danger',
                        }"
                        [descriptionContent]="texts.mvUndergroundingRate.descriptionContent"
                        [isLoading]="!kpi.organizationId"
                        [error]="
                            checkKpiErrors(
                                kpi,
                                'mvUndergroundingRate',
                                'mvUndergroundingRateEvolution'
                            )
                        "
                        cardClass="h-100">
                    </kpi-card>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.lvUndergroundingRate.title"
                        [body]="{
                            value: kpi.lvUndergroundingRate,
                            suffix: texts.lvUndergroundingRate.suffix,
                            precision: texts.lvUndergroundingRate.precision,
                        }"
                        [badge]="{
                            value: kpi.lvUndergroundingRateEvolution,
                            suffix: texts.lvUndergroundingRate.badgeSuffix,
                            precision: texts.lvUndergroundingRate.badgePrecision,
                            style: kpi.lvUndergroundingRateEvolution! > 0 ? 'success' : 'danger',
                        }"
                        [descriptionContent]="texts.lvUndergroundingRate.descriptionContent"
                        [isLoading]="!kpi.organizationId"
                        [error]="
                            checkKpiErrors(
                                kpi,
                                'lvUndergroundingRate',
                                'lvUndergroundingRateEvolution'
                            )
                        "
                        cardClass="h-100">
                    </kpi-card>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.linkyDeploymentRate.title"
                        [body]="{
                            value: kpi.linkyDeploymentRate,
                            suffix: texts.linkyDeploymentRate.suffix,
                            precision: texts.linkyDeploymentRate.precision,
                        }"
                        [footer]="{
                            prefix: texts.linkyDeploymentRate.footerPrefix,
                            value: kpi.linkyDeploymentRateMean,
                            suffix: texts.linkyDeploymentRate.footerSuffix,
                            precision: texts.linkyDeploymentRate.footerPrecision,
                        }"
                        [isLoading]="!kpi.organizationId"
                        [error]="
                            checkKpiErrors(kpi, 'linkyDeploymentRate', 'linkyDeploymentRateMean')
                        "
                        cardClass="h-100">
                    </kpi-card>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.criterionBHix.title"
                        [body]="{
                            value: kpi.criterionBHix,
                            precision: texts.criterionBHix.precision,
                            suffix: texts.criterionBHix.suffix,
                        }"
                        [footer]="{
                            value: kpi.criterionBHixMean,
                            prefix: texts.criterionBHix.footerPrefix,
                            suffix: texts.criterionBHix.footerSuffix,
                            precision: texts.criterionBHix.footerPrecision,
                        }"
                        [descriptionTitle]="texts.criterionBHix.descriptionTitle"
                        [descriptionContent]="texts.criterionBHix.descriptionContent"
                        [isLoading]="!kpi.organizationId"
                        [error]="checkKpiErrors(kpi, 'criterionBHix', 'criterionBHixMean')"
                        cardClass="h-100">
                    </kpi-card>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.totalInvestments.title"
                        [body]="{
                            value: kpi.totalInvestments,
                            precision: texts.totalInvestments.precision,
                            suffix: texts.totalInvestments.suffix,
                        }"
                        [badge]="{
                            value: kpi.totalInvestmentsEvolution,
                            suffix: texts.totalInvestments.badgeSuffix,
                            precision: texts.totalInvestments.badgePrecision,
                            style: kpi.totalInvestmentsEvolution! > 0 ? 'success' : 'danger',
                        }"
                        [isLoading]="!kpi.organizationId"
                        [error]="
                            checkKpiErrors(kpi, 'totalInvestments', 'totalInvestmentsEvolution')
                        "
                        cardClass="h-100">
                    </kpi-card>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <kpi-card
                        [title]="texts.totalNumberOfUsers.title"
                        [body]="{
                            value: kpi.totalNumberOfUsers,
                            precision: texts.totalNumberOfUsers.precision,
                        }"
                        [badge]="{
                            value: kpi.totalNumberOfUsersEvolution,
                            precision: texts.totalNumberOfUsers.badgePrecision,
                            style: kpi.totalNumberOfUsersEvolution! > 0 ? 'success' : 'danger',
                        }"
                        [descriptionContent]="texts.totalNumberOfUsers.descriptionContent"
                        [isLoading]="!kpi.organizationId"
                        [error]="
                            checkKpiErrors(kpi, 'totalNumberOfUsers', 'totalNumberOfUsersEvolution')
                        "
                        cardClass="h-100">
                    </kpi-card>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <geo-map-card title="Big MAP" cardClass="h-100"> </geo-map-card>
                </div>
            </div>
        </div>
    </div>
</div>
