import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { ConcessionControlService } from '../services/concessioncontrol.service';
import { OrganizationState } from '../../../admin/organization/organization.state';

/** This file is divided in 3 parts:
 * - The definition of the Store : defining data structure
 * - Constants that allow NgXs to trigger Actions
 * - Selectors & Actions : Functions allowed to interact with the store
 *
 */

// Store definition
export interface ConcessionControlModel {
    currentData: {
        [key: string]: any;
    };
    data: {
        [organization_id: string]: {
            [year: number]: {
                [key: string]: any;
            };
        };
    };
}
// We get data like model[organizationId][year][keyValue]

//Constants

//Action loaded on page init : ask store to retreive all data from a list of keys
export class PrepareDataFromKeys {
    static readonly type = 'CONCESSIONCONTROL/PREPARE_DATA_FOR_PAGE';
    constructor(public payload: { keys: string[] }) {}
}

//Actions
const NAME = 'concessionControl';
@State<ConcessionControlModel>({
    name: NAME,
    defaults: {
        currentData: {},
        data: {},
    },
})
@Injectable()
export class ConcessioncontrolState {
    constructor(
        private readonly concessionControlService: ConcessionControlService,
        private readonly store: Store,
    ) {}

    public static getDataByKey(key: string) {
        return createSelector(
            [ConcessioncontrolState],
            (state: ConcessionControlModel): [{ [key: string]: [] }] => {
                if (state.currentData[key] && state.currentData[key].length > 0) {
                    return state.currentData[key];
                } else {
                    return [{}];
                }
            },
        );
    }

    @Action(PrepareDataFromKeys)
    public async prepareDataFromKeys(
        ctx: StateContext<ConcessionControlModel>,
        action: PrepareDataFromKeys,
    ): Promise<void> {
        const state = ctx.getState();
        const { keys } = action.payload;
        const currentOrganization = this.store.selectSnapshot(
            OrganizationState.currentOrganization,
        );
        const currentYear = this.store.selectSnapshot(OrganizationState.currentYear);

        if (!currentOrganization || !currentYear) {
            return;
        }

        // First we get the already memorized dataset if we have it, empty object if not
        const currentData =
            (state.data[currentOrganization.id] &&
                state.data[currentOrganization.id][currentYear]) ||
            {};

        for (const key of keys) {
            //If we already have this key, we don't ask again
            if (currentData[key]?.length > 0 && !currentData[key][0].error) {
                continue;
            }
            try {
                const data: [] = await this.concessionControlService.get(
                    currentOrganization.id,
                    currentYear,
                    key,
                );
                if (data && data.length > 0) {
                    currentData[key] = data;
                } else {
                    currentData[key] = [{ error: true }];
                }
            } catch (error: any) {
                currentData[key] = [{ error: true }];
            }
        }
        // Finally we save the data, in both current and memorized states
        state.currentData = currentData;
        state.data[currentOrganization.id] = state.data[currentOrganization.id] || {};
        state.data[currentOrganization.id][currentYear] = currentData;
        ctx.patchState(state);
    }
}
