import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericComponent } from '../generic/generic.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidenavService } from 'src/app/shared/services/sidenav.service';

@UntilDestroy()
@Component({
    selector: 'app-mv-networks',
    templateUrl: './mv-networks.component.html',
    styleUrls: ['./mv-networks.component.scss'],
})
export class MvNetworksComponent extends GenericComponent {
    constructor(
        private readonly route: ActivatedRoute,
        private readonly sidenavService: SidenavService,
    ) {
        super(route, sidenavService);
    }
}
