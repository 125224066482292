import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    authGuard,
    notAuthGuard,
    adminAuthGuard,
    tokenGuard,
    notAdminAuthGuard,
} from './core/guards/auth.guard';

import { AuthModule } from './features/auth/auth.module';
import { AdminModule } from './features/admin/admin.module';

import { HomeComponent } from './features/main/home/home.component';
import { MainModule } from './features/main/main.module';

import { MainComponent } from './features/main/main.component';

import { AuthComponent } from './features/auth/auth.component';
import { LoginComponent } from './features/auth/login/login.component';
import { ForgotPasswordComponent } from './features/auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './features/auth/change-password/change-password.component';
import { SignUpComponent } from './features/auth/signup/signup.component';

import { AdminComponent } from './features/admin/admin.component';
import { AccountComponent } from './features/main/settings/account/account.component';
import { OrganizationComponent } from './features/admin/organization/organization.component';
import { OrganizationDetailComponent } from './features/admin/organization/organization-detail/organization-detail.component';
import { UsersComponent } from './features/admin/users/users.component';
import { OrganizationManageStudiesComponent } from './features/admin/organization/organization-manage-studies/organization-manage-studies.component';
import { OrganizationGeneralComponent } from './features/admin/organization/organization-general/organization-general.component';
import { canDeactivateGuard } from './features/main/settings/account/account.guard';
import { concessionControlRoutes } from './features/main/concessioncontrol/concessioncontrol.route';
import { cracControlRoutes } from './features/main/crac/crac.route';
import { organizationGuard } from './core/guards/organization.guard';
import { yearGuard } from './core/guards/year.guard';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        canActivate: [notAuthGuard],
        children: [
            {
                path: '',
                component: LoginComponent,
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
        ],
    },
    {
        path: 'change-password',
        component: AuthComponent,
        canActivate: [tokenGuard],
        children: [
            {
                path: '',
                component: ChangePasswordComponent,
            },
        ],
    },
    {
        path: 'signup',
        component: AuthComponent,
        canActivate: [tokenGuard],
        children: [
            {
                path: '',
                component: SignUpComponent,
            },
        ],
    },

    {
        path: 'main',
        component: MainComponent,
        canActivate: [authGuard],
        children: [
            { path: '', component: HomeComponent, canActivate: [notAdminAuthGuard] },
            {
                path: 'cc/:year',
                canActivate: [notAdminAuthGuard, yearGuard],
                children: concessionControlRoutes,
            },
            {
                path: 'crac/:year',
                canActivate: [notAdminAuthGuard, yearGuard],
                children: cracControlRoutes,
            },
            { path: 'account', component: AccountComponent, canDeactivate: [canDeactivateGuard] },
        ],
    },
    {
        path: 'main/:organizationId',
        component: MainComponent,
        canActivate: [adminAuthGuard, organizationGuard],
        children: [
            {
                path: '',
                component: HomeComponent,
            },
            {
                path: 'cc/:year',
                canActivate: [yearGuard],
                children: concessionControlRoutes,
            },
            {
                path: 'crac/:year',
                canActivate: [yearGuard],
                children: cracControlRoutes,
            },
        ],
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [adminAuthGuard],
        children: [
            { path: '', component: OrganizationComponent },
            {
                path: 'organization',
                component: OrganizationComponent,
                canActivate: [organizationGuard],
            },
            {
                path: ':organizationId',
                component: OrganizationDetailComponent,
                canActivate: [organizationGuard],
                children: [
                    { path: 'general', component: OrganizationGeneralComponent },
                    { path: 'users', component: UsersComponent },
                    { path: 'manageStudies', component: OrganizationManageStudiesComponent },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), AuthModule, MainModule, AdminModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
