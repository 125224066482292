import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from '@ed---interne/ng-uui-components';
import { FileSizePipe, NgxFilesizeModule } from 'ngx-filesize';

import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';

import { AdminComponent } from './admin.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserUpdateComponent } from './users/user-update/user-update.component';
import { OrganizationCreateComponent } from './organization/organization-create/organization-create.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { OrganizationDetailComponent } from './organization/organization-detail/organization-detail.component';
import { OrganizationGeneralComponent } from './organization/organization-general/organization-general.component';
import { OrganizationManageStudiesComponent } from './organization/organization-manage-studies/organization-manage-studies.component';

@NgModule({
    declarations: [
        AdminComponent,
        UserCreateComponent,
        UserUpdateComponent,
        OrganizationCreateComponent,
        OrganizationDetailComponent,
        OrganizationGeneralComponent,
        OrganizationManageStudiesComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        FormsModule,
        ComponentsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxTippyModule,
        NgxFilesizeModule,
    ],
    providers: [FileSizePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
