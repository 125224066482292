import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    NgbDateAdapter,
    NgbDateParserFormatter,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';

import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { NgApexchartsModule } from 'ng-apexcharts';

import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { ComponentsModule } from '@ed---interne/ng-uui-components';

import { EdDatepickerComponent } from './components/forms/ed-datepicker/ed-datepicker.component';
import {
    CustomAdapter,
    CustomDateParserFormatter,
} from './components/forms/ed-datepicker/ngbd-datepicker.adapter';
import { EdInputFileComponent } from './components/forms/ed-input-file/ed-input-file.component';
import { DropZoneDirective } from './components/forms/ed-input-file/dropzone.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { ChartCardComponent } from './components/chart-card/chart-card.component';
import { KpiCardComponent } from './components/kpi-card/kpi-card.component';
import { GeoMapCardComponent } from './components/geo-map-card/geo-map-card.component';
import { GenericCardComponent } from './components/generic-card/generic-card.component';
import { MapComponent } from './components/map/map.component';
import { StudiesComponent } from './components/studies/studies.component';

@NgModule({
    declarations: [
        EdDatepickerComponent,
        EdInputFileComponent,
        GenericCardComponent,
        KpiCardComponent,
        GeoMapCardComponent,
        MapComponent,
        StudiesComponent,
        ChartCardComponent,
        DropZoneDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        NgbCollapseModule,
        ComponentsModule,
        InfiniteScrollDirective,
        DragAndDropDirective,
        MatSelectModule,
        NgxTippyModule,
        NgApexchartsModule,
        MatMenuModule,
    ],
    exports: [
        EdDatepickerComponent,
        EdInputFileComponent,
        GenericCardComponent,
        KpiCardComponent,
        GeoMapCardComponent,
        ChartCardComponent,
        MapComponent,
        StudiesComponent,
        DropZoneDirective,
        InfiniteScrollDirective,
        DragAndDropDirective,
    ],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        DatePipe,
    ],
})
export class SharedModule {}
