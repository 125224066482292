import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { filter, first, Observable, Subscription } from 'rxjs';

import {
    AlertService,
    ButtonColors,
    ButtonTypes,
    LayerService,
    LayerSize,
} from '@ed---interne/ng-uui-components';

import { User, UpdateUserModel } from 'src/app/core/models/user.model';
import { AuthState } from 'src/app/core/states/auth.state';
import { UpdateUser } from 'src/app/features/admin/users/users.state';
import { passwordValidator } from 'src/app/shared/validators/password.validator';
import { LayerPopupOptions } from 'src/app/shared/all.constants';

import { AccountPasswordEditComponent } from '../edit-password/account-password-edit.component';
import Swal from 'sweetalert2';

@UntilDestroy()
@Component({
    selector: 'account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
    public buttonColors = ButtonColors;
    public buttonTypes = ButtonTypes;

    public user!: User;
    public password: string | null = null;
    public passwordConfirm: string | null = null;
    public header: any;
    public loggedUser$!: Observable<User | null>;
    public loggedUser!: User;
    private _subscriptions: Subscription[] = [];
    public isSaving = false;
    public isSubmitting: boolean = false;

    public userFormGroup: FormGroup = this.formBuilder.group({
        mail: [{ value: '', disabled: true }],
        password: new FormControl({ value: '**********', disabled: true }, { nonNullable: true }),
        firstname: [''],
        lastname: [''],
    });

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService,
        private readonly store: Store,
        private readonly alertService: AlertService,
    ) {}

    public ngOnInit(): void {
        this.loggedUser$ = this.store.select(AuthState.loggedUser);
        this.loggedUser$
            .pipe(untilDestroyed(this))
            .subscribe((data: any) => (this.loggedUser = data));
        this.userFormGroup.patchValue(this.loggedUser);
    }

    public editPassword(): void {
        const loggedUser = this.loggedUser;
        this.layerService.show(
            AccountPasswordEditComponent,
            { loggedUser },
            {
                size: LayerSize.Medium,
                withClosePopup: LayerPopupOptions.Editing,
            },
        );
    }

    public resetForm(): void {
        this.userFormGroup.reset();

        const sub = this.loggedUser$
            .pipe(first(), filter(Boolean), untilDestroyed(this))
            .subscribe((loggedUser) => this.userFormGroup.patchValue(loggedUser));
        this._subscriptions.push(sub);
    }

    public onSubmit(): void {
        const { firstname, lastname } = this.userFormGroup.value;
        const userToUpdate: Partial<UpdateUserModel> = {
            id: this.loggedUser.id,
            firstname,
            lastname,
        };
        this.loggedUser.lastname = lastname;
        this.loggedUser.firstname = firstname;

        this.isSaving = true;
        this.isSubmitting = true;

        const sub = this.store.dispatch(new UpdateUser({ user: userToUpdate })).subscribe({
            next: () => {
                this.alertService.valid('Profil', 'Votre profil a bien été modifié');
                this.isSaving = false;
                this.isSubmitting = false;
                this.userFormGroup.markAsPristine();
            },
            error: (error) => {
                this.alertService.error(
                    'Mon profil',
                    "Une erreur est survenue : Impossible de mettre à jour l'utilisateur",
                );
                this.isSaving = false;
                this.isSubmitting = false;
            },
        });
        this._subscriptions.push(sub);
    }
}
