import { Component, effect, inject, Signal } from '@angular/core';
import { ConcessioncontrolState, PrepareDataFromKeys } from '../../states/concessioncontrol.state';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { CC_CONSTANTS } from '../../assets/constants';
import { GenericComponent } from '../generic/generic.component';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApexAxisChartSeries, ApexDataLabels, ApexYAxis } from 'ng-apexcharts';
import { colorAec1BlueLight, colorAec7RedLight } from 'src/app/shared/all.constants';
import { SidenavService } from 'src/app/shared/services/sidenav.service';

const GENERAL_INFO_MUNICIPALITIES_CHART = 'general-info_x_x_municipalities_chart';
const KEYS = [GENERAL_INFO_MUNICIPALITIES_CHART];

interface ChartData {
    organizationId?: string;
    year?: string;
    xYear?: string;
    nbMunicipalities?: number;
    nbUsers?: string;
    error?: boolean;
}

@UntilDestroy()
@Component({
    templateUrl: 'general-info.component.html',
    styleUrls: ['./general-info.component.scss'],
})
export class GeneralInfoComponent extends GenericComponent {
    public chartData: Signal<ChartData[]> = this.store.selectSignal(
        ConcessioncontrolState.getDataByKey(GENERAL_INFO_MUNICIPALITIES_CHART),
    ) as Signal<ChartData[]>;

    public texts = CC_CONSTANTS.generalInfo.texts;
    public chartTitle = this.texts.municipalitiesChart.title;
    public chartDescriptionContent = this.texts.municipalitiesChart.descriptionContent;

    public chartSeries: ApexAxisChartSeries | undefined;
    public yaxis: ApexYAxis[] = [
        {
            seriesName: this.texts.municipalitiesChart.nbMunicipalitiesAxisTitle,
            min: 0,
        }, //This is the first default
        {
            seriesName: this.texts.municipalitiesChart.nbUsersAxisTitle,
            min: 0,
            opposite: true,
        }, //Second one is to the right
    ];

    public dataLabels: ApexDataLabels = { enabled: true, enabledOnSeries: [] };

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly sidenavService: SidenavService,
    ) {
        //GenericComponent intialize sideNav, Tabs (if exists), title and subTitle
        super(route, sidenavService);

        effect(() => {
            const dataList = this.chartData();
            if (dataList?.length > 0 && !dataList[0].error) {
                this.chartSeries = [
                    {
                        name: this.texts.municipalitiesChart.nbMunicipalitiesAxisTitle,
                        type: 'column',
                        data: dataList.map((data) => {
                            return { x: data.xYear, y: data.nbMunicipalities };
                        }),
                        color: colorAec1BlueLight,
                    },
                    {
                        name: this.texts.municipalitiesChart.nbUsersAxisTitle,
                        type: 'line',
                        data: dataList.map((data) => {
                            return { x: data.xYear, y: data.nbUsers };
                        }),
                        color: colorAec7RedLight,
                    },
                ];
                //Add dataLabels
                //TODO Improve this
                this.dataLabels.enabledOnSeries = [0, 1];
            }
        });
    }

    ngOnInit() {
        // Every time currentOrganization or currentYear changes, we tell the store he has to ask for the data
        combineLatest([this._currentOrganization$, this._currentYear$])
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(() => {
                this.store.dispatch(new PrepareDataFromKeys({ keys: KEYS }));
            });
    }
}
