<div class="container-fluid studies-container">
    <div class="row">
        <div class="col-sm-12 col-lg-6">
            <div class="crac-card">
                <div class="header">
                    <h1>CRAC</h1>
                    <div class="sub-header">Accéder à vos exercices</div>
                </div>

                <div class="studies">
                    <div *ngFor="let study of cracStudies" class="study">
                        <div class="header">
                            <h2>{{ study.year }}</h2>

                            <ng-container
                                *ngTemplateOutlet="
                                    cracStudyHeaderTemplate;
                                    context: { study: study }
                                "></ng-container>
                        </div>

                        <ng-container
                            *ngTemplateOutlet="
                                cracStudyBodyTemplate;
                                context: { study: study }
                            "></ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-lg-6">
            <div class="cc-card">
                <div class="header">
                    <h1>Contrôle de concession</h1>
                    <div class="sub-header">Accéder à vos exercices</div>
                </div>

                <div class="studies">
                    <div *ngFor="let study of ccStudies" class="study">
                        <div class="header">
                            <h2>{{ study.year }}</h2>

                            <ng-container
                                *ngTemplateOutlet="
                                    ccStudyHeaderTemplate;
                                    context: { study: study }
                                "></ng-container>
                        </div>

                        <ng-container
                            *ngTemplateOutlet="
                                ccStudyBodyTemplate;
                                context: { study: study }
                            "></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
