<table mat-table [dataSource]="_dataSource" matSort class="mat-elevation-z8">
    <ng-container *ngFor="let display of _displayedColumns" [matColumnDef]="display.objectKey">
        <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="display.disableSort"
            [ngClass]="{ 'center-cell': centerContent(display.displayType) }">
            {{ display.objectDisplayName }}
        </th>

        <ng-container *ngIf="showDisplay(display.displayType, displayType.TEXT)">
            <td
                mat-cell
                *matCellDef="let element"
                (click)="display.clickable && elementClicked(element, display.objectKey)">
                <div
                    *ngIf="display.objectKey"
                    [class.bold]="display.bold || (display.boldKey && element[display.boldKey])"
                    [class.light]="element[display.objectKey] === unknownLabel"
                    [class.emphasis]="
                        display.italic || (display.italicKey && element[display.italicKey])
                    ">
                    {{ element[display.objectKey] }}
                </div>
                <div *ngIf="display.objectSecondKey" class="text-second">
                    {{ element[display.objectSecondKey] }}
                </div>
            </td>
        </ng-container>

        <ng-container *ngIf="showDisplay(display.displayType, displayType.AVATAR)">
            <td mat-cell *matCellDef="let element" class="center-cell">
                @if (display.objectKey) {
                    @if (!display.clickable) {
                        @if (
                            !element.isSubRow && getAvatarUrl(element[display.objectKey]);
                            as avatarUrl
                        ) {
                            @if (
                                display.objectSecondKey &&
                                isAvatarTypeOrg(element[display.objectSecondKey])
                            ) {
                                <div class="avatar-container">
                                    <img [ngSrc]="avatarUrl" width="32" height="32" alt="avatar" />
                                </div>
                            } @else {
                                <img
                                    [ngSrc]="avatarUrl"
                                    width="32"
                                    height="32"
                                    alt="avatar"
                                    class="non-clickable-avatar" />
                            }
                        } @else if (element.isSubRow) {
                            <i class="icon icon-corner-down-right"></i>
                        } @else {
                            <div class="no-avatar-icon">
                                <i class="icon icon-user-circle"></i>
                            </div>
                        }
                    } @else {
                        <label class="custom-file-upload">
                            <input
                                type="file"
                                (change)="
                                    display.clickable &&
                                        elementClicked(element, display.objectKey, $event)
                                " />
                            @if (getAvatarUrl(element[display.objectKey]); as avatarUrl) {
                                <div class="avatar-container">
                                    <div
                                        class="foreground"
                                        tippyClassName="ed-custom-tippy"
                                        [tippyProps]="{
                                            allowHTML: true,
                                            placement: 'top',
                                            content:
                                                '<div><b>Modifier l\'icône de l\'organisation</b></div><div>Format rond ou carré (JPG, PNG, TIFF)</div>',
                                        }"
                                        ngxTippy>
                                        <i class="icon icon-upload-cloud-02"></i>
                                    </div>
                                    <img [ngSrc]="avatarUrl" width="32" height="32" alt="avatar" />
                                </div>
                            } @else {
                                <div
                                    class="avatar-upload"
                                    tippyClassName="ed-custom-tippy"
                                    [tippyProps]="{
                                        allowHTML: true,
                                        placement: 'top',
                                        content:
                                            '<div><b>Téléverser l\'icone de l\'organisation</b></div><div>Format rond ou carré (JPG, PNG, TIFF)</div>',
                                    }"
                                    ngxTippy>
                                    <i class="icon icon-upload-cloud-02"></i>
                                </div>
                            }
                        </label>
                    }
                }
            </td>
        </ng-container>

        <ng-container *ngIf="showDisplay(display.displayType, displayType.FILE)">
            <td
                mat-cell
                *matCellDef="let element"
                class="filename-column"
                (click)="display.clickable && elementClicked(element, display.objectKey)">
                <div class="file-row-container">
                    <div class="file-row-icon-container">
                        <i [class]="'icon ' + getFileIcon(element.ext)"></i>
                    </div>
                    <div class="file-row-text-container">
                        <div
                            [class.bold]="
                                display.bold || (display.boldKey && element[display.boldKey])
                            "
                            [class.emphasis]="
                                display.italic || (display.italicKey && element[display.italicKey])
                            ">
                            {{ element[display.objectKey] }}
                        </div>
                        <div *ngIf="display.objectSecondKey" class="text-second">
                            {{ element[display.objectSecondKey] }}
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container *ngIf="showDisplay(display.displayType, displayType.DATE)">
            <td
                mat-cell
                *matCellDef="let element"
                (click)="display.clickable && elementClicked(element, display.objectKey)"
                class="center-cell date-column">
                <ng-container *ngIf="element[display.objectKey]; else undefinedDateTemplate">{{
                    element[display.objectKey] | date: 'dd/MM/yy'
                }}</ng-container>
            </td>
        </ng-container>

        <ng-container *ngIf="showDisplay(display.displayType, displayType.NUMBER)">
            <td
                mat-cell
                *matCellDef="let element"
                (click)="display.clickable && elementClicked(element, display.objectKey)"
                class="center-cell">
                <ng-container *ngIf="element[display.objectKey]">{{
                    element[display.objectKey]
                }}</ng-container>
            </td>
        </ng-container>

        <ng-container *ngIf="showDisplay(display.displayType, displayType.LABELS)">
            <td
                mat-cell
                *matCellDef="let element"
                class="file-labels-column"
                (click)="display.clickable && elementClicked(element, display.objectKey)">
                <div class="cell-labels-container">
                    @for (
                        label of getVisibleConcessions(element.concessionControls);
                        track $index
                    ) {
                        <ed-document-label
                            [smallFont]="true"
                            class="badge-custom"
                            [label]="label.year.toString()"
                            [class]="getBadgeClass(label.status)">
                        </ed-document-label>
                    }
                    @if (getHiddenCount(element.concessionControls) > 0) {
                        <div
                            tippyClassName="ed-custom-tippy"
                            [tippyProps]="{
                                allowHTML: true,
                                placement: 'right',
                                content: getHiddenYearsTooltip(element.concessionControls),
                            }"
                            ngxTippy>
                            <ed-document-label
                                [smallFont]="true"
                                class="badge-custom"
                                [label]="'+' + getHiddenCount(element.concessionControls)"
                                [class]="getBadgeClass(4)">
                            </ed-document-label>
                        </div>
                    }
                </div>
            </td>
        </ng-container>

        <ng-container *ngIf="showDisplay(display.displayType, displayType.ICON)">
            <td mat-cell *matCellDef="let element">
                @if (!element['hide_' + display.icon]) {
                    <i
                        [class]="'icon ' + display.icon"
                        (click)="
                            display.clickable && elementClicked(element, display.objectKey)
                        "></i>
                }
            </td>
        </ng-container>

        <ng-container *ngIf="showDisplay(display.displayType, displayType.BADGE)">
            <td mat-cell *matCellDef="let element; let i = index" class="centered">
                <div
                    class="list-badge badge"
                    tippyClassName="ed-custom-tippy left-aligned"
                    [tippyName]="'tipCrmL' + element.id + '_' + i"
                    [tippyProps]="{
                        allowHTML: true,
                        placement: 'left',
                        content: '',
                        trigger: 'manual',
                    }"
                    ngxTippy>
                    {{ element }}%
                </div>
            </td>
        </ng-container>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
            mat-cell
            class="actions-cell"
            *matCellDef="let element"
            (click)="$event.stopPropagation(); $event.preventDefault()">
            @if (!_areActionsInElements) {
                <ed-dropdown-menu
                    (onMenuItemClick)="onDropdownMenuClicked(element, $event)"
                    [menuList]="_actionsMenuList"
                    [buttonIcon]="'icon-dots-vertical'"></ed-dropdown-menu>
            }
            @if (_areActionsInElements) {
                <ed-dropdown-menu
                    *ngIf="element.actions"
                    (onMenuItemClick)="onDropdownMenuClicked(element, $event)"
                    [menuList]="element.actions"
                    [buttonIcon]="'icon-dots-vertical'"></ed-dropdown-menu>
            }
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsKeys"></tr>
    <tr
        [ngClass]="{ 'has-sub-row': !!row.subRows?.length }"
        mat-row
        *matRowDef="let row; columns: columnsKeys; when: !isSubRow"
        (click)="rowClicked(row.id)"></tr>
    <tr
        [ngClass]="row.isLastSubRow ? 'last-sub-row' : 'sub-row'"
        mat-row
        *matRowDef="let row; columns: columnsKeys; when: isSubRow"></tr>
</table>

<ng-template #unknownIconTemplate>
    <i class="icon icon-dpe-none"></i>
</ng-template>

<ng-template #undefinedDateTemplate>-</ng-template>
