import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, debounceTime, fromEvent } from 'rxjs';

@UntilDestroy()
@Directive({
    selector: '[EdInfiniteScroll]',
    standalone: true,
})
export class InfiniteScrollDirective implements OnInit, OnDestroy {
    @Output()
    public nearEnd: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public distanceToBottom = 60;

    @Input()
    public listenToScroll: boolean | null = false;

    private _subscriptions: Subscription[] = [];

    public ngOnInit(): void {
        const elems = document.querySelectorAll('.main-container');

        const elemIndex = elems.length - 1; // Utilise le deuxième élément s'il y en a plus d'un, sinon le premier

        if (elems.length > 0) {
            this._subscriptions.push(
                fromEvent(elems[elemIndex], 'scroll', { capture: true })
                    .pipe(debounceTime(250), untilDestroyed(this))
                    .subscribe((event) => {
                        this._onScroll(event);
                    }),
            );
        }
    }

    private _onScroll(event: Event): void {
        if (!this.listenToScroll) {
            return;
        }

        const element = event.target as HTMLElement;

        if (!element) {
            return;
        }

        const distance = element.scrollHeight - element.offsetHeight - element.scrollTop;

        if (distance <= this.distanceToBottom) {
            this.nearEnd.emit();
        }
    }

    public ngOnDestroy(): void {
        for (const sub of this._subscriptions) {
            sub.unsubscribe();
        }
    }
}
