import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AlertService } from '@ed---interne/ng-uui-components';

import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { ErrorHttp } from '../models/errorHttp.model';

import { SetUserOrganization } from '../../features/admin/organization/organization.state';

/** This file is divided in 3 parts:
 * - The definition of the Store : defining data structure
 * - Constants that allow NgXs to trigger Actions
 * - Selectors & Actions : Functions allowed to interact with the store
 *
 */

// Store definition
export interface AuthStateModel {
    loggedUser: User | null;
    error: ErrorHttp | null;
    isLoading: boolean;
}

//Constants

export class Login {
    static readonly type = 'AUTH/LOGIN';
    constructor(public payload: { email: string; password: string }) {}
}

export class Logout {
    static readonly type = 'AUTH/LOGOUT';
}

export class Register {
    static readonly type = 'AUTH/REGISTER';
    constructor(public payload: { loggedUser: User }) {}
}

export class PasswordForgot {
    static readonly type = 'AUTH/PASSWORD_FORGOT';
    constructor(public payload: { email: string }) {}
}

export class PasswordChange {
    static readonly type = 'AUTH/PASSWORD_CHANGE';
    constructor(public payload: { token: string; password: string }) {}
}

export class Signup {
    static readonly type = 'AUTH/SIGNUP';
    constructor(public payload: { token: string; user: Partial<User> }) {}
}

//Actions

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        loggedUser: null,
        error: null,
        isLoading: false,
    },
})
@Injectable()
export class AuthState {
    @Selector()
    public static loggedUser(state: AuthStateModel): User | null {
        return state.loggedUser;
    }

    @Selector()
    public static error(state: AuthStateModel): any {
        return state.error;
    }

    constructor(
        private readonly authService: AuthService,
        private readonly alertService: AlertService,
        private readonly store: Store,
    ) {}

    @Action(Login)
    public async login(ctx: StateContext<AuthStateModel>, action: Login): Promise<void> {
        try {
            const user = await this.authService.login(
                action.payload.email,
                action.payload.password,
            );
            ctx.patchState({ loggedUser: user, error: null, isLoading: true });

            if (!!user) {
                this.store.dispatch(
                    new SetUserOrganization({
                        organization: user.organization!,
                        isAdmin: user.isAdmin,
                    }),
                );
            }
        } catch (err: any) {
            console.log(err);
            const errorMessage = err.error?.content?.reason || 'Identifiants invalides';
            ctx.patchState({
                loggedUser: null,
                error: { statusCode: err.error?.statusCode, message: err.error?.message },
                isLoading: false,
            });
            ctx.patchState({ isLoading: false });
            this.alertService.error('Connexion', errorMessage);
        }
    }

    @Action(Register)
    public register(ctx: StateContext<AuthStateModel>, action: Register): void {
        const loggedUser = action.payload.loggedUser;
        ctx.patchState({ loggedUser: loggedUser, error: null });

        this.store.dispatch(
            new SetUserOrganization({
                organization: loggedUser.organization!,
                isAdmin: loggedUser.isAdmin,
            }),
        );
    }

    @Action(Logout)
    public async logout(ctx: StateContext<AuthStateModel>): Promise<void> {
        ctx.patchState({ loggedUser: null, error: null });
        localStorage.removeItem('Authorization');
        this.navigateAfterLogout();
    }

    @Action(PasswordForgot)
    public async passwordForgot(
        ctx: StateContext<AuthStateModel>,
        action: PasswordForgot,
    ): Promise<void> {
        try {
            await this.authService.passwordForgot(action.payload.email);
            this.alertService.valid('Mot de passe oublié', 'Email renvoyé !');
            ctx.patchState({ loggedUser: null, error: null });
        } catch (err: any) {
            console.log(err);
            ctx.patchState({
                loggedUser: null,
                error: { statusCode: err.error?.statusCode, message: err.error?.message },
            });
        }
    }

    @Action(PasswordChange)
    public async passwordChange(
        ctx: StateContext<AuthStateModel>,
        action: PasswordChange,
    ): Promise<void> {
        try {
            await this.authService.passwordChange(action.payload.token, action.payload.password);
            this.alertService.valid('Changement mot de passe', 'Changement réussi ');
            ctx.patchState({ loggedUser: null, error: null });
        } catch (err: any) {
            console.log(err);
            this.alertService.error(
                'Changement mot de passe',
                'Erreur lors du changement de mot de passe',
            );
            ctx.patchState({
                loggedUser: null,
                error: { statusCode: err.error?.statusCode, message: err.error?.message },
            });
        }
    }

    @Action(Signup)
    public async signup(ctx: StateContext<AuthStateModel>, action: Signup): Promise<void> {
        try {
            await this.authService.signup(action.payload.token, action.payload.user);
            ctx.patchState({ loggedUser: null, error: null });
        } catch (err: any) {
            console.error('Error signup user:', err);
            throw err;
        }
    }

    // Create a method to handle navigation
    private navigateAfterLogout(): void {
        if (typeof window !== 'undefined') {
            // Check if window is available
            window.location.reload();
        }
    }
}
