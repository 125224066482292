import { Component, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { SidenavService } from '../../../shared/services/sidenav.service';
import { MenuItem } from '../../../shared/interfaces/menuitem.interface';
import { CRAC_CONSTANTS } from './assets/constants';
import { combineLatest } from 'rxjs';
import { OrganizationState } from '../../admin/organization/organization.state';

@UntilDestroy()
@Component({
    selector: 'app-crac',
    template: `<router-outlet></router-outlet>`,
})
export class CracComponent {
    private _currentOrganization$ = inject(Store).select(OrganizationState.currentOrganization);
    private _currentYear$ = inject(Store).select(OrganizationState.currentYear);

    constructor(private readonly sidenavService: SidenavService) {}

    ngOnInit() {
        this.setSideNavTopMenuItems();
    }

    ngOnDestroy(): void {
        // do not reset sidenav for now, but i have the feeling this will change again soon
        // this.sidenavService.setTopMenuItems([]);
    }

    public async setSideNavTopMenuItems(): Promise<void> {
        combineLatest([this._currentOrganization$, this._currentYear$])
            .pipe(untilDestroyed(this))
            .subscribe(([organization, year]) => {
                const prefixPath = `/main/${organization?.id != null ? `${organization.id}/` : ''}crac/${year}`;
                const topMenuItems: MenuItem[] = [
                    {
                        label: CRAC_CONSTANTS.general.label,
                        icon: 'icon-bar-chart-10',
                        routerLink: [`${prefixPath}/${CRAC_CONSTANTS.general.path}`],
                        routerLinkActiveOptions: { exact: true },
                        hidden: false,
                        isActive: false,
                        subItems: [],
                    },
                ];
                this.sidenavService.setTopMenuItems(topMenuItems);
            });
    }
}
