<div class="page-content">
    <div class="page-header">
        <h1>Mon profil</h1>
    </div>

    <div class="page-body">
        <form [formGroup]="userFormGroup">
            <div class="row mb-3">
                <div class="col-3 combined-label display-label-on-the-left">
                    <div>
                        <label for="firstname"> Prénom </label>
                        <label>&nbsp;et&nbsp;</label>
                        <label for="lastname" class="required"> nom </label>
                    </div>
                </div>
                <div class="col-8">
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="col-sm display-label-on-top">
                                    <label for="firstname" class="required"> Prénom </label>
                                </div>
                                <div class="col-sm">
                                    <ed-textfield
                                        innerId="firstname"
                                        name="firstname"
                                        type="text"
                                        placeholder="Prénom"
                                        [required]="true"
                                        [formControlName]="'firstname'"></ed-textfield>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col-sm display-label-on-top">
                                    <label for="lastname" class="required"> Nom </label>
                                </div>
                                <div class="col-sm">
                                    <ed-textfield
                                        innerId="lastname"
                                        name="lastname"
                                        type="text"
                                        placeholder="Nom"
                                        [required]="true"
                                        [formControlName]="'lastname'"></ed-textfield>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-sm-3 d-flex align-items-center">
                    <label for="email"> Email de l'utilisateur </label>
                </div>
                <div class="col-sm-8">
                    <ed-textfield
                        innerId="mail"
                        name="mail"
                        type="mail"
                        placeholder="jeanne.martin@aecenergie.fr"
                        [icon]="'icon-mail-01'"
                        [formControlName]="'mail'"></ed-textfield>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-sm-3 d-flex align-items-center">
                    <label for="password"> Mon mot de passe </label>
                </div>
                <div class="col-sm-8">
                    <ed-textfield
                        innerId="password"
                        name="Mot de passe"
                        type="password"
                        placeholder="Mot de passe"
                        [icon]="'icon-key-02'"
                        [formControlName]="'password'"></ed-textfield>
                </div>
                <div class="col-1">
                    <div class="icon">
                        <ed-button
                            (click)="editPassword()"
                            class="icon"
                            [icon]="'icon-edit-05'"></ed-button>
                    </div>
                </div>
            </div>

            <div class="sticky-bottom-buttons" *ngIf="userFormGroup.dirty && !isSubmitting">
                <ed-button
                    class="left"
                    [icon]="'icon-flip-backward'"
                    (click)="resetForm()"
                    [colorSchema]="buttonColors.Secondary"
                    >Annuler les modifications</ed-button
                >
                <ed-button
                    [type]="buttonTypes.Submit"
                    [disabled]="userFormGroup.invalid || !userFormGroup.dirty"
                    [icon]="'icon-save-01'"
                    [loading]="isSaving"
                    (click)="onSubmit()"
                    >Enregistrer</ed-button
                >
            </div>
        </form>
    </div>
</div>
