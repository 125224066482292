import { ChangeDetectorRef, Component, inject, OnInit, AfterContentChecked } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { FileSizePipe } from 'ngx-filesize';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { AlertService, ButtonColors } from '@ed---interne/ng-uui-components';

import {
    GetAllConcessionControls,
    OrganizationState,
    UpdateConcessionControl,
} from '../organization.state';
import { ConcessionControl } from 'src/app/core/models/concession-control.model';
import { FilesizeOptions } from 'src/app/shared/all.constants';
import {
    ConcessionControlStatus,
    CC_STATUS_CONSTANTS,
} from 'src/app/features/main/concessioncontrol/assets/constants';

@UntilDestroy()
@Component({
    selector: 'organization-manage-studies',
    templateUrl: './organization-manage-studies.component.html',
    styleUrls: ['./organization-manage-studies.component.scss'],
    providers: [FileSizePipe],
})
export class OrganizationManageStudiesComponent implements OnInit, AfterContentChecked {
    private organization = this.store.selectSnapshot(OrganizationState.currentOrganization);
    public editingStudies = new Set<string>();
    public studiesFormGroups: { [key: string]: FormGroup } = {};
    public originalStudiesFormGroup: { [key: string]: any } = {};

    public buttonColors = ButtonColors;
    public statusOptions = CC_STATUS_CONSTANTS;

    public ccStudies: ConcessionControl[] = [];
    private ccStudies$: Observable<ConcessionControl[]> = inject(Store)
        .select(OrganizationState.concessionControls)
        .pipe(
            filter(
                (concessionControls): concessionControls is ConcessionControl[] =>
                    concessionControls !== null,
            ),
        );

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly store: Store,
        private readonly cdr: ChangeDetectorRef,
        private readonly router: Router,
        private readonly alertService: AlertService,
        private readonly fileSizePipe: FileSizePipe,
    ) {}

    ngOnInit() {
        const payload = { organizationId: this.organization!.id };
        this.store.dispatch(new GetAllConcessionControls(payload));

        this.ccStudies$.pipe(untilDestroyed(this)).subscribe((ccStudies) => {
            this.ccStudies = ccStudies;
            this.ccStudies.forEach((ccStudy) => {
                if (!this.studiesFormGroups[ccStudy.id]) {
                    this.studiesFormGroups[ccStudy.id] = this.formBuilder.group({
                        id: [ccStudy.id, Validators.required],
                        status: [ccStudy.status, Validators.required],
                        description: [ccStudy.description, Validators.required],
                        document: [ccStudy.document, Validators.required],
                        year: [ccStudy.year, Validators.required],
                        isFileDeleted: [false],
                        isNewFile: [false],
                        sizeFile: [ccStudy.sizeFile || 0],
                    });
                }

                this.originalStudiesFormGroup[ccStudy.id] = {
                    ...this.studiesFormGroups[ccStudy.id].value,
                    isFileDeleted: false,
                    isNewFile: false,
                };
            });
        });
    }

    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }

    openConcessionControl(year: number) {
        this.router.navigate([`main/${this.organization?.id}/cc/${year}/`]);
    }

    getBadgeLabel(statusId: number): string {
        const option = this.statusOptions.find((option) => option.value === statusId);
        return option ? option.label : '';
    }

    getBadgeClass(status: number): string {
        switch (status) {
            case ConcessionControlStatus.WAITING:
                return 'pending';
            case ConcessionControlStatus.PROCESSING:
                return 'inProgress';
            default:
                return 'available';
        }
    }

    editStudy(id: string): void {
        this.editingStudies.add(id);
    }

    saveChanges(id: string): void {
        const ccStudy = this.ccStudies.find((ccStudy: ConcessionControl) => ccStudy.id === id);
        ccStudy!.isLoading = true;

        const updatedData = this.studiesFormGroups[id].value;

        const updatedDataToUpdate: Partial<ConcessionControl> = {
            id: updatedData.id,
            status: updatedData.status,
            description: updatedData.description,
            year: updatedData.year,
            organization: {
                id: this.organization?.id ?? '',
                name: '',
                userCount: 0,
                concessionControls: [],
            },
        };

        const formGroup = this.studiesFormGroups[id];
        let file = formGroup.get('document')?.value;

        const isFileDeleted = formGroup.value.isFileDeleted;
        const isNewFile = formGroup.value.isNewFile;

        if (isFileDeleted && !isNewFile) {
            file = null;
        }

        this.store
            .dispatch(
                new UpdateConcessionControl({
                    concessionControlPart: updatedDataToUpdate,
                    file,
                    isFileDeleted,
                    isNewFile,
                }),
            )
            .subscribe({
                next: () => {
                    this.alertService.valid(
                        'Contrôle de concession',
                        "L'excercice a bien été modifié",
                    );
                    file = null;
                    formGroup.patchValue({
                        isNewFile: false,
                        isFileDeleted: false,
                    });

                    ccStudy!.isLoading = false;
                    this.editingStudies.delete(id);
                },
                error: (err) => {
                    this.alertService.error(
                        'Contrôle de concession',
                        "Une erreur est survenue : Impossible de mettre à jour l'excercice",
                    );
                },
            });
    }

    cancelEdit(id: string): void {
        this.editingStudies.delete(id);

        this.studiesFormGroups[id].reset(this.originalStudiesFormGroup[id]);
    }

    downloadFile() {}

    deleteFile(id: string): void {
        const formGroup = this.studiesFormGroups[id];
        const currentFile = formGroup.get('document')?.value;
        if (currentFile) {
            formGroup.patchValue({ document: null, isFileDeleted: true });
            formGroup.get('document')?.markAsDirty();
            formGroup.get('document')?.updateValueAndValidity();
        }
    }

    public getFileSize(fileSize: number): string {
        return String(this.fileSizePipe.transform(fileSize, FilesizeOptions));
    }

    public onFileDropped(event: any, id: string): void {
        this.onFileChange(event, id);
    }

    public onFileSelect(event: any, id: string): void {
        this.onFileChange(event.target.files, id);
    }

    public onFileChange(event: any, id: string): void {
        if (!event.length) {
            return;
        }
        const document = event[0];

        if (document) {
            this.studiesFormGroups[id].patchValue({ isNewFile: true });
            this.studiesFormGroups[id].get('document')?.markAsDirty();
            this.studiesFormGroups[id].get('document')?.updateValueAndValidity();
            this.studiesFormGroups[id].patchValue({
                document: document,
                sizeFile: document.size,
            });
        }
    }
}
