import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { User } from '../../../core/models/user.model';
import { AuthState } from '../../../core/states/auth.state';
import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { MenuItem } from '../../interfaces/menuitem.interface';

@UntilDestroy()
@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
    public loggedUser$!: Observable<User | null>;
    public loggedUser!: User;

    public topMenuItems: MenuItem[] = [];
    public bottomMenuItems: MenuItem[] = [];

    constructor(
        private readonly store: Store,
        private router: Router,
        private sidenavService: SidenavService,
    ) {}

    ngOnInit(): void {
        this.loggedUser$ = this.store.select(AuthState.loggedUser);
        this.loggedUser$
            .pipe(untilDestroyed(this))
            .subscribe((data: any) => (this.loggedUser = data));

        this.loggedUser$.pipe(filter(Boolean), untilDestroyed(this)).subscribe((loggedUser) => {
            this.topMenuItems = [
                {
                    label: 'Accueil',
                    icon: 'icon-mitoyen',
                    routerLink: ['/main'],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    isActive: false,
                    subItems: [],
                },
                {
                    label: 'Général',
                    icon: 'icon-bar-chart-10',
                    routerLink: ['/main/general'],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    isActive: false,
                    subItems: [],
                },
                {
                    label: 'Ouvrages',
                    icon: 'icon-electricity',
                    routerLink: [''],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    isActive: false,
                    subItems: [
                        {
                            label: 'Postes Sources',
                            icon: '',
                            routerLink: ['/main/source-posts'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Réseaux HTA',
                            icon: '',
                            routerLink: ['/main/hta-networks'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Réseaux BT',
                            icon: '',
                            routerLink: ['/main/bt-networks'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Postes/transfos. HTA/BT',
                            icon: '',
                            routerLink: ['/main/transformer-stations'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                    ],
                },
                {
                    label: 'Fournitures',
                    icon: 'icon-star',
                    routerLink: [''],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    isActive: false,
                    subItems: [
                        {
                            label: 'Continuité de fourniture',
                            icon: '',
                            routerLink: ['/main/continuity-supply'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Qualité de fourniture',
                            icon: '',
                            routerLink: ['/main/quality-supply'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                    ],
                },
                {
                    label: 'Dépenses',
                    icon: 'icon-cylinder',
                    routerLink: [''],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    isActive: false,
                    subItems: [
                        {
                            label: 'Investissement (CAPEX)',
                            icon: '',
                            routerLink: ['/main/investment'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Entretien et maintenance (OPEX)',
                            icon: '',
                            routerLink: ['/main/care-maintenance'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                    ],
                },
                {
                    label: 'Comptabilité',
                    icon: 'icon-plus-minus',
                    routerLink: [''],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    isActive: false,
                    subItems: [
                        {
                            label: 'Valorisation du patrimoine concédé',
                            icon: '',
                            routerLink: ['/main/valorization-heritage'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Droits du concédant',
                            icon: '',
                            routerLink: ['/main/licensor-rights'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                    ],
                },
                {
                    label: 'Clientèle distribution',
                    icon: 'icon-users-01',
                    routerLink: [''],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    isActive: false,
                    subItems: [
                        {
                            label: 'Usagers, consommations et recettes',
                            icon: '',
                            routerLink: ['/main/distrib-consumption'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Producteurs',
                            icon: '',
                            routerLink: ['/main/distrib-producers'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Raccordements',
                            icon: '',
                            routerLink: ['/main/connections'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Services clientèles',
                            icon: '',
                            routerLink: ['/main/distrib-customers'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Réclamations',
                            icon: '',
                            routerLink: ['/main/distrib-complaints'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                    ],
                },
                {
                    label: 'Clientèle fournisseur',
                    icon: 'icon-users-01',
                    routerLink: ['/main/supplier-customers'],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    isActive: false,
                    subItems: [
                        {
                            label: 'Usagers, consommations et recettes',
                            icon: '',
                            routerLink: ['/main/supplier-consumption'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Services clientèles',
                            icon: '',
                            routerLink: ['/main/supplier-customers'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Réclamations',
                            icon: '',
                            routerLink: ['/main/supplier-complaints'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                        {
                            label: 'Précarité énergétique',
                            icon: '',
                            routerLink: ['/main/energy-insecurity'],
                            routerLinkActiveOptions: { exact: true },
                            hidden: false,
                            isActive: false,
                            subItems: [],
                        },
                    ],
                },
                {
                    label: 'Fiche territoire',
                    icon: 'icon-marker-pin-04',
                    routerLink: ['/main/territory-sheet'],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    isActive: false,
                    subItems: [],
                },
                {
                    label: 'Export rapport',
                    icon: 'icon-download',
                    routerLink: ['/main/export-report'],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    isActive: false,
                    subItems: [],
                },
            ];

            this.sidenavService.topMenuItems$
                .pipe(untilDestroyed(this))
                .subscribe((topMenuItems: MenuItem[]) => {
                    if (topMenuItems) {
                        this.topMenuItems = topMenuItems;
                        this._updatePanelState(this.router.url);
                    }
                });

            this.bottomMenuItems = [
                {
                    label: 'Mon profil',
                    icon: 'icon-user-01',
                    routerLink: ['/main/account'],
                    routerLinkActiveOptions: { exact: true },
                    isActive: false,
                    panelOpenState: false,
                    subItems: [],
                },
            ];

            if (this.loggedUser.isAdmin) {
                this.bottomMenuItems.push({
                    label: 'Administration',
                    icon: 'icon-tool-01',
                    routerLink: ['/admin/organization'],
                    routerLinkActiveOptions: { exact: true },
                    isActive: false,
                    subItems: [],
                });
            }
        });
        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this._updatePanelState(event.urlAfterRedirects);
            }
        });

        const currentUrl = this.router.url;
        this._updatePanelState(currentUrl);
    }
    private _updatePanelState(currentUrl: string): void {
        this.topMenuItems.forEach((menuItem) => {
            const subItemsRouterLinks = menuItem.subItems.map((item) => item.routerLink[0]);
            menuItem.panelOpenState = subItemsRouterLinks.some((url) => currentUrl.includes(url));
            menuItem.isActive = subItemsRouterLinks.some((url) => currentUrl.includes(url));
        });

        this.bottomMenuItems.forEach((menuItem) => {
            const subItemsRouterLinks = menuItem.subItems.map((item) => item.routerLink[0]);
            menuItem.panelOpenState = subItemsRouterLinks.some((url) => currentUrl.includes(url));
            menuItem.isActive = subItemsRouterLinks.some((url) => currentUrl.includes(url));
        });
    }

    public handleClickOnItem() {
        this.sidenavService.toggle();
    }
}
