export enum ConcessionControlStatus {
    WAITING = 1,
    PROCESSING = 2,
    AVAILABLE = 3,
}

export const CC_STATUS_CONSTANTS = [
    {
        label: 'En attente de vos données',
        value: ConcessionControlStatus.WAITING,
    },
    {
        label: 'Traitement en cours',
        value: ConcessionControlStatus.PROCESSING,
    },
    {
        label: 'Disponible',
        value: ConcessionControlStatus.AVAILABLE,
    },
];

export const CC_CONSTANTS = {
    welcome: {
        path: 'welcome',
        icon: 'bi bi-house',
        sidebarLabel: 'Accueil',
        title: 'Accueil',
        subTitle: 'Contrôle de concession',
        texts: {
            default: {
                descriptionTitle: `Extrait de l'article 2 du cahier des charges de concession pour le service public du développement et de l’exploitation du réseau public de distribution d'électricité et de la fourniture d'énergie électrique aux tarifs réglementés de vente :`,
                descriptionContent: ` (...) Les ouvrages concédés comprennent l'ensemble des installations affectées à la distribution publique de l'énergie électrique existant au moment de la signature du présent contrat, dans le périmètre de la concession, ainsi que toutes celles de tension strictement inférieure à 50.000 volts, qui seront établies par le gestionnaire du réseau de distribution avec l'accord de l'autorité concédante ou par l'autorité concédante avec l'accord du gestionnaire du réseau de distribution.
Ils comprennent également les ouvrages de tension supérieure, existant à la date de publication de la loi n° 2004-803 du 9 août 2004, non exploités par RTE en tant que gestionnaire du réseau public de
transport.
Les ouvrages concédés comprennent aussi les branchements visés à l'article 29 du présent cahier des charges, les compteurs, ainsi que leurs accessoires et les concentrateurs de grappes de compteurs.
Conformément aux dispositions de l'arti cle L. 322-4 du code de l' énergie, la partie des postes sources transformant la haute tension en moyenne tension et ses accessoires, intégrés au réseau public
de distribution, constituent des ouvrages de ce réseau tels que définis par le présent cahier des charges et sont la propriété du gestionnaire du réseau de distribution. Celui -ci met à la disposition de la
concession, jusqu'au terme du présent contrat, tout ou partie de ceux de ces ouvrages, existants ou à créer, qui contribuent à son alimentation, sous réserve des besoins des autres concessions et des
utilisateurs des réseaux publics de distribution.
Les autres ouvrages du réseau public de distribution sont la propriété de l'autorité concédante de la distribution publique d'électricité (...)
`,
            },
            concessionContractSigningDate: {
                title: 'Date de signature du contrat de concession',
            },
            currentConcessionContractDuration: {
                title: 'Durée du contrat de concession en vigueur',
                suffix: ' ans',
            },
            concessionHoldersNames: { title: 'Nom des concessionnaires' },
            processedConcessionContractYear: { title: 'Dernier exercice des données' },
            mvUndergroundingRate: {
                title: "Taux d'enfouissement des réseaux HTA",
                suffix: ' %',
                precision: 1,
                badgeSuffix: ' %',
                badgePrecision: 1,
                descriptionContent:
                    "Le taux d'enfouissement HTA correspond à la proportion des réseaux HTA souterrains (y compris en galerie) par rapport aux linéaires totaux HTA",
            },
            lvUndergroundingRate: {
                title: "Taux d'enfouissement des réseaux BT",
                suffix: ' %',
                precision: 1,
                badgeSuffix: ' %',
                badgePrecision: 1,
                descriptionContent:
                    "Le taux d'enfouissement BT correspond à la proportion des réseaux BT souterrains (y compris en galerie) par rapport aux linéaires totaux BT",
            },
            linkyDeploymentRate: {
                title: 'Taux de déploiement des compteurs Linky C5',
                suffix: ' %',
                precision: 0,
                footerPrefix: 'Moyenne AEC: ',
                footerPrecision: 0,
                footerSuffix: ' %',
            },
            criterionBHix: {
                title: 'Critère B HIX',
                suffix: ' minutes',
                precision: 0,
                footerPrefix: 'Moyenne Enedis: ',
                footerPrecision: 0,
                footerSuffix: ' minutes',
                descriptionTitle: 'Critère B HIX',
                descriptionContent: `Le Critère B correspond à la durée moyenne annuelle d'interruption de fourniture par usager BT.
Il comprend les interruptions pour incidents sur les réseaux HTA et BT, et pour travaux sur les réseaux HTA et BT. Il comprend également les interruptions sur le réseau de transport RTE et dans les Postes Sources.
La spécifité HIX (Hors Evènements Exceptionnels) précise que les interruptions exceptionnelles sont exclues selon la définition de la CRE dans le TURPE (évènements climatiques de grande ampleur, vols de métaux, etc.)`,
            },
            totalInvestments: {
                title: "Investissements annuels totaux d'Enedis (CAPEX)",
                precision: 0,
                suffix: ' M€',
                badgePrecision: 0,
                badgeSuffix: ' M€',
            },
            totalNumberOfUsers: {
                title: "Nombre total d'usagers en soutirage (Enedis)",
                descriptionContent: `Le nombre total d'usagers comprend les usagers BT < 36 kVA, BT > 36 kVA et HTA, selon la base clientèle du distributeur.`,
                precision: 0,
                badgePrecision: 0,
            },
        },
    },

    generalInfo: {
        path: 'general-info',
        icon: 'bi bi-info-square-fill',
        sidebarLabel: 'Infos générales',
        title: 'Informations générales',
        subTitle: '',
        texts: {
            municipalitiesChart: {
                title: "Evolution annuelle du nombre de communes en concession, et du nombre total d'usagers (base clientèle d'Enedis)",
                descriptionContent:
                    'Le nombre total de communes en concession indiqué, prend notamment en compte les communes déléguées au sein des communes nouvelles. Selon le détail présenté par le distributeur dans ses bases techniques.',
                nbUsersAxisTitle: "Nombre d'usagers",
                nbMunicipalitiesAxisTitle: 'Nombre de communes',
            },
        },
    },

    sourceStations: {
        path: 'infrastructure/source-stations',
        icon: 'bi bi-lightning-charge-fill',
        sidebarLabel: 'Postes sources',
        title: 'Ouvrages',
        subTitle: 'Postes Sources HTB/HTA',
        texts: {
            nbSourceStationsSupplying: {
                title: 'Nombre total de postes sources alimentant la concession',
                descriptionTitle: 'Précision',
                descriptionContent:
                    "Les postes sources hébergent les transformateurs HTB/HTA, permettant d'assurer la conversion de tension entre le réseau de transport et le réseau de distribution HTA.",
                precision: 0,
                badgePrecision: 0,
            },
            nbSourceStationsIn: {
                title: 'Nombre total de postes sources situés sur la concession',
                precision: 0,
                badgePrecision: 0,
            },
            sourceStationsPowerSupplying: {
                title: 'Puissance totale des postes sources alimentant la concession (en MVA)',
                suffix: ' MVA',
                precision: 0,
                badgePrecision: 0,
                badgeSuffix: ' MVA',
            },
            nbTransformersSupplying: {
                title: 'Nombre total de transformateurs HTB/HTA équipant les postes sources alimentant la concession',
                precision: 0,
                badgePrecision: 0,
            },

            sourceStationsChart: {
                title: "Evolution annuelle du nombre de postes sources sur la concession, ou l'alimentant, et de la puissance totale installée des postes sources (en MVA)",
                nbSourceStationsSupplyingConcessionAxisTitle:
                    'Nombre de postes sources alimentant la concession',
                nbSourceStationsInConcessionAxisTitle: 'Nombre de postes sources sur la concession',
                nbTransformersSupplyingConcessionAxisTitle:
                    'Puissance totale des postes sources alimentant la concession',
            },

            localizationMap: {
                title: "Carte de localisation des postes sources alimentant la concession, et répartition du nombre d'usagers en soutirage (base technique)  par commune de la concession",
                descriptionTitle: 'Précision',
                descriptionContent:
                    'Les communes déléguées au sein de communes nouvelles ne sont pas distinguées sur les cartes, les indicateurs sont cumulés à la maille de la commune nouvelle.',
            },
        },
    },

    mvNetworks: {
        path: 'infrastructure/mv-networks',
        sidebarLabel: 'Réseaux HTA',
        title: 'Ouvrages',
        subTitle: 'Réseaux HTA',
        tabs: {
            undergrounding: {
                path: 'undergrounding',
                title: 'Enfouissement HTA',
                texts: {
                    totalMvNetworkLength: {
                        title: 'Longueur totale des réseaux HTA (en km)',
                        descriptionContent:
                            "Les réseaux HTA (aériens ou souterrains) permettent depuis les postes sources d'alimenter les postes HTA/BT ou de raccorder les usagers HTA (en soutirage ou en injection). Leur niveau de tension nomimale est principalement de 20 000 V, mais il peut exister également dans certains secteurs des niveaux de tension HTA inférieurs.",
                        suffix: ' km',
                        precision: 0,
                        badgeSuffix: ' %',
                        badgePrecision: 1,
                        descriptionTitle: 'Précision',
                    },
                    mvBurialRate: {
                        title: 'Taux d’enfouissement HTA (en %)',
                        descriptionContent:
                            "Le taux d'enfouissement HTA correspond à la proportion des réseaux HTA souterrains (y compris en galerie) par rapport aux linéaires totaux HTA",
                        suffix: ' %',
                        precision: 1,
                        badgeSuffix: ' Points',
                        badgePrecision: 1,
                        descriptionTitle: 'Précision',
                    },
                    mvFeedersCount: {
                        title: 'Nombre de départs HTA alimentant la concession',
                        descriptionContent:
                            "Un départ HTA est qualifié 'alimentant à la concession' à la condition qu'il désserve au moins un poste HTA/BT qui est situé sur une commune appartenant à la concession.",
                        suffix: ' km',
                        precision: 0,
                        badgePrecision: 0,
                        descriptionTitle: 'Précision',
                    },
                    mvFeedersOver70kmCount: {
                        title: 'Nombre de départs HTA alimentant la concession de plus de 70 km',
                        precision: 0,
                        footerSuffix: ' km',
                        footerPrecision: 0,
                    },
                    mvUndergroundRateChart: {
                        title: "Positionnement du taux d'enfouissement HTA de la concession, selon la densité d'usagers (usagers/km HTA) et par rapport aux taux des départements métropolitains",
                        descriptionContent:
                            "Quatre départements d'Ile-de -France (75, 91, 92 et 93) sont exclus de ce benchmark. En effet, ces 4 départements avec des taux d'enfouissement HTA de 100% présentent des densités d'usagers comprises entre 200 et 350 usagers/km, qui sont très supérieurs au reste de l'hexagone, ce qui rendraient illisible le nuage de points. Le 5ème département au classement de la densité (et donc le maximum du benchmark) est les Alpes-Maritimes avec 180 usagers/km.",
                        descriptionTitle: 'Précision',
                        mvUndergroundRateConcessionAxisTitle:
                            "Taux d'enfouissement HTA (Concession)",
                        mvUndergroundRateDepartmentAxisTitle:
                            "Taux d'enfouissement HTA (Départements)",
                    },
                },
            },
            aging: {
                path: 'aging',
                title: 'Age des réseaux HTA et points de vigilances',
                texts: {
                    mvNetworksAverageAge: {
                        title: 'Age moyen des réseaux HTA (en années)',
                        descriptionTitle: 'Précision',
                        descriptionContent: `
                            <p>
                                L'âge moyen des réseaux HTA, est calculé ainsi par AEC : il correspond à la moyenne des âges de chaque tronçon, avec une pondération selon la longueur de chaque tronçon.
                            </p>
                            <p>
                                L'age de chaque tronçon correspond à la différence entre l'année traitée des données et l'année de mise en service du tronçon, à laquelle + 0,5 an (une demi année) est ajoutée.
                            </p>
                            <p class="mb-0">
                                La demi année ajoutée permet de faire une moyenne sur l'année, autrement dit que l'ouvrage ait été mis en service en janvier ou décembre de la même année, nous retenons une mise en service moyenne au 1er juillet.
                                Cela permet principalement d'éviter, par exemple, que tous les ouvrages mis en service en 2023, ait toujours un âge de 0 an à fin 2023.
                                AEC se tient à la disposition des AODE pour transmettre plus de précision sur les modalités de calcul.
                            </p>`,
                        suffix: ' ans',
                        precision: 1,
                        badgeSuffix: ' ans',
                        badgePrecision: 2,
                    },
                    mvNetworksLengthOver40Km: {
                        title: 'Longueur totale des réseaux HTA ayant plus de 40 ans (en km)',
                        descriptionTitle: 'Précision',
                        descriptionContent: `
                            Afin de préciser, l'indicateur "longueur totale des réseaux HTA ayant plus de 40 ans", correspond à strictement plus de 40 ans.
                        `,
                        precision: 0,
                        suffix: ' km',
                        badgeSuffix: ' %',
                        badgePrecision: 1,
                    },
                    mvNetworksLengthCpi: {
                        title: 'Longueur totale des réseaux HTA souterrains de type CPI (en km)',
                        descriptionTitle: 'Précision',
                        descriptionContent: `
                            <p>
                                Les câbles souterrains HTA CPI (Câbles à Isolation Papier Imprégné) correspondent à la 1ère génération de câbles HTA mises en service.
                                Ils ont été posés principalement entre 1960 et 1980. Après 1980 ce sont les câbles souterrains synthétiques qui été posés.
                            </p>
                            <p class="mb-0">
                                Le suivi des linéaires des câbles CPI HTA est souvent mis sous surveillance, car cette technologie qui a en moyenne plus de 40 ans, présente un taux d'incidents pour 100 km fréquemment très élevé.
                                Et les CPI sont principalement situés dans les centres bourgs, secteur dans lesquels les dépannages et travaux peuvent être complexes, et le nombre d'usagers coupés augmentent fortement en cas de double défaut.
                                Cette incidentologie est aggravée durant les périodes de fortes chaleurs estivales.
                            </p>
                        `,
                        suffix: ' km',
                        precision: 0,
                        badgeSuffix: ' %',
                        badgePrecision: 1,
                    },
                    mvNetworksLengthLowSection: {
                        title: 'Longueur totale des réseaux HTA aériens nus de Faibles Sections (en km)',
                        descriptionTitle: 'Précision',
                        descriptionContent: `
                            Les réseaux aériens nus de Faibles Sections (FS) HTA sont les conducteurs :
                            <ul class="mb-0">
                                <li>
                                    en cuivre avec des sections ≤ 14 mm² ;
                                </li>
                                <li>
                                    en aluminium avec des sections ≤ 22 mm².
                                </li>
                            </ul>
                        `,
                        suffix: ' km',
                        precision: 0,
                        badgeSuffix: ' %',
                        badgePrecision: 1,
                    },
                },
            },
        },
    },

    lvNetworks: {
        path: 'infrastructure/lv-networks',
        sidebarLabel: 'Réseaux BT',
        title: 'Ouvrages',
        subTitle: 'Réseaux BT',
        tabs: {
            undergrounding: {
                path: 'undergrounding',
                title: 'Enfouissement BT',
                texts: {},
            },
            aging: {
                path: 'aging',
                title: 'Age des réseaux BT et points de vigilances',
                texts: {},
            },
        },
    },
    transformerStations: {
        path: 'infrastructure/transformer-stations',
        sidebarLabel: 'Autres',
        title: 'Ouvrages',
        subTitle: 'Autres ouvrages',
        tabs: {
            substationsTransformers: {
                path: 'substations-transformers',
                title: 'Postes et transformateurs HTA/BT',
                texts: {},
            },
            meters: {
                path: 'meters',
                title: 'Compteurs',
                texts: {},
            },
        },
    },
    supplyContinuity: {
        path: 'cq/supply-continuity',
        icon: 'bi bi-activity',
        sidebarLabel: 'Continuité fourniture',
        title: 'Continuité et qualité de fourniture',
        subTitle: 'Continuité de fourniture',
        tabs: {
            BCriterion: {
                path: 'b-criterion',
                title: 'Critères B',
                texts: {},
            },
            outages: {
                path: 'outages-frequency',
                title: 'Fréquence de coupure',
                texts: {},
            },
            incidentology: {
                path: 'incidentology',
                title: 'Incidentologie',
                texts: {},
            },
        },
    },
    voltageQuality: {
        path: 'cq/voltage-quality',
        sidebarLabel: 'Qualité tension',
        title: 'Continuité et qualité de fourniture',
        subTitle: 'Qualité de tension',
        texts: {},
    },
    investments: {
        path: 'expenses/investments',
        icon: 'bi bi-cone-striped',
        sidebarLabel: 'Investissements (CAPEX)',
        title: "Dépenses d'Enedis",
        subTitle: 'Investissements (CAPEX)',
        texts: {},
    },
    maintenanceCost: {
        path: 'expenses/maintenance-cost',
        sidebarLabel: 'Entretien et maintenance (OPEX)',
        title: "Dépenses d'Enedis",
        subTitle: 'Entretien et maintenance (OPEX)',
        texts: {},
    },
    kpis: {
        path: 'accounting/kpis',
        icon: 'bi bi-graph-up-arrow',
        sidebarLabel: 'Indicateurs comptables',
        title: 'Comptabilité',
        subTitle: 'Indicateurs comptables',
        texts: {},
    },
    grantorRights: {
        path: 'accounting/grantor-rights',
        sidebarLabel: 'Droits du concédant',
        title: 'Comptabilité',
        subTitle: 'Droits du concédant',
        texts: {},
    },
    operatingAccount: {
        path: 'operating-account',
        icon: 'bi bi-currency-euro',
        sidebarLabel: "Compte d'exploitation",
        title: "Compte d'exploitation",
        subTitle: '',
        texts: {},
    },
    energyUsers: {
        path: 'enedis-customers/energy-users',
        icon: 'bi bi-plugin',
        sidebarLabel: 'Usagers en soutirage',
        title: 'Gestion clientèle - Enedis',
        subTitle: 'Usagers en soutirage',
        texts: {},
    },
    energyProducers: {
        path: 'enedis-customers/energy-producers',
        sidebarLabel: 'Usagers producteurs',
        title: 'Gestion clientèle - Enedis',
        subTitle: 'Usagers producteurs',
        texts: {},
    },
    connections: {
        path: 'enedis-customers/connections',
        sidebarLabel: 'Raccordements',
        title: 'Gestion clientèle - Enedis',
        subTitle: 'Raccordements',
        texts: {},
    },
    enedisQuality: {
        path: 'enedis-customers/quality',
        sidebarLabel: 'Qualité Enedis',
        title: 'Gestion clientèle - Enedis',
        subTitle: "Indicateurs de qualité de service d'Enedis",
        texts: {},
    },
    enedisClaims: {
        path: 'enedis-customers/claims',
        sidebarLabel: 'Réclamations Enedis',
        title: 'Gestion clientèle - Enedis',
        subTitle: 'Réclamations traitées par Enedis',
        texts: {},
    },
    trvUsers: {
        path: 'edf-customers/trv-users',
        icon: 'bi bi-headset',
        sidebarLabel: 'Clients au TRV',
        title: 'Gestion clientèle - EDF',
        subTitle: 'Clients au TRV',
        texts: {},
    },
    edfQuality: {
        path: 'edf-customers/quality',
        sidebarLabel: 'Qualité EDF',
        title: 'Gestion clientèle - EDF',
        subTitle: "Indicateurs de qualité de service d'EDF",
        texts: {},
    },
    edfClaims: {
        path: 'edf-customers/claims',
        sidebarLabel: 'Réclamations EDF',
        title: 'Gestion clientèle - EDF',
        subTitle: 'Réclamations traitées par EDF',
        texts: {},
    },
    energyPoverty: {
        path: 'edf-customers/energy-poverty',
        sidebarLabel: 'Précarité Énergétique',
        title: 'Gestion clientèle - EDF',
        subTitle: 'Précarité énergétique',
        texts: {},
    },
    exportReport: {
        path: 'export_report',
        sidebarLabel: 'Export rapport',
        title: 'Export du rapport',
        subTitle: '',
        texts: {},
    },
};
