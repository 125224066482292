import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { GetAllOrganizations } from '../../features/admin/organization/organization.state';

export const authGuard: CanActivateFn = (): boolean | UrlTree => {
    const authService = inject(AuthService);
    const router = inject(Router);

    if (authService.isAuth) {
        return true;
    }

    if (authService.isAdmin) {
        return router.parseUrl('/admin');
    } else {
        return router.parseUrl('/main/my-organization');
    }
};

export const adminAuthGuard: CanActivateFn = (): boolean | UrlTree => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const store = inject(Store);

    if (authService.isAuth && authService.isAdmin) {
        store.dispatch(new GetAllOrganizations());
        return true;
    } else if (authService.isAuth) {
        return router.parseUrl('/main');
    }

    return router.parseUrl('/');
};

export const notAdminAuthGuard: CanActivateFn = (
    next: ActivatedRouteSnapshot,
): boolean | UrlTree => {
    const authService = inject(AuthService);
    const router = inject(Router);
    if (authService.isAuth && !authService.isAdmin) {
        return true;
    } else if (authService.isAuth && authService.isAdmin) {
        return router.parseUrl('/admin');
    }

    return router.parseUrl('/');
};

export const notAuthGuard: CanActivateFn = (): boolean | UrlTree => {
    const authService = inject(AuthService);
    const router = inject(Router);

    if (!authService.isAuth) {
        return true;
    }

    if (authService.isAdmin) {
        return router.parseUrl('/admin');
    } else {
        return router.parseUrl('/main/my-organization');
    }
};

export const tokenGuard: CanActivateFn = (next: ActivatedRouteSnapshot): boolean | UrlTree => {
    const router = inject(Router);
    const authService = inject(AuthService);

    if (authService.isAuth) {
        return router.parseUrl('/');
    }

    if (next.queryParams['token']) {
        return true;
    }

    return router.parseUrl('/');
};
