import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map, filter, distinctUntilChanged } from 'rxjs';

import { AlertService, ButtonColors, ButtonTypes } from '@ed---interne/ng-uui-components';

import { Organization } from '../../../../core/models/organization.model';
import { UpdateOrganization } from '../organization.state';
import { OrganizationService } from '../organization.service';

import { OrganizationState } from '../organization.state';
import { fileValidator } from '../../../../shared/validators/file.validator';

@UntilDestroy()
@Component({
    selector: 'organization-general',
    templateUrl: './organization-general.component.html',
    styleUrls: ['./organization-general.component.scss'],
})
export class OrganizationGeneralComponent implements OnInit {
    public organizationFormGroup: FormGroup;
    private organization: Organization | undefined | null;
    public buttonColors = ButtonColors;
    public buttonTypes = ButtonTypes;
    public isSubmitting: boolean = false;

    private avatarFile: File | undefined;
    public avatarUrl: string | null = null;

    public avatarUploadContext = {};

    public currentOrganization$!: Observable<Organization | null>;
    public currentOrganization!: Organization;

    constructor(
        private readonly alertService: AlertService,
        private readonly formBuilder: FormBuilder,
        private readonly store: Store,
        private organizationService: OrganizationService,
    ) {
        this.organizationFormGroup = this.formBuilder.group({
            name: [''],
            avatarFile: [null, [fileValidator]],
        });
    }

    ngOnInit() {
        this.getInfoOrganization();

        this.organizationFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.isSubmitting && this.organizationFormGroup.dirty) {
                this.isSubmitting = false;
            }
        });
    }

    private getInfoOrganization() {
        this.currentOrganization$ = this.store.select(OrganizationState.currentOrganization);

        this.currentOrganization$
            .pipe(filter(Boolean), distinctUntilChanged(), untilDestroyed(this))
            .subscribe((organization) => {
                if (organization.id == this.organization?.id) {
                    return;
                }
                this.organization = organization;

                this.organizationFormGroup.patchValue({
                    name: this.organization?.name || '',
                });

                this.organizationService
                    .getAvatar(this.organization!.id!)
                    .pipe(
                        map((blob) => (!!blob ? URL.createObjectURL(blob) : null)),
                        untilDestroyed(this),
                    )
                    .subscribe((avatarUrl) => (this.avatarUrl = avatarUrl));
            });
    }

    public async onFileSelect(event: any): Promise<void> {
        if (!event.target.files.length) {
            return;
        }

        this.avatarFile = event.target.files[0];
        this.avatarUrl = URL.createObjectURL(this.avatarFile!);

        this.organizationFormGroup.patchValue({ avatarFile: this.avatarFile });
        this.organizationFormGroup.get('avatarFile')?.markAsDirty();
        this.organizationFormGroup.get('avatarFile')?.updateValueAndValidity();
    }

    public onSubmit(): void {
        if (this.isSubmitting) {
            return;
        }

        this.isSubmitting = true;

        const { name, avatarFile } = this.organizationFormGroup.value;
        const orgaToUpdate: Partial<Organization> = {
            id: this.organization!.id,
            name: name,
        };
        this.store
            .dispatch(new UpdateOrganization({ organizationPart: orgaToUpdate, avatarFile }))
            .pipe(untilDestroyed(this))
            .subscribe({
                next: async () => {
                    this.organization!.name = name;
                    this.organizationService.triggerUpdateListOrga();

                    this.organizationFormGroup.markAsPristine();
                    this.isSubmitting = false;

                    if (avatarFile) {
                        this.alertService.valid(
                            'Organisation',
                            "L'organisation et l'avatar ont bien été modifiés",
                        );
                    } else {
                        this.alertService.valid(
                            'Organisation',
                            "L'organisation a bien été modifiée",
                        );
                    }
                },
                error: (err) => {
                    this.alertService.error(
                        'Organisation',
                        "Une erreur est survenue : Impossible de mettre à jour l'organisation",
                    );
                    this.isSubmitting = false;
                },
            });
    }

    public resetForm(): void {
        this.organizationFormGroup.reset();
        this.isSubmitting = false;
        this.getInfoOrganization();
    }
}
