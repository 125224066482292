import { Component, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
    public isAtLeastMedium!: boolean;

    constructor() {
        inject(BreakpointObserver)
            .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
            .pipe(untilDestroyed(this))
            .subscribe((result) => {
                const matches = result.matches;
                this.isAtLeastMedium = matches;
            });
    }
}
