import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService, ButtonTypes } from '@ed---interne/ng-uui-components';

import { PasswordChange } from '../../../core/states/auth.state';
import { passwordValidator } from '../../../shared/validators/password.validator';

@UntilDestroy()
@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
    public ButtonTypes = ButtonTypes;
    public changePasswordFormGroup: FormGroup = this.formBuilder.group({
        password: ['', [passwordValidator()]],
    });
    public passwordChanged = false;
    public isPasswordVisible: boolean = false;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private store: Store,
        private readonly alertService: AlertService,
    ) {}

    public onSubmit(): void {
        const password = this.changePasswordFormGroup.value.password || '';
        const token = this.activatedRoute.snapshot.queryParams['token'] || '';

        this.store
            .dispatch(
                new PasswordChange({
                    token: token,
                    password: password,
                }),
            )
            .pipe(untilDestroyed(this))
            .subscribe({
                next: () => {
                    this.alertService.valid('Mot de passe', 'Changement réussi !');
                    this.passwordChanged = true;
                },
                error: () => {
                    this.alertService.error(
                        'Mot de passe',
                        'Erreur lors du changement de mot de passe',
                    );
                },
            });
    }

    public togglePasswordVisibility(): void {
        this.isPasswordVisible = !this.isPasswordVisible;
    }
}
