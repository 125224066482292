import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Pagination } from '../../../shared/all.types';

export interface PaginationStateModel {
    users: Pagination | null;
}

export class SetUsersPagination {
    static readonly type = 'PAGINATION/SET_USERS';

    constructor(
        public payload?: Pagination,
        public reset?: boolean,
    ) {}
}

@State<PaginationStateModel>({
    name: 'pagination',
    defaults: {
        users: null,
    },
})
@Injectable()
export class UsersPaginationState {
    @Selector()
    public static users(state: PaginationStateModel): Pagination | null {
        return state.users;
    }

    @Action(SetUsersPagination)
    public setUsersPagination(
        ctx: StateContext<PaginationStateModel>,
        action: SetUsersPagination,
    ): void {
        if (!action.payload || action.reset) {
            ctx.patchState({ users: null });
        } else {
            ctx.patchState({ users: action.payload });
        }
    }
}
